import React from "react";
import { useState, useEffect } from "react";
import { bodyTableStyles } from "../../styles/cards/bodyTableStyles";
import { useTheme } from "@mui/material/styles";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";

import { LoadingIcon } from "../../loadingIcon";

import { api } from "../../../../api/index";

import BodyTableModal from "../modal/bodyTableModal";

const BodyTable = ({ columns, apiType, apiBody, userID }) => {
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);

  const theme = useTheme();
  const style = bodyTableStyles(theme);

  const fetchData = async () => {
    setLoaded(true);
    setError(null);

    try {
      const response = await api("get", apiBody, apiType);
      setData(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoaded(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  if (loaded) {
    return <LoadingIcon page="dataTable" />;
  }

  if (error) {
    return (
      <TableContainer style={{ textAlign: "center" }}>
        Error fetching data: {error.message}
      </TableContainer>
    );
  }

  if (!data.length) {
    return (
      <TableContainer style={{ textAlign: "center" }}>
        No data to display.
      </TableContainer>
    );
  }

  const formatDate = (date) => {
    const dateString = date;
    const dateObject = new Date(dateString);
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, "0");
    const day = String(dateObject.getDate()).padStart(2, "0");

    return `${day}-${month}-${year}`;
  };

  const calculateDayDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
      return "Invalid Date(s)";
    }

    const timeDifference = end.getTime() - start.getTime();

    const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return dayDifference;
  };

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow sx={style.bodyTableHeaderRow}>
            {columns.map((column) => (
              <TableCell key={column.name}>{column.name}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
            .map((item) => (
              <TableRow key={item.id}>
                <TableCell>{formatDate(item.startDate)}</TableCell>
                <TableCell>{formatDate(item.endDate)}</TableCell>
                <TableCell>
                  {calculateDayDifference(item.startDate, item.endDate)} Day(s)
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TableContainer sx={style.bodyTableButtonContainer}>
        <button style={style.bodyTablePTOButton} onClick={handleOpenModal}>
          Create New Personal Time Off
        </button>
        <BodyTableModal
          open={isOpen}
          handleClose={handleCloseModal}
          title="Create New Personal Time Off"
          contentBody={
            "You are creating a new Personal Time Off. Once you confirm the dates the system wont assign you new incidents during that period of time"
          }
          columns={columns}
          apiType={apiType}
          userID={userID}
          fetchData={fetchData}
        />
      </TableContainer>
    </TableContainer>
  );
};

export default BodyTable;
