export const modalStyles = (theme) => {
  return {
    modalContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      border: `1px solid ${theme.palette.custom.borderColor}`,
      boxShadow: 5,
      borderRadius: 1,
      width: '794px',
      padding: '24px',
      gap: '10px'
    },
    modalHeader: {
      marginBottom: '15px',
      display: 'flex',
      justifyContent: 'space-between',
      alignContent: 'center',
      alignItems: 'center'
    },
    closeIcon: {
      fontSize: '15px',
      cursor: 'pointer'
    },
    modalHeaderDivider: {
      width: '107%',
      position: 'relative',
      left: '-25px',
      marginBottom: '15px'
    },
    modalFooterDivider: {
      width: '107%',
      position: 'relative',
      left: '-25px',
      marginTop: '15px',
      marginBottom: '15px'
    },
    footerConatiner: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center'
    },
    closeButton: {
      color: theme.palette.common.white,
      width: '100%'
    },
    cancelButton: {
      color: theme.palette.custom.darkRed,
      marginRight: '20px'
    },
    addButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      "&:disabled": {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.custom.white
      }

    },
    formBody: {
      border: `1px solid ${theme.palette.custom.borderColor}`,
      borderRadius: 1
    },
    inputFontColor: {
      color: theme.palette.custom.darkText
    }
  };
};
