import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { useLocation } from "react-router-dom";
import DataTableMD from "../../core/dataTableMD";
import {
  incidentsColumns,
  getIncidentsFilters
} from "../configs/studentsClosedIncidentsDataConfig";

const ClosedIncidentsTab = (props) => {
  const { state } = useLocation();
  const [filters, setFilters] = useState([]);
  useEffect(() => {
    const getFilters = async () => {
      const filters = await getIncidentsFilters();
      setFilters(filters);
    };
    getFilters();
  }, []);

  return (
    <Container>
      <DataTableMD
        title="Incidents"
        dbTable={"incidents"}
        filters={filters}
        toggles={null} // or null for none
        columns={incidentsColumns}
        sort={{ field: "number", order: "desc" }}
        isTab={true}
        apiBody={{
          studentId: state.id,
          filters: [{ key: "status", value: "Closed", expression: "=" }],
        }}
      />
    </Container>
  );
};

export default ClosedIncidentsTab;
