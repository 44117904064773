import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Autocomplete,
  TextField,
  CircularProgress,
} from "@mui/material";

import { styles } from "../styles/editableContentDefault";
import { useTheme } from "@mui/material/styles";
import { api } from "../../../api";
import { localGet, localSet } from "../../../utils/localStorageUtils";
import { removedNamesForUpdate } from "../../../utils/utilsApi";
import { handlePhoneNumber, handleEmail } from "../../../utils/dataUtils";
import ApiSelect from "./apiSelect";

const EditableContentDefault = ({
  action,
  data,
  type,
  updating,
  setUpdating,
  validationType,
  card,
  values,
  setValues,
  validations,
  setValidations,
}) => {
  const navigate = useNavigate();
  const [thisUpdating, setThisUpdating] = useState(false);
  const [value, setValue] = useState(
    data[action.field] ? data[action.field] : ""
  );
  const [thisValue, setThisValue] = useState(null);
  const [validation, setValidation] = useState(
    action.type === "select" ? true : false
  );

  const theme = useTheme();
  const style = styles(theme);

  const processApiselectValues = () => {
    let tempValue = {id: data[action.field], label: data[action.labelField]};
    if (Array.isArray(data[action.field])) {
      const tempArray = [];
      for (let item of data[action.field]) {
        const index = data[action.field].indexOf(item);
        tempArray.push({id: item, label: data[action.labelField][index]});
      }
      tempValue = tempArray;
    }
    setThisValue(tempValue);
  };


  useEffect(() => {
    setValue(data[action.field] ? data[action.field] : "");
    if (action.type === "apiSelect" || action.type === "select") {
      processApiselectValues();
    }
    // eslint-disable-next-line
  }, [data]);

  const handleEdit = async (value, label) => {
    if (card) {
      const tempValues = { ...values };
      tempValues[action.field] = value;
      setValues(tempValues);
      setValue(value);
      if (action.type === "apiSelect" || action.type === "select") {
        processApiselectValues();
      }
    }
    if (!updating && !card) {
      setThisUpdating(true);
      setUpdating(true);
      let tempData = { ...data };
      if (Array.isArray(data[action.field]) && !Array.isArray(value)) {
        const tempArray = [];
        tempArray.push(value);
        tempData[action.field] = tempArray;
      } else {
        tempData[action.field] = value;
      }
      const results = await api("patch", tempData, type);
      if (results.status === 200) {
        setValue(value);
        if (action.type === "apiSelect" || action.type === "select") {
          processApiselectValues();
        }
        const localItems = await localGet(type, true);
      } else {
        setValue(data[action.field]);
        if (action.type === "apiSelect" || action.type === "select") {
          processApiselectValues();
        }
      }
      if (action.labelField) {
        tempData[action.labelField] = label;
      }
      setThisUpdating(false);
      navigate(".", { replace: true, state: tempData });
      setUpdating(false);
    }
  };

  const handleSelectChange = async (field, value, label) => {
    let tempLabel = value.label;
    if (action.multiple) {
      tempLabel = [];
      for (let item in label) {
        tempLabel.push(label[item]);
      }
    }
    let tempValue = value.id;
    if (action.multiple) {
      tempValue = [];
      for (let item in value) {
        tempValue.push(value[item]);
      }
    }
    if ((action.multiple && value.length !== 0) || !action.multiple) {
      handleEdit(tempValue, tempLabel);
    }
  };

  const handleTextChange = (thisValue) => {
    let tempValidation = validation;
    const newChar = thisValue[thisValue.length - 1];
    const changeType = thisValue.length > value.length ? "add" : "remove";
    if (validationType === "phone") {
      let { value, validations } = handlePhoneNumber(
        newChar,
        thisValue,
        action.field,
        changeType,
        null,
        tempValidation
      );
      tempValidation = validations;
      thisValue = value;
    } else if (validationType === "email") {
      handleEmail(thisValue, action.field, tempValidation, null);
    } else {
      if (thisValue !== "" && thisValue !== null && thisValue !== undefined) {
        tempValidation = true;
      }
    }
    if (validations) {
      const tempValidations = validations;
      tempValidations[action.field] = tempValidation;
      setValidation(tempValidation);
      setValidations(tempValidations);
    }
    handleEdit(thisValue, null);
  };

  const renderInput = (params, action) => {
    return (<TextField {...params}
        label={action.label}
        key={params.id}
        InputLabelProps={{shrink: true,}}
        placeholder={`Select a ${action.title}`}/>)
  };

  return (
    <Container
      sx={[
        style.editableContentDefaultItemData,
        thisUpdating && style.editableContentDefaultItemDataUpdating,
      ]}
    >
      {action.type === "text" && (
        <TextField
          type="text"
          value={value}
          sx={style.editableContentDefaultItemTextField(value, card)}
          onChange={(e) => handleTextChange(e.target.value)}
          disabled={action.disabled}
        />
      )}
      {action.type === "select" && thisValue && (
        <Autocomplete
          multiple={action.multiple}
          disablePortal
          getOptionLabel={(option) => option.label || "error"}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          options={action.options}
          value={thisValue}
          onChange={(e, value) => handleSelectChange(action.field, value, null)}
          sx={style.autoCompleteSelect}
          renderInput={(params) => renderInput(params, action)}
          fullWidth = {true}
          size="small"
        />
      )}
      {action.type === "apiSelect" && thisValue && (
        <ApiSelect
          filterValues={data}
          handleFilterChange={handleSelectChange}
          config={action}
          thisValue={thisValue}
          multiple={action.multiple}
          width={"auto"}
          label={null}
        />
      )}
      {thisUpdating && (
        <CircularProgress sx={style.editableContentDefaultProgress} />
      )}
    </Container>
  );
};

export default EditableContentDefault;
