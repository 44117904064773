import React from "react";
import { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { helpPageStyles } from "../../core/styles/cards/helpPageStyles";

const HelpAccordion = ({ summary, contentHeader, contentBody }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = (event) => {
    setExpanded(!expanded); // Toggle the state
  };

  const theme = useTheme();
  const styles = helpPageStyles(theme);

  return (
    <Accordion sx={styles.mdAccordionContainer}>
      <AccordionSummary
        sx={
          expanded
            ? styles.mdAccordionSummaryExpanded
            : styles.mdAccordionSummary
        }
        expandIcon={
          <span
            style={
              expanded
                ? styles.mdAccordionSummaryExpandedIcon
                : styles.mdAccordionSummaryIcon
            }
          >
            +
          </span>
        }
        onClick={handleToggle}
      >
        <Typography variant="h6">{summary}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={styles.mdAccordionContent}>
        <Typography sx={styles.mdAccordionContentHeading}>
          {contentHeader}
        </Typography>
        <Typography sx={styles.mdAccordionContentBody}>
          {contentBody}
        </Typography>
      </AccordionDetails>
      <div style={styles.mdAccordionLine}></div>
    </Accordion>
  );
};

export default HelpAccordion;
