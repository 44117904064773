export const styles = (theme) => {
  return {
    itemContainer: {
      display: "flex",
      flexDirection: "row",
      padding: "20px !important",
      borderBottom: `1px solid ${theme.palette.custom.borderColor}`,
    },
    icon: {
      marginRight: "10px",
    },
  };
};
