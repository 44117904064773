export const styles = (theme) => {
  return {
    bodyTableSuccessModelContainer: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "background.paper",
      border: `1px solid ${theme.palette.custom.borderColor}`,
      boxShadow: 5,
      borderRadius: 1,
      width: "794px",
      padding: "24px",
      gap: "10px",
    },
    bodyTableSuccessModelHeading: {
      fontWeight: "700",
      fontSize: "1.5rem",
      textAlign: "center",
    },
    bodyTableSuccessModelBody: {
      fontWeight: "400",
      fontSize: "1.125rem",
      textAlign: "center",
      marginTop: "1rem",
    },
    bodyTableSuccessModelFooterContainer: {
      marginTop: "1rem",
      textAlign: "center",
    },

    bodyTableSuccessModelCloseButton: {
      background: `${theme.palette.primary.main}`,
      color: `${theme.palette.custom.white}`,
      textAlign: "center",
    },
  };
};
