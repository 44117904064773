import { useContext, useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { Grid, Typography } from '@mui/material';
import GlobalAppContext from '../../context';
import { useTheme } from '@mui/material/styles';
import { styles } from '../styles/averageNumberStyles';

const AverageNumberofIncidents = ({config, data}) => {
  const [chartOptions, setChartOptions] = useState(null);

  const theme = useTheme();
  const { namespace  } = useContext(GlobalAppContext);
  const style = styles(theme);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      const categories = data.labels.map((label) => {
        return new Date(label).toLocaleDateString();
      });
      let tempOptions = chartOptions
      if (!chartOptions) {
        tempOptions = config;
      }
      tempOptions.series = data.data
      tempOptions.xaxis.categories = categories;
      tempOptions.xaxis.tickAmount = categories.length > 10 ? categories.length/5 : 10;
      const allValues = [...tempOptions.series[0].data, ...tempOptions.series[1].data, ...tempOptions.series[2].data]
      const maxyValue = Math.max(...allValues)
      tempOptions.yaxis.tickAmount = maxyValue > 15 ? 15 : maxyValue + 1;
      setChartOptions({...tempOptions});
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <Grid item xs={12} sx={style.averageContainer}>
      <Grid container>
        <Grid item xs={12} sx={style.heading}>
          <Typography variant='body'>
            {namespace.dashboard.averageNumberOfIncidentsPerStudent}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={style.margin('top', '20px')}>
        { chartOptions &&
          <Chart options={chartOptions} series={chartOptions.series} type={chartOptions.chart.type} width='100%' height={350} />
        }
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AverageNumberofIncidents;