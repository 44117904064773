export const styles = (theme, width) => {
  return {
    autoCompleteSelect: {
      width: width,
      marginTop: "0px",
      "& .MuiFormLabel-root": {
          width: "300px !important",
        color: `${theme.palette.custom.black}`,
        top: "-12px",
        left: "12px",
      },
      "&.Mui-focused .MuiFormLabel-root": {
        color: `${theme.palette.custom.black}`,
      },
      "& .MuiAutoComplete-root": {
        color: `${theme.palette.custom.black}`,
      },
      "& .MuiOutlinedInput-notchedOutline": {
          color: `${theme.palette.custom.black}`,
          border: `1px solid ${theme.palette.custom.borderColor}`,
      },
    },
    listBox: {
      maxHeight: "300px !important",
    },
  }
};