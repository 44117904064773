import ActiveIncidentsIcon from '../assets/activeIncidentsIcon.svg';
import ClosedIncidentsIcon from '../assets/closedIncidentsIcon.svg';
import ActiveEmergenciesIcon from '../assets/activeEmergenciesIcon.svg';
import ActiveEscalationsIcon from '../assets/activeEscalationsIcon.svg'

export const ActivityData = [{
    id: 1,
    name: 'Active Incidents',
    icon: ActiveIncidentsIcon,
    message: '',
    field: "activeIncidents"
  },
  {
    id: 2,
    name: 'Closed Incidents',
    icon: ClosedIncidentsIcon,
    message: '',
    field: "closedIncidents"
  },
  {
    id: 3,
    name: 'Active Emergencies',
    icon: ActiveEmergenciesIcon,
    message: '',
    field: "activeEmergencies"
  },
  {
    id: 4,
    name: 'Active Escalations',
    icon: ActiveEscalationsIcon,
    message: '',
    field: "activeEscalations"
  }];