import { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import Chart from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';
import { styles } from '../styles/priorityCardStyles';


const BarGraphCard = ({config, data, type}) => {
  const [barData, setBarData] = useState([]);
  const [options, setOptions] = useState(config.options);

  const theme = useTheme();
  const style = styles(theme);

  useEffect(() => {
    options.colors = config.types[type].colors;
    setOptions({...options});
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const tempData = [];
    for (let item in data) {
      tempData.push({data: [data[item].value]});
    }
    setBarData(tempData);
  }, [data]);

  return (
    <Grid item xs={12} sx={style.priorityContainer}>
      <Grid item xs={12} sx={style.heading}>
        <Typography variant='body'>
          {config.types[type].title}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={style.chartSpacing}>
        <Chart
          options={options}
          height={80}
          series={barData}
          type="bar"
          width='100%'
        />
      </Grid>
      <Grid container spacing={0.5}>
        {data.map((item, index) => (
          <Grid item key={index} xs={4} sx={[style.info, style.infoColor(config.types[type].colors[index])]}>
            <span >
              {item.label}
            </span>
            <span style={style.infoSpan}>
              {`${item.value} (${item.percentage}%)`}
            </span>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default BarGraphCard;