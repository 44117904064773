import React, { useState, useEffect } from "react";
import {
  Container,
  FormControl,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { formStyles } from "../../styles/forms/formStyles";
import { handleValues, handleValidations } from "../../../../utils/dataUtils";
import { ShowIcon, NoShowIcon } from "../../customIcons";
import ApiSelect from "../apiSelect";

const Form = ({
  fields,
  values,
  setValues,
  setEdited,
  validations,
  setValidations,
}) => {
  const userPermission = JSON.parse(localStorage.getItem("user")).permission;
  const initOptions = {};
  const initVisibility = {};
  for (let field of fields) {
    if (
      (field.showAdd === undefined || field.showAdd) &&
      field.type === "select"
    ) {
      initOptions[field.field] = field.options;
    }
    if (field.dataType === "password") {
      initVisibility[field.field] = false;
    }
  }
  const [options, setOptions] = useState(initOptions);
  const [show, setShow] = useState({});
  const [visibilty, setVisibility] = useState(initVisibility);
  const theme = useTheme();
  const styles = formStyles(theme);

  const handleValueChange = (fieldData, value, label) => {
    if (setEdited) {
      setEdited(true);
    }
    if (validations) {
      const thisValidation = handleValidations(fieldData, value);
      setValidations({ ...validations, [fieldData.field]: thisValidation });
    }

    const newChar = value[value.length - 1];
    const changeType =
      value.length > values[fieldData.field]?.length ? "add" : "remove";
    handleValues(fieldData, value, values, newChar, changeType);
    if (fieldData.field === "district" && label) {
      values.districtName = label;
    }
    if (fieldData.field === "school" && label) {
      values.schoolName = label;
    }
    setValues({ ...values });
  };

  const handleConfigOptions = async () => {
    const tempShow = show;
    for (let field of fields) {
      if ((field.showAdd === undefined || field.showAdd) && field.requires) {
        let showfield = true;
        for (let require in field.requires) {
          const requiresValue = values[require];
          if (
            requiresValue === "" ||
            requiresValue === null ||
            requiresValue === undefined
          ) {
            showfield = false;
          } else {
            if (field.requires[require].filter) {
              const tempOptions = options;
              const filteredOptions = field.options.filter((option) => {
                return option.district === requiresValue;
              });
              tempOptions[field.field] = filteredOptions;
              setOptions({ ...tempOptions });
            }
            if (
              !(
                !field.requires[require].requiresValues ||
                field.requires[require].requiresValues.includes(requiresValue)
              )
            ) {
              showfield = false;
            }
          }
        }
        tempShow[field.field] = showfield;
        const tempValues = values;
        if (!showfield) {
          tempValues[field.field] = null;
        } else if (tempValues[field.field] === null) {
          tempValues[field.field] = "";
        }
        setValues(tempValues);
      } else {
        tempShow[field.field] = true;
      }
    }
    setShow({ ...tempShow });
  };

  useEffect(() => {
    handleConfigOptions();
    // eslint-disable-next-line
  }, [values, fields]);

  const handlePasswordToggle = (id) => {
    const password = document.getElementById(id);
    if (!visibilty[id]) {
      password.type = "text";
      visibilty[id] = true;
      setVisibility({ ...visibilty });
    } else {
      password.type = "password";
      visibilty[id] = false;
      setVisibility({ ...visibilty });
    }
  };

  return (
    <Container sx={styles.formContainer}>
      {fields.map((field, index) => {
        if (
          (field.showAdd === undefined || field.showAdd) &&
          show[field.field] &&
          (!field.showPermissions ||
            field.showPermissions.includes(userPermission))
        ) {
          return (
            <FormControl fullWidth sx={styles.formControl} key={field.field}>
              <Typography sx={styles.inputLabel}>{field.headerName}</Typography>
              {field.type === "text" && (
                <TextField
                  sx={styles.textBox}
                  value={values[field.field]}
                  disabled={field.disabled}
                  id={field.field}
                  type={field.dataType}
                  onChange={(e) => handleValueChange(field, e.target.value)}
                  InputProps={
                    field.dataType === "password"
                      ? {
                          endAdornment: (
                            <InputAdornment position="end">
                              {visibilty[field.field] ? (
                                <ShowIcon
                                  sx={styles.visibilityIcon}
                                  onClick={() =>
                                    handlePasswordToggle(field.field)
                                  }
                                />
                              ) : (
                                <NoShowIcon
                                  sx={styles.visibilityIcon}
                                  onClick={() =>
                                    handlePasswordToggle(field.field)
                                  }
                                />
                              )}
                            </InputAdornment>
                          ),
                        }
                      : {}
                  }
                />
              )}
              {field.type === "select" && (
                <Select
                  sx={styles.select}
                  id={field.field}
                  value={values[field.field]}
                  disabled={field.disabled}
                  onChange={(e) => handleValueChange(field, e.target.value, null)}
                >
                  {options[field.field].map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
              {field.type === "apiSelect" && (
                <ApiSelect
                  filterValues={values}
                  handleFilterChange={handleValueChange}
                  config={field}
                  thisValue={(values[field.labelField] && values[field.field] !== "") ? {label: values[field.labelField], id: values[field.field]} : null}
                  width={"100%"}
                  fieldConfig={field}
                />
              )}
            </FormControl>
          );
        } else {
          return null;
        }
      })}
    </Container>
  );
};

export default Form;
