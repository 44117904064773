export const handlePostLogin = async (response, navigate) => {
    localStorage.setItem('accessToken', response.data.accessToken);
    localStorage.setItem('user', JSON.stringify(response.data.initData.userData));
    // localStorage.setItem('users', JSON.stringify(response.data.initData.users));
    // localStorage.setItem('students', JSON.stringify(response.data.initData.students));
    // localStorage.setItem('districts', JSON.stringify(response.data.initData.districts));
    // localStorage.setItem('schools', JSON.stringify(response.data.initData.schools));
    // localStorage.setItem('incidents', JSON.stringify(response.data.initData.incidents));
    localStorage.setItem('incidentConfig', JSON.stringify(response.data.initData.incidentConfig));
    // localStorage.setItem('timeStamps', JSON.stringify(response.data.initData.timeStamps));
    navigate('/dashboard');
}