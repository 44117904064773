import { createTheme } from "@mui/material/styles";
import { populateTheme } from "../utils/themeUtils";

const colors = {
  primary: {
    main: "#1890ff",
    dark: "#0070c2",
    light: "#E6F7FF;",
  },
  secondary: {
    main: "#1890FF",
    dark: "#004C82",
    light: "#40A9FF",
  },
  error: {
    main: "#962424",
    dark: "#6b1a1a",
    light: "#963838",
  },
  warning: {
    main: "#E54c4c",
    dark: "#FF4D4F",
    light: "#FFC5C6",
  },
  info: {
    main: "#2196f3",
    dark: "#1e88e5",
    light: "#64b5f6",
  },
  success: {
    main: "#4caf50",
    dark: "#277529",
    light: "#81c784",
  },
  custom: {
    white: "#ffffff",
    black: "#000000",
    gray1: "#F6F6F6",
    gray4: "#D9D9D9",
    gray5: "#8c8c8c",
    gray6: "#5B5B5B",
    turquoise: "#00000040",
    lightPink: "#F6F1F1",
    lightBlue: "#C2E8FF",
    lightGray: "#e0e0e0",
    borderColor: "#F0F0F0",
    backgroundColor: "#FAFAFA",
    gray: "#E8E8E8",
    darkGray: "#262626",
    green: "#27935F",
    blue: "#1B4380",
    red: "#FFC5C6",
    darkRed: "#FF4D4F",
    pink: "#EDBDF1",
    yellow: "#FFE299",
    lightpurple: "#dacdfa",
    textDark: "#131313",
    darkYellow: "#FBBD43",
    darkBlue: "#0F161E",
  },
};

const theme = populateTheme(colors);

export default createTheme(theme);
