export const styles = (theme) => {
  return {
    filterContainer: {
      paddingLeft: "20px !important",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      background: theme.palette.custom.white,
      borderBottom: `1px solid ${theme.palette.custom.gray4}`,
    },
    title: {
      paddingLeft: "10px",
      fontSize: "16px",
      marginRight: "10px",
    },
    leftContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      margin: "0px",
      marginRight: "10px",
      width: "auto",
    },
    districtSelect: {
      width: "300px",
      margin: "0px",
      marginTop: "10px",
      marginRight: "10px",
      "& .MuiFormLabel-root": {
        left: "14px !important",
        top: "-12px !important",
        color: `${theme.palette.custom.gray5} !important`,
        fontSize: "12px",
      },
      "& .MuiSelect-select": {
        height: "20px !important",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #F0F0F0 !important",
        height: "50px !important",
      },
    },
    schoolSelect: {
      width: "300px",
      margin: "0px",
      marginTop: "10px",
      "& .MuiFormLabel-root": {
        left: "14px !important",
        top: "-12px !important",
        color: `${theme.palette.custom.gray5} !important`,
        fontSize: "12px",
      },
      "& .MuiSelect-select": {
        height: "20px !important",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #F0F0F0 !important",
        height: "50px !important",
      },
    },
    rightContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      margin: "0px",
    },
    startDate: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "auto",
      margin: "0px",
      marginTop: "10px",
      marginRight: "10px",
      "& .MuiFormLabel-root": {
        left: "14px !important",
        top: "-12px !important",
        color: `${theme.palette.custom.gray5} !important`,
        fontSize: "12px",
      },
      "& .MuiSelect-select": {
        height: "40px !important",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #F0F0F0 !important",
        height: "50px !important",
      },
    },
    endDate: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "300px",
      margin: "0px",
      marginTop: "10px",
      "& .MuiFormLabel-root": {
        left: "14px !important",
        top: "-12px !important",
        color: `${theme.palette.custom.gray5} !important`,
        fontSize: "12px",
      },
      "& .MuiSelect-select": {
        height: "40px !important",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #F0F0F0 !important",
        height: "50px !important",
      },
    },

    secondformControl: {
      paddingLeft: "5px",
    },
  };
};
