export const styles = (theme) => {
  return {
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
      border: `1px solid ${theme.palette.custom.borderColor}`,
      backgroundColor: theme.palette.custom.white,
      padding: '24px !important',
      width: '70%',
    },
    heading: {
      display: 'flex',
      flexDirection: 'column'
    },
    link: {
      color: theme.palette.primary.main,
      fontSize: '12px',
      lineHeight: '12px'
    },

    margin: function(position, value) {
      switch (position.toLowerCase()) {
        case 'top':
          return { marginTop: value };
        case 'bottom':
          return { marginBottom: value };
        case 'left':
          return { marginLeft: value };
        case 'right':
          return { marginRight: value };
        default:
          return { margin: value };
      }
    }
  };
};
