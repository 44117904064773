/**
 * This is the data used to populate the help page accordions
 * summary - This is the property on the object that wil be displayed before the Accordion opens
 * contentHeader- This is the header that will be displayed for the Accordion
 * contentBody: This is the body that will be displayed for the Accordion
 */

const HelpData = [
  {
    id: 1,
    summary: "How do I change the status of a case?",
    contentHeader: "How do I change the status of a case?",
    contentBody:
      "To change the status of a case, navigate to the case details page and locate the status field. From there, you can select the appropriate status option from the dropdown menu and save your changes.",
  },
  {
    id: 2,
    summary:
      "How can I communicate with students to clarify certain situations?",
    contentHeader:
      "How can I communicate with students to clarify certain situations?",
    contentBody: "Test 1",
  },
  {
    id: 3,
    summary: "What should I do if I need to escalate a case?",
    contentHeader: "What should I do if I need to escalate a case?",
    contentBody: "Test 2",
  },
  {
    id: 4,
    summary: "Can I track the history of actions taken on a case?",
    contentHeader: "Can I track the history of actions taken on a case?",
    contentBody: "Test 3",
  },
];

export default HelpData;
