import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Select,
  MenuItem,
  CircularProgress,
  Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { api } from "../../../../api";

import { styles } from "../../styles/detailsView/detailViewHeaderTitle";
import { useTheme } from "@mui/material/styles";

import { getDateAtTime, getTitleString, getFieldVariables } from "../../../../utils/dataUtils";
import { localGet } from "../../../../utils/localStorageUtils";

const DetailViewHeaderTitle = ({
  data,
  titleConfig,
  type,
  title,
  updating,
  setUpdating,
  handleModalOpen,
}) => {
  const navigate = useNavigate();
  const [mainActionValue, setMainActionValue] = useState(
    titleConfig.mainAction ? data[titleConfig.mainAction.field] : null
  );
  const [mainActionUpdating, setMainActionUpdating] = useState(false);

  const theme = useTheme();
  const style = styles(theme);

  useEffect(() => {
    if (titleConfig.mainAction) {
      setMainActionValue(data[titleConfig.mainAction.field]);
    }
    // eslint-disable-next-line
  }, [data]);

  const typeDisplay = getTitleString(title);

  const handleSubTitleData = (type, data) => {
    if (type === "date") {
      return getDateAtTime(data);
    } else {
      return null;
    }
  };

  const handleComment = async (comment) => {
    const userData = localGet("user");
    const commentData = {
      comment: comment,
      incident: data.id,
      type: "Investigation Feedback",
      portalOnly: "False",
      role: "user",
      userId: userData.id,
    };
    await api("post", commentData, "comments");
  };

  const handleApiCall = async (field, value, tempData) => {
    setMainActionUpdating(true);
    if (field === titleConfig.mainAction.field) {
      setUpdating(true);
      const results = await api("patch", tempData, type);
      if (results.status === 200) {
        setMainActionValue(value);
        setMainActionUpdating(false);
      } else {
        tempData[field] = data[field];
        setMainActionValue(data[field]);
        setMainActionUpdating(false);
      }
      setUpdating(false);
      navigate(".", { replace: true, state: tempData });
    }
  };

  const handleDeleteApiCall = async () => {
    setMainActionUpdating(true);
    const deleteData = {
      id: data.id,
    };
    const results = await api("delete", deleteData, type);
    if (results.status === 200) {
      navigate(`/${title.replace(" ", "")}`, { replace: true });
      setMainActionUpdating(false);
    }
    setMainActionUpdating(false);
  };

  const handleModalSubmit = async (
    field,
    value,
    saveToDb,
    changeValue,
    changeField
  ) => {
    if (changeValue === "delete" && changeField === "delete") {
      await handleDeleteApiCall();
    } else {
      const tempData = { ...data };
      tempData[changeField] = changeValue;
      if (saveToDb === true) {
        tempData[field] = value;
      }
      await handleApiCall(changeField, changeValue, tempData);
      if (saveToDb === "comment") {
        await handleComment(value);
      }
    }
    return;
  };

  const handleUpdate = async (field, value) => {
    if (!updating) {
      if (
        titleConfig?.mainAction?.modalConfig &&
        titleConfig.mainAction.modalConfig[value]
      ) {
        const modalConfig = titleConfig.mainAction.modalConfig[value];
        modalConfig.changeValue = value;
        modalConfig.changeField = field;
        modalConfig.submitFunction = handleModalSubmit;
        handleModalOpen(modalConfig);
      } else {
        const tempData = { ...data };
        tempData[field] = value;
        handleApiCall(field, value, tempData);
      }
    }
  };

  return (
    <Container sx={style.detailViewHeaderTitleContainer}>
      <Container sx={style.detailViewHeaderTitlesContainer}>
        <Typography variant="h4" sx={style.detailViewHeaderTitle}>
          {titleConfig.title ? (
            titleConfig.title
          ) : (
            <>
              {typeDisplay + " "}
              {titleConfig.preString && titleConfig.preString}
              {getFieldVariables(titleConfig.field, data)}
              {titleConfig.postString && titleConfig.postString}
            </>
          )}
        </Typography>
        {titleConfig.subTitleConfig && (
          <Typography variant="h6" sx={style.detailViewHeaderSubtitle}>
            {titleConfig.subTitleConfig.preString &&
              titleConfig.subTitleConfig.preString}
            {handleSubTitleData(
              titleConfig.subTitleConfig.type,
              data[titleConfig.subTitleConfig.field]
            )}
            {titleConfig.subTitleConfig.postString &&
              titleConfig.subTitleConfig.postString}
          </Typography>
        )}
      </Container>
      {titleConfig.mainAction && titleConfig.mainAction.type === "dropdown" && (
        <Container sx={style.detailViewHeaderMainActionContainer}>
          {mainActionUpdating && (
            <CircularProgress
              sx={style.detailViewHeaderMainActionSelectProgress}
            />
          )}
          <Container sx={style.detailViewHeaderMainActionSelectContainer}>
            <Typography variant="h6" sx={style.detailViewHeaderMainActionTitle}>
              {titleConfig.mainAction.title}
            </Typography>
            <Select
              displayEmpty
              value={mainActionValue}
              onChange={(e) =>
                handleUpdate(titleConfig.mainAction.field, e.target.value)
              }
              sx={[
                style.detailViewHeaderMainActionSelect,
                style.selectBackground(
                  titleConfig.mainAction.backgroundColor[mainActionValue]
                ),
              ]}
            >
              {titleConfig.mainAction.options.map((option, index) => (
                <MenuItem
                  value={option.id}
                  key={index}
                  sx={[
                    style.detailViewHeaderMainActionMenuItem,
                    option.hidden && style.detailViewHeaderMainActionMenuHidden,
                  ]}
                >
                  {titleConfig.mainAction.optionsConfig &&
                  titleConfig.mainAction.optionsConfig.type === "chip" ? (
                    <Chip
                      label={option.label}
                      sx={style.selectBackground(
                        titleConfig.mainAction.backgroundColor[option.label]
                      )}
                    />
                  ) : (
                    <Typography>option.label</Typography>
                  )}
                </MenuItem>
              ))}
            </Select>
          </Container>
        </Container>
      )}
      {titleConfig.mainAction && titleConfig.mainAction.type === "text" && (
        <Container
          sx={style.detailViewHeaderMainActionContainer}
          onClick={() =>
            handleUpdate(
              titleConfig.mainAction.field,
              titleConfig.mainAction.field
            )
          }
        >
          {mainActionUpdating && (
            <CircularProgress
              sx={style.detailViewHeaderMainActionSelectProgressText}
            />
          )}
          <Typography
            variant="h6"
            sx={[
              style.detailViewHeaderMainActionTitle,
              style.detailViewHeaderTextColor(titleConfig.mainAction.textColor),
              style.detailViewHeaderTextStyles(
                titleConfig.mainAction.textStyles
              ),
            ]}
          >
            {titleConfig.mainAction.preIcon && titleConfig.mainAction.preIcon}
            {titleConfig.mainAction.text}
          </Typography>
        </Container>
      )}
    </Container>
  );
};

export default DetailViewHeaderTitle;
