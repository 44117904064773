export const styles = (theme) => {
  return {
    loginContainer: {
      margin: "0px",
      padding: "0px !important",
      minWidth: "100%",
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    },
    loginLeftDiv: {
      margin: "0px",
      padding: "0px !important",
      width: "40%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: `${theme.palette.secondary.dark}`,
    },
    loginLogo: {
      width: "90%",
      height: "90%",
    },
    loginRightDiv: {
      width: "60%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#FFFFFF",
    },
    loginTitle: {
      fontSize: "35px",
      fontWeight: "700",
      lineHeight: "38px",
      letterSpacing: "0em",
      marginBottom: "10px",
    },
    loginSubTitle: {
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "38px",
      letterSpacing: "0em",
      textAlign: "center",
    },
    loginFormWrapper: {
      width: "430px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    loginForm: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "0px",
      padding: "0px !important",
      minWidth: "100%",
    },
    loginInputLabel: {
      flex: "1",
      display: "flex",
      alignSelf: "flex-start",
      marginTop: "10px",
      marginBottom: "5px",
      color: `${theme.palette.custom.gray5}`,
      fontSize: "14px",
    },
    loginInputTextbox: {
      margin: "0px",
      minWidth: "100%",
      padding: "10px",
      marginBottom: "5px",
      border: `1px solid ${theme.palette.custom.borderColor}`,
      borderRadius: "5px",
      fontSize: "18px",
      webkitAutofill: "none !important",
      WebkitBoxShadow: "none !important",
      "& .MuiOutlinedInput-input": {
        padding: "3px 30px 3px 0px !important",
        border: "none !important",
        color: `${theme.palette.custom.darkGray}`,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        margin: "0px",
        border: "none !important",
      },
      "&.Mui-focused .MuiOutlinedInput-input": {
        margin: "0px",
        border: "none !important",
        padding: "3px 30px 3px 0px !important",
      },
      "&.input:-webkit-autofill": {
        webkitBoxShadow: `0 0 0 30px ${theme.palette.custom.red} inset !important`,
        webkitTextFillColor: "red",
        backgroundColor: `${theme.palette.custom.red} !important`,
      },
    },
    signInButton: {
      marginTop: "20px",
      marginBottom: "20px",
      minWidth: "80%",
      padding: "10px",
      borderRadius: "5px",
      backgroundColor: `${theme.palette.secondary.main}`,
      color: `${theme.palette.custom.white}`,
      fontSize: "18px",
      fontWeight: "600",
      cursor: "pointer",
    },
    warningText: {
      margin: "0px",
      padding: "0px !important",
      color: `${theme.palette.warning.main}`,
      fontSize: "14px",
    },
    loginForgotPassword: {
      textDecoration: "underline",
      fontSize: "16px",
      color: `${theme.palette.secondary.main}`,
      cursor: "pointer",
    },
    sendNewCode: {
      textDecoration: "underline",
      fontSize: "20px",
      color: `${theme.palette.secondary.main}`,
      cursor: "pointer",
      marginBottom: "20px",
    },
    codeTextBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginRight: "10px",
      width: "60px",
      marginBottom: "5px",
      border: `1px solid ${theme.palette.custom.gray4}`,
      borderRadius: "20px",
      fontSize: "18px",

      "& .MuiOutlinedInput-input": {
        border: "none !important",
        color: `${theme.palette.custom.gray5}`,
        width: "10px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        margin: "0px",
        border: "none !important",
      },
      "&.Mui-focused .MuiOutlinedInput-input": {
        margin: "0px",
        border: "none !important",
        padding: "3px 30px 3px 0px !important",
      },
    },
    codeTextBoxContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    passRequirements: {
      margin: "0px",
      marginTop: "10px",
      padding: "0px !important",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "10px",
    },
    passRequirementsLeft: {
      margin: "0px",
      padding: "0px !important",
      display: "flex",
      flexDirection: "column",
    },
    passRequirementsRight: {
      margin: "0px",
      padding: "0px !important",
      display: "flex",
      flexDirection: "column",
    },
    visibilityIcon: {
      cursor: "pointer",
      width: "20px",
    },
  };
};

export const passRequirement = (status, theme) => {
  const returnObj = {
    fontSize: "14px",
    color: `${theme.palette.custom.gray}`,
    marginBottom: "5px",
  };
  if (status === true) {
    returnObj.color = `${theme.palette.custom.green}`;
  } else if (status === false) {
    returnObj.color = `${theme.palette.warning.main}`;
  }
  return returnObj;
};
