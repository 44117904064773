export const styles = (theme) => {
  return {
    loadingIcon: (page) => {
      if (page === "activity") {
        return {
          width: "120px",
          marginTop: "60px",
        };
      }
      if (page === "content") {
        return {
          position: "absolute",
          width: "100px",
          top: "20%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        };
      } else if (page === "login") {
        return {
          position: "absolute",
          width: "100px",
          top: "50%",
          left: "70%",
          transform: "translate(-50%, -50%)",
        };
      } else if (page === "dataTable") {
        return {
          width: "150px !important",
        };
      } else if (page === "comments") {
        return {
          position: "relative",
          width: "120px",
          top: "50%",
          left: "18%",
          transform: "translate(-50%, -50%)",
        };
      } else if (page === "sendItAgain") {
        return {
          position: "relative",
          top: "30px",
          left: "50px",
          width: "100px",
          transform: "translate(-50%, -50%)",
        };
      } else if (page === "dashboard") {
        return {
          width: "120px",
          position: "absolute",
          top: "50%",
          left: "50%",
        };
      } else {
        return {
          position: "absolute",
          width: "100px",
          top: "20%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        };
      }
    },
  };
};
