export const overTimeConfig = {
    series: [{
        name: "Incidents",
        data: []
    }],
    chart: {
        width: '100%',
        height: 350,
        type: 'area',
        redrawOnParentResize: true,
        zoom: {
        enabled: false
        }
    },
    dataLabels: {
        enabled: false
    },
    legend: {
        show: false,
    },
    stroke: {
        curve: 'straight',
        width: 1
    },
    grid: {

        xaxis: {
            lines: {
                show: false
            }
        },
        yaxis: {
            lines: {
                show: true
            }
        },
    },
    xaxis: {
        categories: [],
        tickAmount: 10,
    },
    yaxis: {
        tickAmount: 10,
    },
    colors: []
};