import React, {useContext, Suspense } from 'react';
import { Navigate } from 'react-router-dom'
import { Container } from '@mui/material';

import { LoadingIcon } from '../feature/core/loadingIcon';
import SideBarComponent from './components/componentSidebar';
import ContentComponent from './components/componentContent';
import GlobalAppContext from '../feature/context';
import WebSocketProvider from '../feature/context/websocketsContext';

import {
    screenContainer,
    screenContentRightClose,
    screenContentRightOpen
} from './styles/defaultLayout';


/**
 * Todo: This description needs to be filled
 * @returns 
 */
const DefaultLayout = () => {
    const accessToken = localStorage.getItem('accessToken');
    const { sideNavigationOpen } = useContext(GlobalAppContext);
    return (
        <>
            {
                accessToken ? (
                    <WebSocketProvider>
                        <Container sx={screenContainer}>
                            <Container>
                                <SideBarComponent />
                            </Container>
                            <Container sx={sideNavigationOpen ? screenContentRightOpen : screenContentRightClose}>
                                <Suspense fallback={<LoadingIcon page={"content"}/>}>
                                    <ContentComponent />
                                </Suspense>
                            </Container>
                        </Container>
                    </WebSocketProvider>
                ) : (
                    <Navigate to='/auth/login' />
                )
            }
        </>
    )
}

export default DefaultLayout;