export const styles = (theme) => {
  return {
    dataTableContainer: {
      minHeight: "calc(100vh - 353px)",
      maxHeight: "calc(100vh - 353px)",
      borderRadius: "0px",
      margin: "0px",
      backgroundColor: `${theme.palette.custom.white}`,
      border: `1px solid ${theme.palette.custom.borderColor}`,
      width: "100%",
    },

    dataTableHeaderRow: {
      height: "56px",
      backgroundColor: `${theme.palette.custom.backgroundColor}`,
      fontFamily: "Nunito Sans",
    },

    dataTableHeaderCell: {
      fontFamily: "Nunito Sans",
      padding: "0px",
      paddingTop: "15px",
      paddingBottom: "15px",
      borderBottom: `1px solid ${theme.palette.custom.borderColor}`,
    },

    dataTableHeaderCellText: {
      borderLeft: `1px solid ${theme.palette.custom.borderColor}`,
      fontFamily: "Nunito Sans",
      fontWeight: "bold",
      textAlign: "left",
      margin: "0px",
      padding: "0px",
      paddingLeft: "16px",
      paddingTop: "5px",
      paddingBottom: "5px",
    },

    dataTableHeaderCellTextFirst: {
      borderLeft: "none",
    },

    dataTableCellTextBold: {
      fontWeight: "bold",
    },

    dataTableCell: {
      fontFamily: "Nunito Sans",
      borderBottom: `1px solid ${theme.palette.custom.borderColor}`,
    },

    dataTableChip: {
      borderRadius: "4px",
      margin: "0 0 10px 0",
    },

    dataTableProfileBubble: {
      borderRadius: "50%",
      width: "45px",
      height: "45px",
      margin: "0px 0",
    },

    dataTableCellGroup: {
      fontFamily: "Nunito Sans",
    },
  };
};
