export const styles = (theme) => {
  return {
    detailViewTabsContainer: {
      minWidth: "100%",
      padding: "0px !important",
    },

    detailViewTabsListContainer: {
      alignItems: "end",
      height: "80px",
      display: "flex",
      minWidth: "100%",
      padding: "0px !important",
      borderBottom: `1px solid ${theme.palette.custom.lightGray}`,
    },

    detailViewTabContainer: {},

    detailViewTabsListTabContainer: {
      display: "flex",
      width: "auto",
      marginRight: "0px",
      marginLeft: "60px",
      padding: "0px !important",
      alignItems: "center",
    },

    detailViewTabsContentContainer: {
      minWidth: "100%",
      padding: "0px !important",
      minHeight: "calc(100vh - 96)",
      overflow: "auto",
    },

    detailViewTabsListButton: {
      paddingBottom: "10px",
      fontFamily: "Nunito Sans",
      fontSize: "24px",
    },

    detailViewTabActive: {
      borderBottom: `3px solid ${theme.palette.primary.main}`,
    },

    detailViewTabTextActive: {
      fontWeight: "600",
    },

    detailViewTabsTitleChip: {
      marginBottom: "10px",
      marginLeft: "10px",
      backgroundColor: `${theme.palette.custom.borderColor}`,
      color: `${theme.palette.custom.black}`,
      fontSize: "12px",
      fontWeight: "600",
      padding: "0px",
      borderRadius: "20px",
      height: "24px",
    },

    detailViewTabsTitleChipActive: {
      backgroundColor: `${theme.palette.primary.light}`,
    },

    tabContainer: {
      display: "flex",
      minWidth: "100%",
      padding: "0px !mportant",
      margin: "0px !important",
    },
  };
};
