
import { Component } from "react";

/***
 * The standard default loading indicator that needs to be used throughout the entire application.
 */
export class LoadingIndicatorComponent extends Component {
    render() {
        return (
            <div className="loading-indicator">
                <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 48 47">

                    {/* Bubble Bottom Left */}
                    <linearGradient
                        className="bubble-bottom-left" id="SVGID_1_"
                        gradientUnits="userSpaceOnUse" gradientTransform="matrix(1 0 0 -1 0 48)"
                        x1="6.9484" y1="41.1306" x2="47.0811" y2="0.9979" >
                        <stop offset="0"/>
                        <stop offset="0.9984" />
                    </linearGradient>

                    <path className="bubble-point" d="M46.7,46.1C46.7,46.1,46.8,46.1,46.7,46.1l-23.5-0.1c-12.7,0-22.9-10.3-22.9-23c0-12.7,10.3-22.9,22.9-22.9 s23,10.3,23,22.9c0,4.8-1.5,9.3-4.1,13l5.3,9.2c0,0,0,0,0,0l0.5,0.8L46.7,46.1z" />

                    {/* Bubble Top Right */}
                    <linearGradient
                        className="bubble-top-right" id="SVGID_00000161623966746375175540000003042307788318822567_"
                        gradientUnits="userSpaceOnUse" gradientTransform="matrix(1 0 0 -1 0 48)"
                        x1="-21.539" y1="84.9662" x2="43.3996" y2="20.0276">
                        <stop offset="0"/>
                        <stop offset="0.1875"/>
                        <stop offset="0.5612"/>
                        <stop offset="0.8404"/>
                    </linearGradient>

                    {/* Half Bubble Over Backdrop */}
                    <path className="bubble-top-right-path" d="M11.7,3.1c0,0,6.5,28.5,30.4,33 c8.4-11.7,2.5-25.6-3.8-30.6C29.4-1.4,19.5-1.2,11.7,3.1z" />

                    {/* <!-- Bubble Left --> */}
                    <path className="dot dot-left" d="M13.3,23.1c1.4,0,2.6,1.2,2.6,2.6c0,1.4-1.2,2.6-2.6,2.6s-2.6-1.2-2.6-2.6C10.7,24.2,11.8,23.1,13.3,23.1z" />

                    {/* <!-- Bubble Right --> */}
                    <path className="dot dot-right" d="M34.7,23.1c1.4,0,2.6,1.2,2.6,2.6c0,1.4-1.2,2.6-2.6,2.6c-1.4,0-2.6-1.2-2.6-2.6C32.1,24.2,33.3,23.1,34.7,23.1 z" />

                    {/* <!-- Bubble Middle --> */}
                    <path className="dot dot-middle" d="M24,21.9c2.1,0,3.7,1.7,3.7,3.7c0,2.1-1.7,3.7-3.7,3.7c-2.1,0-3.7-1.7-3.7-3.7C20.3,23.6,21.9,21.9,24,21.9z" />

                </svg>
            </div>
        );
    };
};