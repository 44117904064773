import React from 'react';
import { useEffect, useState } from 'react';
import DataTableMD from '../core/dataTableMD';
import { getSchoolsColumns, getSchoolFilters } from './configs/schoolDataTableConfig';

/**
 * This generates the Schools Page for the application
 * @param {*} props These are the properties required for the Schools page (user, etc)
 * @returns This returns a generated Schools page for the user
 */
const SchoolsPage = (props) => {
    const [filters, setFilters] = useState([])
    const [columns, setColumns] = useState([])

    useEffect(() => {
        const getFilters = async () => {
            const filters = await getSchoolFilters()
            const columns = await getSchoolsColumns()
            setColumns(columns) 
            setFilters(filters)
        }
        getFilters()
    }, [])
    const addSuccessMessage = "The new school has been successfully added to the system. "
    return (
        <DataTableMD
            title="Schools"
            dbTable={"schools"}
            filters={filters} // or null for none
            columns={columns}
            addButton={true}
            addSuccessMessage={addSuccessMessage}
            sort={{field: 'name', order: 'asc'}}
        />
    );
};

export default SchoolsPage;