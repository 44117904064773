/**
 * This generates the Users Page for the application
 * @param {*} props These are the properties required for the Users page (user, etc)
 * @returns This returns a generated Users page for the user
 */
const ErrorFourOFour = (props) => {
    return (
        <div>
            404 Not Found
        </div>
    );
};

export default ErrorFourOFour;