import TranslationNamespaces from "../translation-namespaces";

const afZA = {
  components: {
    MuiBreadcrumbs: {
      defaultProps: {
        expandText: "Broodkrummel",
      },
    },
    MuiTablePagination: {
      defaultProps: {
        getItemAriaLabel: function (type) {
          if (type === "first") {
            return "Gaan na eerste bladsy";
          } else if (type === "last") {
            return "Gaan na laatste bladsy";
          } else if (type === "next") {
            return "Gaan na volgende bladsy";
          } else {
            return "Gaan na vorige bladsy";
          }
        },
        labelRowsPerPage: "Reëls per bladsy:",
        labelDisplayedRows: function ({ from, to, count }) {
          return `${from}–${to} van ${count !== -1 ? count : `meer as ${to}`}`;
        },
      },
    },
    MuiRating: {
      defaultProps: {
        getLabelText: function (value) {
          return `${value} Ster${value !== 1 ? "ren" : ""}`;
        },
        emptyLabelText: "Leeg",
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        clearText: "Vee uit",
        closeText: "Maak toe",
        loadingText: "Besig om te laai…",
        noOptionsText: "Geen opsies",
        openText: "Maak oop",
      },
    },
    MuiAlert: {
      defaultProps: {
        closeText: "Maak toe",
      },
    },
    MuiPagination: {
      defaultProps: {
        "aria-label": "Navigasie via paginering",
        getItemAriaLabel: function (type, page, selected) {
          if (type === "page") {
            return `${selected ? "" : "Gaan na "}bladsy ${page}`;
          } else if (type === "first") {
            return "Gaan na eerste bladsy";
          } else if (type === "last") {
            return "Gaan na laatste bladsy";
          } else if (type === "next") {
            return "Gaan na volgende bladsy";
          } else {
            return "Gaan na vorige bladsy";
          }
        },
      },
    },
  },
  [TranslationNamespaces.Dashboard]: {
    overview: "Oorsig",
  },
};

export default afZA;
