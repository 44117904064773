export const bodyTableStyles = (theme) => {
  return {
    bodyTableHeaderRow: {
      height: "56px",
      backgroundColor: `${theme.palette.custom.backgroundColor}`,
      fontFamily: "Nunito Sans",
    },

    bodyTableButtonContainer: {
      textAlign: "end",
      width: "100%",
      padding: "2rem 1rem 2rem 0rem",
      border: `1px solid ${theme.palette.custom.lightGray}`,
      marginTop: "2rem",
    },

    bodyTablePTOButton: {
      color: `${theme.palette.primary.main}`,
      background: `${theme.palette.custom.white}`,
      border: "none",
      fontWeight: "700",
    },

    bodyTableModalBodyModalHeader: {
      color: `${theme.palette.custom.darkGray}`,
      padding: "0rem 1.5rem 1rem 1.5rem",
      fontWeight: "700",
      justifyContent: "space-between",
      display: "flex",
      alignItems: "center",
    },

    bodyTableSubText: {
      color: `${theme.palette.custom.darkGray}`,
      background: `${theme.palette.custom.white}`,
      fontWeight: "400",
      fontSize: "0.875rem",
      padding: "0rem 1.5rem 1rem 1.5rem",
    },
  };
};
