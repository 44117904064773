export const styles = (theme) => {
  return {
    mainContainer: {
      marginTop: "40px",
      marginLeft: "60px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
    },
    title: {
      fontSize: "20px",
      fontWeight: "500",
      marginBottom: "20px",
    },
    textArea: {
      display: "flex",
      width: "calc(100% - 120px)",
      maxWidth: "1000px",
      borderRadius: "4px",
      boxShadow: `0px 0px 8px 0px ${theme.palette.custom.turquoise} inset`,
      height: "calc(100vh - 800px)",
      minHeight: "150px",
      marginBottom: "20px",
      overflow: "scroll",
      "& .MuiOutlinedInput-root": {
        padding: "0px !important",
      },
      "& .MuiOutlinedInput-input": {
        color: `${theme.palette.custom.textDark}`,
        border: "none !important",
        fontSize: "18px !important",
        fontWeight: "500 !important",
        padding: "20px !important",
        overflow: "scroll",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none !important",
      },
      "&.Mui-focused .MuiOutlinedInput-input": {
        padding: "0px !important",
      },
    },
    submitButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      padding: "10px 15px 10px 15px",
      borderRadius: "8px",
      "&:hover": {
        backgroundColor: theme.palette.primary.main ,
      },
    },
    saveButtonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      width: "calc(100% - 120px)",
      maxWidth: "1000px !important",
      margin: "0px",
    },
    saveButton: {
      display: "flex",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.custom.white,
      padding: "10px 15px 10px 15px",
      borderRadius: "8px",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    saveProgress: {
      color: theme.palette.custom.white,
      marginLeft: "10px",
      width: "15px !important",
      height: "15px !important",
      padding: "0px !important",
    },
  };
};
