import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Container } from "@mui/material";

import { LoadingIndicatorComponent } from "../feature/core/components/loadingIndicator";
import { styles } from "../feature/auth/styles/login";
import { useTheme } from "@mui/material/styles";

import LoginLogoIcon from "../feature/core/icons/loginLogo";
/**
 * This generates the Login Page for the application
 * @param {*} props These are the properties required for the login page (user, etc)
 * @returns This returns a generated login page for the user, that is used to log into the application
 */
const LoginPage = (props) => {
  const theme = useTheme();
  const style = styles(theme);
  return (
    <Suspense fallback={<LoadingIndicatorComponent />}>
      <Container sx={style.loginContainer}>
        <Container sx={style.loginLeftDiv}>
          <LoginLogoIcon sx={style.loginLogo} />
        </Container>
        <Container sx={style.loginRightDiv}>
          <Outlet />
        </Container>
      </Container>
    </Suspense>
  );
};

export default LoginPage;
