import React from 'react';
import InfoContainerMD from '../../core/infoContainerMD';
import { schoolInfoConfig } from '../configs/schoolsInfoConfig';


const SchoolInformationTab = ({data, handleOpen, updating, setUpdating }) => {
    return (
        <InfoContainerMD
            config={schoolInfoConfig}
            infoData={data}
            incidentData={true}
            handleOpen={handleOpen}
            updating={updating}
            setUpdating={setUpdating}
            type={"schools"}
        />
    );
};

export default SchoolInformationTab;