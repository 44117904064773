export const styles = (theme) => ({
  detailViewHeaderActionsContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    minWidth: "auto",
    padding: "0px !important",
    alignItems: "center",
  },
  detailViewHeaderActionItemContainer: {
    display: "flex",
    flexDirection: "column",
    width: "auto",
    marginLeft: "0px",
    marginRight: "40px",
    padding: "0px !important",
  },
  detailViewHeaderActionItemTitle: {
    fontFamily: "Nunito Sans",
    color: `${theme.palette.custom.black}`,
    fontWeight: "700",
    width: "100%",
  },
  detailViewHeaderActionItemText: {
    color: `${theme.palette.custom.black}`,
    fontWeight: "400",
    fontFamily: "Nunito Sans",
    alignItems: "center",
    display: "flex",
  },
  detailViewHeaderActionItemData: {
    padding: "6px 0px 5px 0px !important",
    marginRight: "0px",
    display: "flex",
    alignItems: "center",
    width: "auto",
  },
});
