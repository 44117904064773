
/**
 * This is the default headings for Districts.
 * field - This is the property name inside the payload related to the heading ie DB property name.
 * heading - This is the Title that will be displayed for the Values.
 * editable- true if field should be editable, false otherwise.
 */

export const districtsHeadings = [
    {
        field: 'superintendent',
        heading: 'Superintendent',
        editable: true,
        type: 'string',
        column: 1
    },
    {
        field: 'phone',
        heading: 'Phone Number',
        editable: true,
        type: 'phone',
        column: 1
    },
    {
        field: 'address',
        heading: 'Address',
        editable: true,
        type: 'string',
        column: 1
    }
]
