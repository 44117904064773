import { Chip, Typography } from "@mui/material";
import {
  EscalatedIcon,
  EmergencyIcon,
  DeEscalateIcon,
} from "../feature/core/customIcons";
import { styles } from "../feature/incidents/styles/activityTab";
import { getChipColor } from "../utils/utilsDataTable";

export const getDateAtTime = (date) => {
  if (!date) {
    return null;
  }
  date = date.replace("Z", "");
  const localTomezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dateStr = new Date(date + "+0000")
    .toLocaleString("en-US", { timeZone: localTomezone })
    .split(",");
  return `${dateStr[0]} at ${dateStr[1]}`;
};

export const getTitleString = (title, plural = false) => {
  if (plural) {
    const titleString =
      title.charAt(0).toUpperCase() + title.substring(1, title.length - 1);
    if (titleString.charAt(titleString.length - 1) === "s") {
      return titleString;
    } else {
      return titleString + "s";
    }
  } else if (title.charAt(title.length - 1) === "s") {
    return title.charAt(0).toUpperCase() + title.substring(1, title.length - 1);
  } else {
    return title.charAt(0).toUpperCase() + title.substring(1, title.length);
  }
};

export const getFieldVariables = (field, data) => {
  if (field.includes("{") && field.includes("}")) {
    const fieldVariables = field.match(/{(.*?)}/g);
    fieldVariables.forEach((variable) => {
      const variableName = variable.substring(1, variable.length - 1);
      field = field.replace(variable, data[variableName]);
    });
  } else {
    field = data[field];
  }
  return field;
};

export const getStatusColor = (status, theme) => {
  let color = "gray";
  if (status === "New") {
    color = "green";
  } else if (status === "Investigate") {
    color = "blue";
  } else if (status === "Waiting") {
    color = "pink";
  } else if (status === "Closed") {
    color = "darkGray";
  } else if (status === "Blocked") {
    color = "yellow";
  }
  return getChipColor(color, theme);
};

export const getActivityData = (activityData, theme) => {
  const style = styles(theme);
  let returnData = {};
  returnData.time = getDateAtTime(activityData.timestamp);
  const nameSplit = activityData.updatedBy.split(" ");
  returnData.initials =
    nameSplit[0].charAt(0).toUpperCase() +
    nameSplit[nameSplit.length - 1].charAt(0).toUpperCase();
  if (activityData.change === "created") {
    returnData.message = (
      <Typography sx={style.activityEntryHeaderMessage} key="1">
        created this case
      </Typography>
    );
    returnData.body = false;
  } else if (
    activityData.change.key === "escalated" &&
    activityData.change.value === "True"
  ) {
    returnData.message = [
      <Typography sx={style.activityEntryHeaderMessage} key="1">
        escalated this case
      </Typography>,
      <EscalatedIcon key="2" />,
    ];
    returnData.body = false;
  } else if (
    activityData.change.key === "escalated" &&
    activityData.change.value === "False"
  ) {
    returnData.message = [
      <Typography sx={style.activityEntryHeaderMessage} key="1">
        de-escalated this case
      </Typography>,
      <DeEscalateIcon key="2" />,
    ];
    returnData.body = false;
  } else if (
    activityData.change.key === "emergency" &&
    activityData.change.value === "True"
  ) {
    returnData.message = [
      <Typography sx={style.activityEntryHeaderMessage} key="1">
        marked this case as an emergency
      </Typography>,
      <EmergencyIcon key="2" />,
    ];
    returnData.body = false;
  } else if (
    activityData.change.key === "emergency" &&
    activityData.change.value === "False"
  ) {
    returnData.message = (
      <Typography sx={style.activityEntryHeaderMessage} key="1">
        marked this case as an non-emergency
      </Typography>
    );
    returnData.body = false;
  } else if (activityData.change.key === "status") {
    returnData.message = [
      <Typography key="1" sx={style.activityEntryHeaderMessage}>
        changed the status to
      </Typography>,
      <Chip
        key="2"
        label={activityData.change.value}
        sx={[
          style.activityEntryHeaderMessageChip,
          getStatusColor(activityData.change.value, theme),
        ]}
      />,
      <Typography key="3" sx={style.activityEntryHeaderMessage}>
        from
      </Typography>,
      <Chip
        key="4"
        label={activityData.change.prevValue}
        sx={[
          style.activityEntryHeaderMessageChip,
          getStatusColor(activityData.change.prevValue, theme),
        ]}
      />,
    ];
    returnData.body = false;
  } else if (activityData.change.key === "type") {
    returnData.message = (
      <Typography sx={style.activityEntryHeaderMessage} key="1">
        {`changed the type to ${activityData.change.value.join(
          ", "
        )} from ${activityData.change.prevValue.join(", ")}`}
      </Typography>
    );
    returnData.body = false;
  } else if (activityData.change.key === "priority") {
    returnData.message = `changed the priority to ${activityData.change.value} from ${activityData.change.prevValue}`;
    returnData.body = false;
  } else if (activityData.change.key === "assignedUsers") {
    returnData.message = (
      <Typography sx={style.activityEntryHeaderMessage} key="1">
        {`assigned this case to ${activityData.change.value[0]}`}
      </Typography>
    );
    returnData.body = false;
  } else if (activityData.change === "studentComment") {
    returnData.message = (
      <Typography sx={style.activityEntryHeaderMessage} key="1">
        sent a new message in the chat
      </Typography>
    );
    returnData.body = "comment";
  } else if (activityData.change === "responderCommentResponder") {
    returnData.message = (
      <Typography sx={style.activityEntryHeaderMessage} key="1">
        left a new comment
      </Typography>
    );
    returnData.body = "comment";
  } else if (activityData.change === "responderCommentStudent") {
    returnData.message = (
      <Typography sx={style.activityEntryHeaderMessage} key="1">
        sent a new message to the student in the chat
      </Typography>
    );
    returnData.body = "comment";
  } else if (activityData.change.key === "number") {
    returnData.message = (
      <Typography sx={style.activityEntryHeaderMessage} key="1">
        {`assigned ${activityData.change.value} as the incident number`}
      </Typography>
    );
    returnData.body = false;
  } else {
    returnData = null;
  }
  return returnData;
};

export const handlePhoneNumber = (
  newChar,
  value,
  field,
  changeType,
  values,
  validations
) => {
  if (
    (!isNaN(newChar) || changeType === "remove") &&
    ((changeType === "add" && value.length <= 13) || changeType === "remove")
  ) {
    if (value.length === 3) {
      if (changeType === "add") {
        value = `(${value})`;
      }
    }
    if (value.length === 4) {
      if (changeType === "remove") {
        value = value.slice(1, 3);
      }
    }
    if (value.length === 5) {
      if (changeType === "remove") {
        value = value.slice(1, 3);
      }
    }
    if (value.length === 8) {
      if (changeType === "add") {
        value = `${value}-`;
      } else {
        value = value.slice(0, 7);
      }
    }
    if (values) {
      values[field] = value;
    }
  }
  if (value.length >= 13) {
    if (value.length > 13) {
      value = value.slice(0, 13);
    }
    if (typeof validations === "object" && validations) {
      validations[field] = true;
    } else {
      validations = true;
    }
  } else {
    if (typeof validations === "object" && validations) {
      validations[field] = false;
    } else {
      validations = false;
    }
  }
  return { validations, values, value };
};

export const handleEmail = (value, field, validations, values) => {
  const emailRegex = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  if (emailRegex.test(value)) {
    if (typeof validations === "object" && validations) {
      validations[field] = true;
    } else {
      validations = true;
    }
    if (values) {
      values[field] = value;
    }
  } else {
    if (typeof validations === "object" && validations) {
      validations[field] = false;
    } else {
      validations = false;
    }
    if (values) {
      values[field] = value;
    }
  }
  return { validations, values, value };
};

export const handleValidations = (columnData, value) => {
  let validation = false;
  if (
    columnData.required &&
    value !== "" &&
    value !== null &&
    value !== undefined
  ) {
    if (
      columnData.dataType === "string" ||
      columnData.dataType === "password"
    ) {
      validation = true;
    } else if (columnData.dataType === "email") {
      const response = handleEmail(value, columnData.field, validation, null);
      validation = response.validations;
    } else if (columnData.dataType === "phone") {
      const response = handlePhoneNumber(
        1,
        value,
        columnData.field,
        "add",
        null,
        validation
      );
      validation = response.validations;
    }
  }
  return validation;
};

export const handleValues = (
  columnData,
  value,
  values,
  newChar,
  changeType
) => {
  if (
    columnData.required &&
    value !== "" &&
    value !== null &&
    value !== undefined
  ) {
    if (
      columnData.dataType === "string" ||
      columnData.dataType === "password"
    ) {
      values[columnData.field] = value;
    } else if (columnData.dataType === "email") {
      const response = handleEmail(value, columnData.field, null, values);
      value = response.value;
    } else if (columnData.dataType === "phone") {
      const response = handlePhoneNumber(
        newChar,
        value,
        columnData.field,
        changeType,
        values,
        null
      );
      value = response.value;
    }
  } else {
    values[columnData.field] = value;
  }
  return { values, value };
};

export const handlePasswordValidation = (
  value,
  type,
  passRequirements,
  setPassRequirements,
  password
) => {
  let tempPassRequirements = passRequirements;
  if (Object.values(tempPassRequirements).includes("init")) {
    tempPassRequirements = {
      length: false,
      upper: false,
      lower: false,
      number: false,
      special: false,
      match: false,
    };
    setPassRequirements({ ...tempPassRequirements });
  }
  if (type === "new") {
    if (value.length >= 8) {
      tempPassRequirements["length"] = true;
    } else {
      tempPassRequirements["length"] = false;
    }

    if (value.match(/[A-Z]/)) {
      tempPassRequirements["upper"] = true;
    } else {
      tempPassRequirements["upper"] = false;
    }

    if (value.match(/[a-z]/)) {
      tempPassRequirements["lower"] = true;
    } else {
      tempPassRequirements["lower"] = false;
    }

    if (value.match(/[0-9]/)) {
      tempPassRequirements["number"] = true;
    } else {
      tempPassRequirements["number"] = false;
    }

    if (value.match(/[!@#$%^&*]/)) {
      tempPassRequirements["special"] = true;
    } else {
      tempPassRequirements["special"] = false;
    }
  } else {
    if (value === password) {
      tempPassRequirements["match"] = true;
    } else {
      tempPassRequirements["match"] = false;
    }
  }
  setPassRequirements({ ...tempPassRequirements });
};
