import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalAppContext from '../../feature/context';
import { generateNavigationList } from '../../utils/utilsNavigationItems';
import { sideBarDrawer } from '../styles/componentSidebarStyle';
import Logo from '../../feature/core/icons/logo.svg';
import LogoIcon from '../../feature/core/icons/logoIcon.svg';
import { useLocation } from 'react-router-dom';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(6)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7.3)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);
  
const SideBarComponent = () => {
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem('user'));
  const { sideNavigationOpen } = React.useContext(GlobalAppContext);
  const listItems = generateNavigationList(user, location); 

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer variant="permanent" open={sideNavigationOpen} sx={sideBarDrawer}>
        <DrawerHeader>
          <img src={sideNavigationOpen ? Logo : LogoIcon} alt='logo' width='100%' height={sideNavigationOpen ? 'auto' : '40px'} style={{
            transition: 'width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
          }} />
        </DrawerHeader>
        <List>
          {listItems[0].map((text, index) => (
              <Box key={index} sx={{ paddingLeft: '56px' }}>
                {text}
              </Box>
            )
          )}
        </List>
        <List sx={{
          position: 'absolute',
          bottom: 0,
          width: '21vw'
        }}>
          {listItems[1].map((text, index) => (
            <Box key={index} sx={{ paddingLeft: '56px' }}>
              {text}
            </Box>
          ))}
        </List>
      </Drawer>
    </Box>
  );
};

export default SideBarComponent