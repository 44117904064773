export const styles = (theme) => ({
  infoContainerMain: {
    display: "flex",
    flexWrap: "wrap",
    margin: "0px",
    marginTop: "30px",
    padding: "0px !important",
    justifyContent: "space-between",
    minWidth: "100%",
  },
  infoContainerMainFirstChild: {
    flex: "1 1 auto",
  },
  infoContainerColumnContainer: {
    margin: "0px",
    padding: "0px !important",
    marginRight: "120px",
    width: "auto",
  },
  infoContainerCellContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "0px",
    padding: "0px !important",
    marginBottom: "30px",
    width: "auto",
  },
  infoContainerCellHeading: {
    fontSize: "22px",
    fontWeight: "700",
    fontFamily: "Nunito Sans",
    marginBottom: "10px",
    width: "auto",
  },
  infoContainerCellText: {
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: "Nunito Sans",
    marginBottom: "0px",
    width: "auto",
  },
  infoContainerColumnWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "0px",
    marginLeft: "60px",
    padding: "0px !important",
    width: "40%",
  },
  infoContainerCellContainerButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: `${theme.palette.primary.main}`,
    color: `${theme.palette.custom.white}`,
    borderRadius: "8px",
    width: "auto",
    padding: "10px 20px 10px 20px !important",
    cursor: "pointer",
  },
  infoContainerCellContainerButtonTitle: {
    fontSize: "17px",
    fontWeight: "700",
    fontFamily: "Nunito Sans",
    marginRight: "10px",
  },
  image :{
    width: "90px",
    height: "90px",
  }
});
