import React, { useState, useEffect } from "react";
import { Container, Button, CircularProgress } from "@mui/material";
import DetailInfoCardPage from "../../core/components/detailsView/detailInfoCardPage";
import { getCardConfig } from "../configs/myAccountConfig";
import { useTheme } from "@mui/material/styles";
import { styles } from "../styles/myAccount.jsx";
import { api } from "../../../api";
import { localSet } from "../../../utils/localStorageUtils.js";
import { useNavigate } from "react-router-dom";

const MyAccountConfig = ({ data, handleOpen, updating, setUpdating }) => {
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [validations, setValidations] = useState({});
  const [edited, setEdited] = useState(false);
  const [cards, setCards] = useState([]);

  const theme = useTheme();
  const style = styles(theme);

  const getDefaultValues = () => {
    const initValues = {};
    for (let field in data) {
      initValues[field] = data[field];
    }
    for (let card in cards) {
      for (let field in cards[card].fields) {
        if (!(cards[card].fields[field].field in initValues)) {
          initValues[cards[card].fields[field].field] = "";
        }
      }
    }
    return initValues;
  };

  useEffect(() => {
    const fetchData = async () => {
      const cards = await getCardConfig(data);
      setCards(cards);
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data) {
      setValues(getDefaultValues());
    }
    // eslint-disable-next-line
  }, [cards]);

  const handleSaveClick = async () => {
    if (!updating) {
      setUpdating(true);
      let tempData = { ...data };
      for (const field in values) {
        tempData[field] = values[field];
      }
      const results = await api("patch", tempData, "users");
      if (results.status === 200) {
        navigate(".", { replace: true, state: tempData });
        localSet("user", tempData, true);
      } else {
        navigate(".", { replace: true, state: { ...data } });
      }
      setUpdating(false);
    }
  };

  const handleCancelClick = () => {
    const tempValues = getDefaultValues();
    setValues({ ...tempValues });
    setEdited(false);
  };

  return (
    <Container sx={style.mainContainer}>
      {cards.length > 0 && (
        <DetailInfoCardPage
          data={data}
          cards={cards}
          values={values}
          setValues={setValues}
          validations={validations}
          setValidations={setValidations}
          setEdited={setEdited}
        />
      )}
      {cards.length > 0 && edited && (
        <Container sx={style.buttonContainer}>
          <Button sx={style.buttonCancel} onClick={handleCancelClick}>
            Cancel
          </Button>
          <Button
            sx={style.buttonSave}
            onClick={handleSaveClick}
            disabled={Object.values(validations).includes(false)}
          >
            {updating ? "Saving" : "Save"}
            {updating && <CircularProgress sx={style.buttonProgress} />}
          </Button>
        </Container>
      )}
    </Container>
  );
};

export default MyAccountConfig;
