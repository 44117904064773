export const styles = (theme) => {
  return {
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '20px',
    },
    dashboardContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '16px',
      marginRight: '16px',
      width: 'calc(100% - 32px)',
    },
    title: {
      marginTop: '20px',
      fontSize: '30px',
      fontWeight: '600',
      color: theme.palette.custom.textDark,
      marginBottom: '20px',
    },
    secondRowContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: '20px',
      marginTop: '20px',
    },
    barGraphContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'space-between',
      justifyContent: 'space-between',
      width: '30%',
    },
    resolveTimesContainer: {
      padding: '20px !important',
      marginTop: '30px',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.custom.gray1,
    },
    averageResolveTimeConatiner: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: '20px',
    },
    studentOverViewContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '20px',
    },
    noDistrict: {
      marginTop: 'auto',
      marginRight: 'auto',
      marginLeft: 'auto',
      marginBottom: 'auto',
      fontSize: '20px',
      fontWeight: '600',
      color: theme.palette.custom.textDark,
    },

    cardContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      marginTop: '20px',
    },

    incidentContainer: {
      height: '453px',
      borderRadius: '4px',
      border: `1px solid ${theme.palette.custom.gray4}`,
      justify: 'space-between',
      padding: '24px',
      gap: '40px'
    }
  };
};