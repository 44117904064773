export const getChipColor = (color, theme) => {
  const colorBackgroundMap = {
    'blue': {
      backgroundColor: theme.palette.custom.blue,
      color: theme.palette.custom.white,
    },
    'green': {
      backgroundColor: theme.palette.custom.green,
      color: theme.palette.custom.white,
    },
    'pink': {
      backgroundColor: theme.palette.custom.pink,
      color: theme.palette.custom.white,
    },
    'yellow': {
      backgroundColor: theme.palette.custom.yellow,
      color: theme.palette.custom.darkText,
    },
    'gray': {
      backgroundColor: theme.palette.custom.gray,
      color: theme.palette.custom.darkText,
    },
    'darkGray': {
      backgroundColor: theme.palette.custom.darkGray,
      color: theme.palette.custom.white,
    },
  };
  return colorBackgroundMap[color];
};
