export const multiGraphConfig = {
    series: [],
    chart: {
      width: '100%',
      height: 350,
      type: 'area',
      redrawOnParentResize: true,
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false,
    },
    stroke: {
      curve: 'straight',
      width: [1,1,1]
    },
    grid: {
      row: {
        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      },
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      tickAmount: 10,
    },
    colors: ['#3DBC51', '#FAAD14', '#DC484A']
  };