export const formStyles = (theme) => {
  return {
    formContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    formControl: {
      display: "flex",
      flexDirection: "column",
      marginBottom: "5px",
    },
    inputLabel: {
      color: theme.palette.custom.gray5 + "!important",
      flex: "1",
      display: "flex",
      alignSelf: "flex-start",
      marginTop: "5px",
      marginBottom: "5px",
      fontSize: "14px",
    }, 
    textBox: {
      color: `${theme.palette.custom.black}`,
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.custom.gray4} !important`
      },
    },
    select: {
      color: `${theme.palette.custom.black}`,
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.custom.gray4} !important`
      },
    },
    visibilityIcon: {
      cursor: "pointer",
      width: "20px",
    },
  };
};
