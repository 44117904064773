export const styles = (theme) => {
  return {
    bodyTableModalContainer: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "background.paper",
      border: `1px solid ${theme.palette.custom.borderColor}`,
      boxShadow: 5,
      borderRadius: 1,
      width: "794px",
      padding: "24px",
      gap: "10px",
    },
    bodyTableModalBodyModalHeader: {
      color: `${theme.palette.custom.darkGray}`,
      padding: "0rem 1.5rem 1rem 1.5rem",
      fontWeight: "700",
      justifyContent: "space-between",
      display: "flex",
      alignItems: "center",
    },
    bodyTableSubText: {
      color: `${theme.palette.custom.darkGray}`,
      background: `${theme.palette.custom.white}`,
      fontWeight: "400",
      fontSize: "0.875rem",
      padding: "0rem 1.5rem 1rem 1.5rem",
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    formControl: {
      display: "flex",
      flexDirection: "column",
      marginBottom: "5px",
    },
    inputLabel: {
      color: theme.palette.custom.gray + "!important",
      flex: "1",
      display: "flex",
      alignSelf: "flex-start",
      marginTop: "5px",
      marginBottom: "5px",
      fontSize: "14px",
    },
    closeIcon: {
      fontSize: "15px",
      cursor: "pointer",
    },
    modalHeaderDivider: {
      width: "107%",
      position: "relative",
      left: "-25px",
      marginBottom: "15px",
    },
    modalFooterDivider: {
      width: "107%",
      position: "relative",
      left: "-25px",
      marginTop: "15px",
      marginBottom: "15px",
    },
    footerConatiner: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    cancelButton: {
      color: theme.palette.custom.red,
      marginRight: "20px",
    },

    addButton: {
      color: theme.palette.custom.white,
    },
  };
};
