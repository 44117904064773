export const styles = (theme) => ({
  userInfoContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "0px !important",
    height: "calc(100vh - 58px)",
    margin: "0px !important",
  },
  userInfoHeaderContainer: {
    marginLeft: "20px",
  },
  userInfoTitle: {
    marginLeft: "40px",
    fontSize: "24px",
    fontWeight: "700",
  },
  userInfoCardContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "calc(100% - 80px)",
    marginTop: "10px",
    marginLeft: "20px",
    paddingTop: "20px !important",
  },
  userInfoCardContainerLeft: {
    display: "flex",
    flexDirection: "column",
    width: "48%",
    padding: "0px !important",
    margin: "0px !important",
  },
  userInfoCardContainerRight: {
    display: "flex",
    flexDirection: "column",
    width: "48%",
    padding: "0px !important",
    margin: "0px !important",
  },
  userInfoCard: {
    backgroundColor: `${theme.palette.custom.white}`,
    marginBottom: "20px",
    borderRadius: "4px",
    border: `1px solid ${theme.palette.custom.borderColor}`,
  },
  userInfoCardHeader: {
    padding: "15px !important",
    borderBottom: `1px solid ${theme.palette.custom.borderColor}`,
  },
  userInfoCardHeaderTitle: {
    fontSize: "18px",
    fontWeight: "400",
  },
  userInfoCardBody: {
    // Add your styles for userInfoCardBody here if needed
  },
  userInfoPersonalCard: {
    display: "flex",
    flexDirection: "column",
    width: "48%",
    padding: "0px !important",
    margin: "0px !important",
  },
  userInfoAccountCard: {
    display: "flex",
    flexDirection: "column",
    width: "48%",
    padding: "0px !important",
    margin: "0px !important",
  },
  userInfoTimeOffCard: {
    display: "flex",
    flexDirection: "column",
    width: "48%",
    padding: "0px !important",
    margin: "0px !important",
  },
});
