export const styles = (theme) => {
  return {
    editableContentDefaultItemTextField: (string, card) => {
      let width = "100%";
      if (!card) {
        width = string ? string.length * 10 : 100;
      }
      return {
        color: `${theme.palette.custom.black} !important`,
        fontWeight: "400",
        fontFamily: "Nunito Sans",
        width: width,
        padding: "10px",
        borderRadius: "4px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
        "& .MuiOutlinedInput-input": {
          padding: "2.5px !important",
          color: `${theme.palette.custom.black} !important`,
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none !important",
        },
        "&.Mui-focused .MuiOutlinedInput-input": {
          padding: "0px !important",
        },
      };
    },

    editableContentDefaultItemSelect: (string, card) => {
      return {
        width: "100%",
        paddingRight: "20px !important",
        "& .MuiOutlinedInput-input": {
          padding: "10px !important",
          border: "none !important",
          width: "100%",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${theme.palette.custom.gray4} !important`,
          width: "100%",
        },
      };
    },

    editableContentDefaultItemMenuItem: {},

    editableContentDefaultItemData: {
      padding: "6px 0px 5px 0px !important",
      marginRight: "0px",
      borderRadius: "6px",
      display: "flex",
      alignItems: "center",
    },

    editableContentDefaultItemDataUpdating: {},

    editableContentDefaultProgress: {
      marginRight: "0px",
      marginLeft: "10px",
      padding: "0px",
      color: `${theme.palette.primary.main}`,
      height: "30px !important",
      width: "30px !important",
    },

    autoCompleteSelect: {
      width: "100%",
      minWidth: "220px",
      margin: "0px",
      "& .MuiFormLabel-root": {
          width: "300px !important",
        color: `${theme.palette.custom.black}`,
        top: "-12px",
        left: "12px",
      },
      "&.Mui-focused .MuiFormLabel-root": {
        color: `${theme.palette.custom.black}`,
      },
      "& .MuiAutoComplete-root": {
        color: `${theme.palette.custom.black}`,
      },
      "& .MuiOutlinedInput-notchedOutline": {
          color: `${theme.palette.custom.black}`,
          border: `1px solid ${theme.palette.custom.borderColor}`,
      },
    },
  };
};
