import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, TextField, Button, CircularProgress, useTheme } from '@mui/material';
import { styles } from '../styles/outcomeTab';
import { api } from '../../../api';
import { localGet, localSet } from '../../../utils/localStorageUtils';
import { outcomeConfig } from '../configs/incidentOutcomeConfig';


const OutcomeTab = ({data, handleOpen, updating, setUpdating, handleModalOpen }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const style = styles(theme);
    const [outcome, setOutcome] = useState(data.outcomeDraft ? data.outcomeDraft : "");
    const [thisUpdating, setThisUpdating] = useState(false);
    const [sent, setSent] = useState(false)

    const handleChange = (value) => {
        setOutcome(value);
    }

    const handleSaveButton = async () => {
        if (!updating){
            setThisUpdating(true);
            setUpdating(true);
            let tempData = {...data}
            tempData.outcomeDraft = outcome;
            const results = await api("patch", tempData, "incidents")
            if (results.status === 200){
                const localItems = await localGet("incidents", true)
                localItems.forEach((item, index) => {
                    if (item.id === tempData.id){
                        localItems[index] = tempData
                    }
                })
                navigate(".", { replace: true, state: tempData });
                localSet("incidents", localItems, true)
            } else {
                navigate(".", { replace: true, state: {...data} });
            }
            setUpdating(false);
            setThisUpdating(false);
        }
    }

    const handleSentModalSubmit = async (field, value, saveToDb, changeValue, changeField) => {
        setUpdating(false);
    }

    const handleModalSubmit = async (field, value, saveToDb, changeValue, changeField) => {
        let tempData = {...data};
        tempData.status = "Waiting";
        const results = await api("patch", tempData, "incidents")
        if (results.status === 200){
            const localItems = await localGet("incidents", true)
            localItems.forEach((item, index) => {
                if (item.id === tempData.id){
                    localItems[index] = tempData
                }
            })
            localSet("incidents", localItems, true)
            const userData = localGet("user");
                const commentData = {
                comment: outcome,
                incident: data.id,
                type: "Investigation Feedback",
                portalOnly: "False",
                role: "user",
                userId: userData.id
            }
            const commentResults = await api("post", commentData, "comments")
            if (commentResults.status === 200){
                navigate(".", { replace: true, state: tempData });
            } else {
                navigate(".", { replace: true, state: {...data} });
            }
        }
        setSent(true);
    }

    useEffect(() => {
        if (sent === true){
            const modalConfig = outcomeConfig.modalConfig.sent
            modalConfig.changeValue = "";
            modalConfig.changeField = "";
            modalConfig.submitFunction = handleSentModalSubmit;
            handleModalOpen(modalConfig);
        }
        // eslint-disable-next-line
    }, [sent])

    const handleSendButton = () => {
        setUpdating(true);
        const modalConfig = outcomeConfig.modalConfig.confirm
        modalConfig.changeValue = "status";
        modalConfig.changeField = "Waiting";
        modalConfig.submitFunction = handleModalSubmit;
        handleModalOpen(modalConfig);
    }
    return (
        <Container sx={style.mainContainer}>
            <Typography sx={style.title}>Please share the outcome of this investigation with the student. </Typography>
            <TextField 
                    multiline
                    placeholder="Type something here..." 
                    sx={style.textArea}
                    onChange={(e) => handleChange(e.target.value)}
                    value={outcome}
                    disabled={thisUpdating}
                />
            <Container sx={style.saveButtonContainer}>
                <Button
                    sx={style.saveButton}
                    disabled={outcome === "" ? true : false}
                    onClick={() => handleSaveButton()}
                >
                    {thisUpdating ? (
                        "Saving"
                    ) : (
                        "Save"
                    )}
                    {thisUpdating && (
                    <CircularProgress sx={style.saveProgress}/>
                    )}
                </Button>
            </Container>
            <Button 
                sx={style.submitButton}
                disabled={data.status === "Investigate" ? false : true}
                onClick={() => handleSendButton()}
            >
                Send Outcome To Student
            </Button>
        </Container>
    );
};

export default OutcomeTab;