import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Select,
  TextField,
  CircularProgress,
  MenuItem,
} from "@mui/material";

import { styles } from "../styles/editableContent";
import { useTheme } from "@mui/material/styles";
import { EditIcon } from "../customIcons";
import { api } from "../../../api";
import { removedNamesForUpdate } from "../../../utils/utilsApi";
import { handlePhoneNumber, handleEmail } from "../../../utils/dataUtils";
import ApiSelect from "./apiSelect";

const EditableContent = ({
  action,
  data,
  type,
  updating,
  setUpdating,
  validationType,
}) => {
  const navigate = useNavigate();
  const [editIcons, setEditIcons] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editValue, setEditValue] = useState(null);
  const [editLabelValue, setEditLabelValue] = useState(null);
  const [validation, setValidation] = useState(
    (action.type === "select" || action.type === "apiSelect") ? true : false
  );

  const theme = useTheme();
  const style = styles(theme);

  useEffect(() => {
    setEditValue(data[action.field] ? data[action.field] : "");
    setEditLabelValue(data[action.labelField]);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (editing === "editing" && action.type === "text") {
      document.getElementById("editAbleContentTextField").focus();
    }
    // eslint-disable-next-line
  }, [editing]);

  const handleEditHover = (direction) => {
    let tempEditIcons = editIcons;
    if (direction === "in") {
      tempEditIcons = true;
    } else if (direction === "out") {
      tempEditIcons = false;
    }
    setEditIcons(tempEditIcons);
  };

  const handleEditClick = () => {
    let tempEditIcons = editIcons;
    let tempEditing = editing;
    tempEditing = "editing";
    tempEditIcons = false;
    setEditing(tempEditing);
    setEditIcons(tempEditIcons);
  };

  const handleEdit = async (field, value, label) => {
    if (!updating && validation) {
      setUpdating(true);
      let tempData = { ...data };
      let tempEditing = editing;
      tempData[field] = value;
      if (action.labelField) {
        tempData[action.labelField] = label;
      }
      const record = await removedNamesForUpdate({ ...tempData });
      const results = await api("patch", record, type);
      if (results.status === 200) {
        setEditing(false);
        setEditIcons(false);
      } else {
        tempEditing = false;
        setEditing(tempEditing);
        const tempEditValue = data[field];
        setEditValue(tempEditValue);
      }
      navigate(".", { replace: true, state: tempData });
      setUpdating(false);
    }
  };

  const handleTextChange = (thisValue) => {
    let tempValidation = validation;
    const newChar = thisValue[thisValue.length - 1];
    const changeType = thisValue.length > editValue.length ? "add" : "remove";
    if (validationType === "phone") {
      let { value, validations } = handlePhoneNumber(
        newChar,
        thisValue,
        action.field,
        changeType,
        null,
        tempValidation
      );
      tempValidation = validations;
      thisValue = value;
    } else if (validationType === "email") {
      handleEmail(thisValue, action.field, tempValidation, null);
    } else {
      if (thisValue !== "" && thisValue !== null && thisValue !== undefined) {
        tempValidation = true;
      }
    }
    setEditValue(thisValue);
    setValidation(tempValidation);
  };

  const handleKeyDown = () => {
    if (validation) {
      handleEdit(action.field, editValue, null);
      setEditing("updating");
    }
  };

  const handleSelectChange = (field, value, label) => {
    const selectData = document.getElementById(value);
    setEditing("updating");
    setEditValue(value);
    setEditLabelValue(label ? label : selectData.textContent);
    handleEdit(action.field, value, label);
  };

  return (
    <Container
      id={action.field}
      sx={[
        style.editableContentItemData,
        editing === "editing" && style.editableContentItemDataEditing,
        editIcons && style.editableContentItemDataIconShow,
      ]}
      onMouseEnter={(e) => handleEditHover("in", action)}
      onMouseLeave={(e) => !editing && handleEditHover("out", action)}
      onKeyDown={(e) => e.key === "Enter" && handleKeyDown()}
    >
      {action.type === "text" && !editing && (
        <Typography variant="subtitle1" sx={style.editableContentItemText}>
          {editValue}
        </Typography>
      )}
      {action.type === "text" && editing === "editing" && (
        <TextField
          type="text"
          id="editAbleContentTextField"
          value={editValue}
          sx={style.editableContentItemTextField(editValue)}
          onChange={(e) => handleTextChange(e.target.value)}
        />
      )}
      {action.type === "text" && editing === "updating" && (
        <Typography variant="subtitle1" sx={style.editableContentItemText}>
          {editValue}
          <CircularProgress sx={style.editableContentItemTextProgress} />
        </Typography>
      )}
      {(action.type === "select" || action.type === "apiSelect") && !editing && (
        <Typography variant="subtitle1" sx={style.editableContentItemText}>
          {editLabelValue}
        </Typography>
      )}
      {action.type === "select" && editing === "editing" && (
        <Select
          displayEmpty
          id="editAbleContentSelect"
          value={editValue}
          onChange={(e) => handleSelectChange(null, e.target.value, null)}
          sx={style.editableContentItemSelect}
        >
          {action.options.map((option) => (
            <MenuItem
              value={option.value}
              id={option.value}
              key={option.value}
              sx={style.editableContentItemMenuItem}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      )}
      {action.type === "apiSelect" && editing === "editing" && (
        <ApiSelect
          filterValues={data}
          handleFilterChange={handleSelectChange}
          config={action}
        />
      )}
      {(action.type === "select" || action.type === "apiSelect") && editing === "updating" && (
        <Typography variant="subtitle1" sx={style.editableContentItemText}>
          {editLabelValue}
          <CircularProgress sx={style.editableContentItemTextProgress} />
        </Typography>
      )}
      {editIcons && [undefined, false].includes(editing) && (
        <EditIcon
          id="editContentButton"
          sx={style.editableContentEditIcon}
          onClick={() => handleEditClick(action)}
        />
      )}
    </Container>
  );
};

export default EditableContent;
