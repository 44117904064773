export const styles = (theme) => {
  return {
    resolveContainer: {
      display: "flex",
      flexDirection: "column",
      height: "453px",
      borderRadius: "4px",
      border: `1px solid ${theme.palette.custom.gray1}`,
      backgroundColor: theme.palette.custom.white,
      justify: "space-between",
      padding: "24px !important",
      width: "30%",
    },
    tableContainer: {
      display: "flex",
    },
    tableBody: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    tableRow: {
      display: "flex",
      flexDirection: "row",
      height: "32px",
    },
    labelCell: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      border: "none !important",
      overflow: "clip",
      whiteSpace: "nowrap",
    },
    percentageCell: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start !important",
      width: "60%",
      border: "none !important",
    },
    title: {
      fontSize: "16px",
      marginBottom: "24px",
    },
    subHeading: {
      fontSize: "16px",
      marginBottom: "8px",
    },
    percentageContainer: function (percentage) {
      return {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: theme.palette.custom.white,
        borderRadius: "4px",
        width: `${percentage}%`,
        background: theme.palette.primary.main,
        height: "18px",
      };
    },
    rowColor: function (index) {
      return {
        background:
          index % 2 === 0
            ? `${theme.palette.custom.gray1}`
            : "transparent",
      };
    },
    pagination: {
      display: "flex",
      justifyContent: "center",
      marginTop: "15px",
    },
  };
};
