import React from "react";
import { Container, Typography } from "@mui/material";

import { styles } from "../styles/headerBreadCrumbs";
import { useTheme } from "@mui/material/styles";

const HeaderBreadCrumbs = ({ first, last }) => {
  const theme = useTheme();
  const style = styles(theme);
  return (
    <Container sx={style.headerBreadCrumbs}>
      <Typography variant="subtitle1" sx={style.headerBreadCrumbsFirst}>
        {first}&nbsp;&nbsp;/
      </Typography>
      <Typography variant="subtitle1" sx={style.headerBreadCrumbsSecond}>
        &nbsp;&nbsp;{last}
      </Typography>
    </Container>
  );
};

export default HeaderBreadCrumbs;
