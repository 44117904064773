export const barGraphConfig = {
  options: {
    chart: {
      stacked: true,
      stackType: "100%",
      redrawOnParentResize: true,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          hideOverflowingLabels: false,
        }
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 0
    },
    legend: {
      show: false,
    },
    xaxis: {
      show: false,
      showAlways: false,
      showForNullSeries: false,
      opposite: false,
      reversed: false,
      logarithmic: false,
      forceNiceScale: false,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
        
    },
    yaxis: {
      show: false,
      showAlways: false,
      showForNullSeries: false,
      opposite: false,
      reversed: false,
      logarithmic: false,
      forceNiceScale: false,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
        
    },
    grid: {
      show: false
    }
  },
  types: {
    outcome:{
      colors: ['#89CCC5', '#96889D', '#E0BBBC'],
      title: 'Outcome'
    },
    priority:{
      colors: ['#3DBC51', '#EF8100', '#DC484A'],
      title: 'Priority'
    },
  }
};