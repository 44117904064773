import React, { useState } from "react";
import { Container, Typography, Avatar } from "@mui/material";
import { styles } from "./styles/infoContainerMD";
import { useTheme } from "@mui/material/styles";
import EditableContent from "./components/editableContent";
import IncidentsCard from "./incidentsCard";
import StudentDetailsCard from "../incidents/components/studentDetailsCard";

/**
 * This is the default Info Container Component.
 * config - This is the config data which is a list of dictionaries with the following properties: field,heading,editable
 * infoData - This is the json data of the information. Expects one json dictionary with key value pairs.
 * isIncident- true if incident page, false otherwise. Will add Message student button if true
 */

const InfoContainerMD = ({
  config,
  infoData,
  incidentData,
  handleOpen,
  updating,
  setUpdating,
  type,
}) => {
  const [data] = useState(() => infoData);
  const [leftConfig] = useState(() =>
    config.filter((item) => item.column === 1)
  );
  const [rightConfig] = useState(() =>
    config.filter((item) => item.column === 2)
  );

  const theme = useTheme();
  const style = styles(theme);

  const processField = (field, editable, validationType) => {
    if (editable) {
      const editableData = {
        field: field,
        type: "text",
      };
      return (
        <EditableContent
          action={editableData}
          data={data}
          type={type}
          updating={updating}
          setUpdating={setUpdating}
          validationType={validationType}
        />
      );
    } else {
      let fieldData = "N/A";
      if (field.includes("{") && field.includes("}")) {
        fieldData = field;
        const fieldVariables = field.match(/{(.*?)}/g);
        fieldVariables.forEach((variable) => {
          const variableName = variable.substring(1, variable.length - 1);
          fieldData = fieldData.replace(variable, data[variableName]);
        });
      }
      else if (data[field] && !Array.isArray(data[field])) {
        fieldData = data[field];
      } else if (Array.isArray(data[field]) && data[field].length > 0) {
        fieldData = data[field].join(", ");
      }
      return (
        <Typography sx={style.infoContainerCellText}>{fieldData}</Typography>
      );
    }
  };

  const processRow = (heading, field, editable, type, buttonConfig, index) => {
    if (type === "button") {
      return (
        <Container sx={style.infoContainerCellContainer} key={index}>
          <Container
            sx={style.infoContainerCellContainerButton}
            onClick={() => handleOpen("max")}
          >
            <Typography
              sx={style.infoContainerCellContainerButtonTitle}
            >
              {buttonConfig.title}
            </Typography>
            {buttonConfig.icon && (
              <img src={buttonConfig.icon} alt="icon" />
            )}
          </Container>
        </Container>
      );  
    } else if (type === "image"){
      return(
        <Avatar sx={style.image} src={`${process.env.REACT_APP_S3_BUCKET_URL}${data[field]}`} variant="rounded" key={index}/>
      )
    } else {
      return (
        <Container sx={style.infoContainerCellContainer} key={index}>
          <Typography
            variant="h5"
            style={style.infoContainerCellHeading}
          >
            {heading}
          </Typography>
          {processField(field, editable, type)}
        </Container>
      );
    }
  };

  return (
    <Container sx={style.infoContainerMain}>
      <Container sx={style.infoContainerColumnWrapper}>
        <Container sx={style.infoContainerColumnContainer}>
          {leftConfig.map(
            ({ heading, field, editable, type, buttonConfig }, index) => {
              return processRow(heading, field, editable, type, buttonConfig, index)
            }
          )}
        </Container>
        <Container sx={style.infoContainerColumnContainer}>
          {rightConfig.map(
            ({ heading, field, editable, type, buttonConfig }, index) => {
              return processRow(heading, field, editable, type, buttonConfig, index)
            }
          )}
        </Container>
      </Container>
      {/* {incidentData && <IncidentsCard data={data} />} */}
    </Container>
  );
};
export default InfoContainerMD;
