import { useContext, useState, useEffect } from 'react';
import { Container, Typography } from '@mui/material';
import Chart from 'react-apexcharts';
import GlobalAppContext from '../../context';
import { styles } from '../styles/incidentsOverTimeStyles';

import { useTheme } from '@mui/material/styles';

const IncidentsOverTime = ({config, data}) => {
  const [chartOptions, setChartOptions] = useState(null);

  const theme = useTheme();
  const { namespace  } = useContext(GlobalAppContext);
  const style = styles(theme);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      const categories = data.incidentsOverTime.labels.map((label) => {
        return new Date(label).toLocaleDateString();
      });
      let tempOptions = chartOptions
      if (!chartOptions) {
        tempOptions = config;
      }
      tempOptions.series[0].data = data.incidentsOverTime.data;
      tempOptions.xaxis.categories = categories;
      tempOptions.xaxis.tickAmount = categories.length > 10 ? categories.length/5 : 10;
      const maxyValue = Math.max(...tempOptions.series[0].data)
      tempOptions.yaxis.tickAmount = maxyValue > 15 ? 15 : maxyValue + 1;
      setChartOptions({...tempOptions});
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <Container sx={style.mainContainer}>
      <Container sx={style.heading}>
        <Typography variant='body'>
          {namespace.dashboard.incidentsOverTime}
        </Typography>
      </Container>
      <Container sx={style.graphContainer}>
        { chartOptions &&
          <Chart options={chartOptions} series={chartOptions.series} type={chartOptions.chart.type} width='100%' height={350} />
        }
      </Container>
    </Container>
  )
}

export default IncidentsOverTime;