import { localGet } from "./localStorageUtils";

export const getPriorityOptions = async () => {
  const incidentConfig = await localGet("incidentConfig", true);
  const priorityOptions = [];
  for (let priority in incidentConfig.priority) {
    priorityOptions.push({
      id: incidentConfig.priority[priority],
      label: incidentConfig.priority[priority],
    });
  }
  return priorityOptions;
};

export const getUrgencyOptions = async () => {
  const incidentConfig = await localGet("incidentConfig", true);
  const urgencyOptions = [];
  for (let urgency in incidentConfig.urgency) {
    urgencyOptions.push({
      id: incidentConfig.urgency[urgency],
      label: incidentConfig.urgency[urgency],
    });
  }
  return urgencyOptions;
};

export const getTypeOptions = async () => {
  const incidentConfig = await localGet("incidentConfig", true);
  const typeOptions = [];
  for (let type in incidentConfig.type) {
    typeOptions.push({
      id: incidentConfig.type[type],
      label: incidentConfig.type[type],
    });
  }
  return typeOptions;
};

export const getStatusOptions = async () => {
  const incidentConfig = await localGet("incidentConfig", true);
  const statusOptions = [];
  for (let status in incidentConfig.status) {
    statusOptions.push({
      id: incidentConfig.status[status],
      label: incidentConfig.status[status],
      hidden: incidentConfig.status[status] === "Waiting" ? true : false,
    });
  }
  return statusOptions;
};

export const getCategoryOptions = async () => {
  const incidentConfig = await localGet("incidentConfig", true);
  const categoryOptions = [];
  for (let category in incidentConfig.category) {
    categoryOptions.push({
      id: incidentConfig.category[category],
      label: incidentConfig.category[category],
    });
  }
  return categoryOptions;
};

export const getPerpetratorOptions = async () => {
  const incidentConfig = await localGet("incidentConfig", true);
  const perpetratorOptions = [];
  for (let perpetrator in incidentConfig.perpetrator) {
    perpetratorOptions.push({
      id: incidentConfig.perpetrator[perpetrator],
      label: incidentConfig.perpetrator[perpetrator],
    });
  }
  return perpetratorOptions;
};
