export const styles = (theme) => {
  const width = (width) => ({ width });

  return {
    activityContainer: {
      margin: "0px",
      padding: "0px !important",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
    },
    activityCard: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "space-between",
      margin: "0px",
      padding: "20px 23px 20px 23px !important",
      width: "23%",
      height: "95px",
      borderRadius: "4px",
      border: `1px solid ${theme.palette.custom.borderColor}`,
      backgroundColor: theme.palette.custom.white,
    },
    row1: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    row2: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    title: {
      color: `${theme.palette.custom.gray5}`,
      fontSize: "14px",
      lineHeight: "22px",
    },
    chevronRight: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    dataContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    data: {
      color: `${theme.palette.custom.black}`,
      fontSize: "20px",
      lineHeight: "22px",
      marginLeft: "10px",
    },
    activityCardMessage: {
      color: `${theme.palette.custom.red}`,
      fontSize: "12px",
      textDecoration: "underline",
      lineHeight: "12px",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      marginTop: "5px",
    }
  };
};
