import React from "react";
import HelpAccordion from "../components/helpAccordion";
import HelpData from "../configs/helpData.js";

const HelpInfo = () => {
  const data = HelpData; // Assuming HelpData is an array of objects

  return (
    <div>
      {data.map((item) => (
        <HelpAccordion
          key={item.id}
          summary={item.summary}
          contentHeader={item.contentHeader}
          contentBody={item.contentBody}
        />
      ))}
    </div>
  );
};

export default HelpInfo;
