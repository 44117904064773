import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Typography, Chip, CircularProgress } from "@mui/material";

import DetailViewHeaderTitle from "./detailViewHeaderTitle";
import DetailViewHeaderActions from "./detailViewHeaderActions";
import { styles } from "../../styles/detailsView/detailViewHeader";
import { useTheme } from "@mui/material/styles";

import HeaderBreadCrumbs from "../headerBreadCrumbs";

import { api } from "../../../../api";

const DetailViewHeader = ({
  data,
  titleConfig,
  type,
  title,
  actionItems,
  headerConfig,
  updating,
  setUpdating,
  handleModalOpen,
}) => {
  const navigate = useNavigate();
  const [rightButtonUpdating, setRightButtonUpdating] = useState(false);
  const [rightButtonConfig, setRightButtonConfig] = useState({
    backgroundColor:
      headerConfig?.rightButton?.backgroundColor[
        data[headerConfig.rightButton.field]
      ],
    title:
      headerConfig?.rightButton?.title[data[headerConfig.rightButton.field]],
    iconAfter:
      headerConfig?.rightButton?.iconAfter[
        data[headerConfig.rightButton.field]
      ],
  });

  const theme = useTheme();
  const style = styles(theme);

  useEffect(() => {
    setRightButtonConfig({
      backgroundColor:
        headerConfig?.rightButton?.backgroundColor[
          data[headerConfig.rightButton.field]
        ],
      title:
        headerConfig?.rightButton?.title[data[headerConfig.rightButton.field]],
      iconAfter:
        headerConfig?.rightButton?.iconAfter[
          data[headerConfig.rightButton.field]
        ],
    });
    // eslint-disable-next-line
  }, [data]);

  const typeDisplay =
    type.charAt(0).toUpperCase() + type.substring(1, type.length - 1);

  const handleRightButtonClick = async (value) => {
    if (!updating) {
      setRightButtonUpdating(true);
      setUpdating(true);
      const tempData = { ...data };
      tempData[headerConfig.rightButton.field] =
        headerConfig.rightButton.value[value];
      const results = await api("patch", tempData, type);
      if (results.status === 200) {
        setRightButtonUpdating(false);
      } else {
        tempData[headerConfig.rightButton.field] =
          data[headerConfig.rightButton.field];
        setRightButtonUpdating(false);
      }
      navigate(".", { replace: true, state: tempData });
      setUpdating(false);
    }
  };

  return (
    <Container sx={style.detailViewHeaderContainer}>
      {titleConfig?.breadcrumbs ? (
        <HeaderBreadCrumbs
          first={typeDisplay + "s"}
          last={typeDisplay + " Information"}
        />
      ) : (
        titleConfig?.breadcrumbsReplacment && (
          <Typography sx={style.breadcrumbReplacement}>
            {titleConfig.breadcrumbsReplacment}
          </Typography>
        )
      )}
      <DetailViewHeaderTitle
        data={data}
        titleConfig={titleConfig}
        type={type}
        title={title}
        updating={updating}
        setUpdating={setUpdating}
        handleModalOpen={handleModalOpen}
      />
      <Container sx={style.detailViewHeaderActionsContainer}>
        {actionItems?.length > 0 && (
          <DetailViewHeaderActions
            data={data}
            actionItems={actionItems}
            type={type}
            updating={updating}
            setUpdating={setUpdating}
          />
        )}
        <Container sx={style.detailViewHeaderRightButtonAfterContainer}>
          {rightButtonUpdating && (
            <CircularProgress
              sx={style.detailViewHeaderRightButtonAfterProgress}
            />
          )}
          {headerConfig?.rightButton && (
            <Chip
              sx={[
                style.detailViewHeaderRightButton(
                  rightButtonConfig.backgroundColor
                ),
              ]}
              onClick={() =>
                handleRightButtonClick(data[headerConfig.rightButton.field])
              }
              label={[
                rightButtonConfig.title,
                <rightButtonConfig.iconAfter
                  key={2}
                  sx={style.detailViewHeaderRightButtonAfterIcon}
                />,
              ]}
            />
          )}
        </Container>
      </Container>
    </Container>
  );
};

export default DetailViewHeader;
