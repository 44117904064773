import { getPermissionOptions } from "../../../utils/userConfig";
import { TrashCanIcon } from "../../core/customIcons";

export const getDetailViewConfig = async (data) => {
  let permissionOptions = await getPermissionOptions("disclosureAdmin");
  let permissionDisabled = false;
  if (data.permission === "disclosureAdmin") {
    permissionDisabled = true;
  } else {
    permissionOptions = await permissionOptions.filter((option) => {
      return option.value !== "disclosureAdmin";
    });
  }

  return {
    titleConfig: {
      breadcrumbs: false,
      field: "{firstName} {lastName}",
      mainAction: {
        type: "text",
        text: "Delete This Super Admin",
        field: "delete",
        textColor: "warning.main",
        textStyles: ["underline"],
        preIcon: <TrashCanIcon />,
        modalConfig: {
          delete: {
            title: "Delete Super Admin",
            type: "text",
            message:
              "Are you sure you want to delete {data.firstName} {data.lastName} Super Admin?",
            submitLabel: "Delete",
            cancelLabel: "Cancel",
            submitColor: "red",
            cancelColor: "blue",
            submitType: "delete",
          },
        },
      },
    },
    type: "users",
    title: "super admins",
    cards: [
      {
        column: 1,
        title: "Personal Information",
        bodyType: "form",
        fields: [
          {
            field: "firstName",
            headerName: "First Name",
            cellType: "text",
            required: true,
            type: "text",
            dataType: "string",
          },
          {
            field: "lastName",
            headerName: "Last Name",
            cellType: "text",
            required: true,
            type: "text",
            dataType: "string",
          },
          {
            field: "phone",
            headerName: "Phone",
            cellType: "text",
            required: true,
            type: "text",
            dataType: "phone",
          },
        ],
      },
      {
        column: 2,
        title: "Account",
        bodyType: "form",
        fields: [
          {
            field: "email",
            headerName: "Email",
            cellType: "text",
            disabled: true,
            required: true,
            type: "text",
            dataType: "email",
          },
          {
            field: "permission",
            headerName: "Role",
            disabled: permissionDisabled,
            required: true,
            type: "select",
            dataType: "string",
            options: permissionOptions,
          }
        ],
      },
    ],
  };
};
