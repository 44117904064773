import { getPermissionOptions } from "../../../utils/userConfig";

export const getCardConfig = async (data) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const permissionsOptions = await getPermissionOptions(user.permission);
  return [
    {
      column: 1,
      title: "Personal Information",
      bodyType: "form",
      fields: [
        {
          field: "firstName",
          headerName: "First Name",
          cellType: "text",
          required: true,
          type: "text",
          dataType: "string",
        },
        {
          field: "lastName",
          headerName: "Last Name",
          cellType: "text",
          required: true,
          type: "text",
          dataType: "string",
        },
        {
          field: "phone",
          headerName: "Phone",
          cellType: "text",
          required: true,
          type: "text",
          dataType: "phone",
        },
      ],
    },
    {
      column: 2,
      title: "Account",
      bodyType: "form",
      fields: [
        {
          field: "email",
          headerName: "Email",
          cellType: "text",
          disabled: true,
          required: true,
          type: "text",
          dataType: "email",
        },
        {
          field: "permission",
          headerName: "Role",
          disabled: true,
          required: true,
          type: "select",
          dataType: "string",
          options: permissionsOptions,
        },
        {
          field: "districtName",
          headerName: "District",
          cellType: "text",
          disabled: true,
          required: true,
          type: "text",
          dataType: "string",
          requires: {
            permission: {
              requiresValues: ["districtAdmin", "schoolAdmin", "responder"],
            },
          },
        },
        {
          field: "school",
          labelField: "schoolName",
          showPermissions: ["disclosureAdmin", "districtAdmin"],
          headerName: "School",
          cellType: "text",
          required: true,
          type: "apiSelect",
          dataType: "string",
          apiConfig: {
            apiBody: {filters: [{ key: "district", value: data.district, expression: "=" }]},
            dataType: "schools",
            label: "",
          },
          requires: {
            permission: {
              requiresValues: ["schoolAdmin", "responder"],
            }
          },
        },
        {
          field: "school",
          labelField: "SchoolName",
          showPermissions: ["schoolAdmin", "responder"],
          headerName: "School",
          cellType: "text",
          disabled: true,
          required: true,
          type: "select",
          options: [{label: data.schoolName, value: data.school}],
          dataType: "string",
          requires: {
            permission: {
              requiresValues: ["schoolAdmin", "responder"],
            },
          },
        }
      ],
    },
    {
      column: 1,
      title: "Personal Time Off",
      bodyType: "table",
      apiType: "paidTimeOffs",
      apiBody: {
        userId: data.id,
      },
      requires: {
        permission: {
          requiresValues: ["responder"],
        }
      },
      columns: [
        {
          name: "Start Date",
        },
        {
          name: "End Date",
        },
        {
          name: "Count",
        },
      ],
    },
  ];
};
