import {
  getTypeOptions,
  getStatusOptions,
  getPriorityOptions,
} from "../../../utils/incidentConfig";

import IncidentInfoTab from "../components/incidentInfoTab";
import ActivityTab from "../components/activityTab";
import OutcomeTab from "../components/outcomeTab";

import { EscalatedIcon, DeEscalateIcon } from "../../core/customIcons";

export const getDetailViewConfig = async (data) => {
  const typeOptions = await getTypeOptions();
  const statusOptions = await getStatusOptions();
  const priorityOptions = await getPriorityOptions();
  return {
    titleConfig: {
      breadcrumbs: true,
      field: "number",
      preString: "#",
      subTitleConfig: {
        field: "objectDateCreated",
        preString: "Created on ",
        type: "date",
      },
      mainAction: {
        field: "status",
        title: "Status",
        type: "dropdown",
        options: statusOptions,
        optionsConfig: {
          type: "chip",
        },
        backgroundColor: {
          New: "green",
          Open: "gray",
          Investigate: "blue",
          Waiting: "pink",
          Closed: "darkGray",
          Blocked: "yellow",
        },
      },
    },
    actionItems: [
      {
        title: "Incident Type",
        field: "type",
        labelField: "type",
        label: "",
        type: "select",
        editable: true,
        defaultToEditing: true,
        multiple: true,
        options: typeOptions,

      },
      {
        title: "Emergency",
        field: "emergency",
        labelField: "emergency",
        label: "",
        type: "select",
        editable: true,
        defaultToEditing: true,
        options: [
          { id: "True", label: "Yes" },
          { id: "False", label: "No" },
        ],
      },
      {
        title: "Priority",
        field: "priority",
        labelField: "priority",
        label: "",
        type: "select",
        editable: true,
        defaultToEditing: true,
        options: priorityOptions,
      },
      {
        hidden: ["responder"],
        title: "Assigned To",
        field: "assignedUsers",
        multiple: true,
        labelField: "assignedUsersNames",
        type: "apiSelect",
        apiConfig: {
          apiBody: {filters: [{key: "permission", value: "responder", expression: "="}, {key: "school", value: data.school, expression: "="}]},
          label: "",
          dataType: "user",
          addedOptions: [{ id: "unassigned", label: "Unassigned" }],
        },
        editable: true,
        defaultToEditing: true,
      },
    ],
    headerConfig: {
      rightButton: {
        title: { False: "Escalate This Case", True: "De-Escalate This Case" },
        field: "escalated",
        value: { True: "False", False: "True" },
        iconAfter: { True: DeEscalateIcon, False: EscalatedIcon },
        backgroundColor: { True: "darkYellow", False: "yellow" },
      },
    },
    type: "incidents",
    title: "Incidents",
    tabs: [
      { title: "Incident Information", component: IncidentInfoTab },
      { title: "Activity", component: ActivityTab },
      { title: "Investigation Outcome", component: OutcomeTab },
    ],
    actions: {
      edit: false,
      delete: false,
    },
  };
};
