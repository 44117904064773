import React, { useEffect, useState } from "react";
import { Typography, Button, Box, Modal, Divider } from "@mui/material";
import { CloseIcon } from "../../customIcons";
import ModalBody from "./modalBody";
import { useTheme } from "@mui/material/styles";
import { modalStyles } from "./modalStyles";
import { handleValidations } from "../../../../utils/dataUtils";

const AddModal = (props) => {
  const userPermission = JSON.parse(localStorage.getItem("user")).permission;
  const {
    open,
    submit,
    handleClose,
    title,
    handleSubmit,
    columns,
    addSuccessMessage,
    values,
    setValues,
  } = props;
  const theme = useTheme();
  const style = modalStyles(theme);
  const [validations, setValidations] = useState({});
  const [adding, setAdding] = useState(false);

  const initValidations = async () => {
    const tempValidations = {};
    columns.forEach((column) => {
      if (column.showAdd) {
        let passRequires = true;
        if (column.requires) {
          const requires = column.requires;
          for (const key in requires) {
            if (requires[key].requiresValues) {
              if (!requires[key].requiresValues.includes(values[key])) {
                passRequires = false;
              }
            }
          }
        }
        if (
          column.required &&
          !handleValidations(column, values[column.field]) &&
          (!column.showPermissions ||
            column.showPermissions.includes(userPermission)) &&
          passRequires
        ) {
          tempValidations[column.field] = false;
        } else {
          tempValidations[column.field] = true;
        }
      }
    });
    setValidations({ ...tempValidations });
  };

  useEffect(() => {
    if (Object.keys(validations).length === 0) {
      initValidations();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    initValidations();
    // eslint-disable-next-line
  }, [open, values]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style.modalContainer}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={style.modalHeader}
        >
          {submit ? `${title} Added Successfully` : `Add a New ${title}`}
          <CloseIcon sx={style.closeIcon} onClick={handleClose} />
        </Typography>
        <Divider sx={style.modalHeaderDivider} />
        <ModalBody
          type={title}
          submit={submit}
          columns={columns}
          values={values}
          setValues={setValues}
          addSuccessMessage={addSuccessMessage}
          validations={validations}
          setValidations={setValidations}
        />
        <Divider sx={style.modalFooterDivider} />
        <Box sx={style.footerConatiner}>
          {submit ? (
            <Button
              sx={style.closeButton}
              size="medium"
              variant="contained"
              onClick={handleClose}
            >
              Close
            </Button>
          ) : (
            <>
              <Button
                sx={style.cancelButton}
                size="medium"
                variant="text"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                sx={style.addButton}
                size="medium"
                variant="contained"
                disabled={Object.values(validations).includes(false) || adding}
                onClick={(e) => {
                  handleSubmit(values, setAdding);
                }}
              >
                {adding ? "Adding..." : "Add"}
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default AddModal;
