import "./App.scss";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import publicRoutes from "./routes/publicRoutes";
import privateRoutes from "./routes/privateRoutes";
import GlobalAppContext from "./feature/context";
import locales from "./localization/supported-locales/mui-localizations";
import CssBaseline from "@mui/material/CssBaseline";
import lightTheme from "./theme/default-light-theme";
import darkTheme from "./theme/default-dark-theme";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { useMemo, useState, useEffect } from "react";

/***
 * This component is the main application component responsible for routing within the application.
 * For Routes:
 * NB: Please place routes in alphabetical order according to path.
 * NB: Numbers precede letters.
 */
const App = () => {
  const [sideNavigationOpen, setSideNavigationOpen] = useState(true);
  // const [theme, setTheme] = useState(
  //   localStorage.getItem("DisclosureTheme") ?? "lightTheme"
  // );

  const [themeName, setThemeName] = useState("light");
  const [thisTheme, setThisTheme] = useState(lightTheme);

  useEffect(() => {
    setThisTheme(themeName === "light" ? lightTheme : darkTheme);
  }, [themeName]);

  const [locale, setLocale] = useState(
    localStorage.getItem("DisclosureLocale") ?? "enUS"
  );

  const G_CONTEXT = useMemo(
    () => ({
      sideNavigationOpen: sideNavigationOpen,
      setSideNavigationOpen: setSideNavigationOpen,
      themeName: thisTheme,
      setTheme: setThisTheme,
      locale: locale,
      setLocale: setLocale,
      namespace: locales[locale.replace("\n", "")],
    }),
    [sideNavigationOpen, locale, thisTheme]
  );

  useMemo(() => {
    if (themeName) {
      localStorage.setItem("DisclosureTheme", themeName);
    }
  }, [themeName]);

  useMemo(() => {
    if (locale) {
      localStorage.setItem("DisclosureLocale", locale);
    }
  }, [locale]);

  const themeWithLocale = useMemo(() => {
    if (locale && themeName) {
      return createTheme(thisTheme, locales[locale]);
    }
    return createTheme(thisTheme, locales["enUS"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, themeName]);

  const router = createBrowserRouter([
    ...privateRoutes(setThemeName),
    ...publicRoutes,
  ]);

  return (
    <GlobalAppContext.Provider value={G_CONTEXT}>
      <ThemeProvider theme={themeWithLocale}>
        <CssBaseline />
        <RouterProvider
          router={router}
          setThemeName={setThemeName}
          themeName={themeName}
        />
      </ThemeProvider>
    </GlobalAppContext.Provider>
  );
};

export default App;
