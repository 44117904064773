import React, { useEffect, useState } from "react";
import {
  Container,
  Modal,
  Typography,
  TextField,
  CircularProgress,
} from "@mui/material";

import { styles } from "./styles/modalMD";
import { useTheme } from "@mui/material/styles";
import { CloseIcon } from "./customIcons";

const ModalMD = ({
  title,
  type,
  label,
  cancelLabel,
  submitLabel,
  submittingLabel,
  open,
  submitFunction,
  message,
  data,
  setModalOpen,
}) => {
  const [thisOpen, setThisOpen] = useState(false);
  const [value, setValue] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const theme = useTheme();
  const style = styles(theme);

  useEffect(() => {
    setSubmitting(false);
    if (open === title) {
      setThisOpen(true);
      setValue("");
    } else {
      setThisOpen(false);
    }
    // eslint-disable-next-line
  }, [open]);

  const handleModalClose = () => {
    setThisOpen(false);
    setModalOpen(false);
  };

  const processMessage = (message) => {
    return message.replace(/{data.(.*?)}/g, (match, field) => {
      return data[field];
    });
  };

  const handleModalSubmit = async (value) => {
    setSubmitting(true);
    submitFunction(value);
  };

  return (
    <Modal open={thisOpen} onClose={null}>
      <Container sx={style.detailViewContainerModal}>
        <Container sx={style.detailViewContainerModalHeader}>
          <Typography sx={style.detailViewContainerModalHeaderTitle}>
            {title}
          </Typography>
          <CloseIcon
            sx={style.detailViewContainerModalHeaderClose}
            onClick={() => handleModalClose()}
          />
        </Container>
        <Container sx={style.detailViewContainerModalBody}>
          <Typography sx={style.detailViewContainerModalBodyLabel}>
            {label}
          </Typography>
          {type === "textArea" && (
            <TextField
              multiline
              value={value}
              sx={style.detailViewContainerModalBodyTextArea}
              onChange={(e) => setValue(e.target.value)}
            />
          )}
          {type === "text" && (
            <Typography>{processMessage(message)}</Typography>
          )}
        </Container>
        <Container sx={style.detailViewContainerModalFooter}>
          {cancelLabel && (
            <Container
              sx={style.detailViewContainerModalFooterCancelButton}
              onClick={() => handleModalClose()}
            >
              {cancelLabel}
            </Container>
          )}
          <Container
            sx={style.detailViewContainerModalFooterSubmitButton}
            onClick={() => handleModalSubmit(value)}
          >
            {submitting && submittingLabel ? submittingLabel : submitLabel}
            {submitting && (
              <CircularProgress
                sx={style.detailViewContainerModalFooterSubmitProgress}
              />
            )}
          </Container>
        </Container>
      </Container>
    </Modal>
  );
};

export default ModalMD;
