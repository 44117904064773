import React, { useEffect, useState } from "react";
import {
  Container,
  TextField,
  Typography,
  Chip,
  CircularProgress,
} from "@mui/material";

import { styles } from "../styles/activityTab";
import { useTheme } from "@mui/material/styles";
import { api } from "../../../api";
import { localGet } from "../../../utils/localStorageUtils";
import SendIcon from "@mui/icons-material/Send";
import { getActivityData } from "../../../utils/dataUtils";
import { Graylogo } from "../../core/customIcons";
import { LoadingIcon } from "../../core/loadingIcon";

const ActivityTab = ({ data }) => {
  const [activityData, setActivityData] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [loading, setLoading] = useState(true);
  const [updatingComment, setUpdatingComment] = useState(false);

  const theme = useTheme();
  const style = styles(theme);

  const fetchData = async () => {
    const results = await api("get", { id: data.id }, "incidentActivity");
    const tempActivityData = [...results.data];
    for (let activity in tempActivityData) {
      const additionalData = getActivityData(tempActivityData[activity], theme);
      if (additionalData) {
        tempActivityData[activity].message = additionalData.message;
        tempActivityData[activity].initials = additionalData.initials;
        tempActivityData[activity].body = additionalData.body;
        tempActivityData[activity].messageIcon = additionalData.messageIcon;
        tempActivityData[activity].time = additionalData.time;
      } else {
        delete tempActivityData[activity];
      }
    }
    setActivityData(tempActivityData);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const handleComment = async () => {
    setUpdatingComment(true);
    const userData = await localGet("user", true);
    const userFullName = `${userData.firstName} ${userData.lastName}`;
    const commentData = {
      comment: newComment,
      incident: data.id,
      type: "General",
      portalOnly: "True",
    };
    const createComment = await api("post", commentData, "comments");
    if (createComment.status === 200) {
      setNewComment("");
      const tempActivityData = activityData;
      const tempData = {
        updatedBy: userFullName,
        comment: newComment,
        timestamp: new Date(Date.now()).toISOString(),
        change: "responderCommentResponder",
      };
      const additionalData = getActivityData(tempData, theme);
      if (additionalData) {
        tempData.message = additionalData.message;
        tempData.initials = additionalData.initials;
        tempData.body = additionalData.body;
        tempData.messageIcon = additionalData.messageIcon;
        tempData.time = additionalData.time;
      }
      tempActivityData.push(tempData);
      tempActivityData.sort(
        (a, b) =>
          new Date(b.timestamp.replace("Z", "")) -
          new Date(a.timestamp.replace("Z", ""))
      );
      setActivityData([...tempActivityData]);
    }
    setUpdatingComment(false);
  };

  return (
    <Container sx={style.detailViewTabContainer}>
      <TextField
        multiline
        rows={3}
        sx={style.responderCommentTextArea}
        placeholder="Add a comment for other responders"
        value={newComment}
        onChange={(e) => setNewComment(e.target.value)}
        InputProps={{
          endAdornment: (
            <Container
              position="end"
              onClick={() => handleComment()}
              sx={style.responderCommentTextAreaButton}
            >
              {updatingComment ? (
                <CircularProgress
                  sx={style.responderCommentTextAreaButtonProgress}
                />
              ) : (
                <SendIcon sx={style.responderCommentTextAreaButtonIcon} />
              )}
            </Container>
          ),
        }}
      />
      {loading ? (
        <LoadingIcon page={"activity"} />
      ) : (
        <Container sx={style.activityEntriesWrapper}>
          {activityData.map((entry, index) => (
            <Container
              key={index}
              sx={[
                style.activityEntryWrapper,
                index === activityData.length - 1 && style.lastEntry,
              ]}
            >
              <Container sx={style.blackDot}></Container>
              <Container sx={style.activityEntryHeader}>
                <Container sx={style.activityEntryHeaderLeft}>
                  {entry?.updatedBy === "Student" ? (
                    <Chip
                      label={<Graylogo sx={style.graylogo} />}
                      sx={[
                        style.activityEntryHeaderLeftChip,
                        style.activityEntryHeaderLeftChipStudent,
                      ]}
                    ></Chip>
                  ) : (
                    <Chip
                      sx={style.activityEntryHeaderLeftChip}
                      label={entry?.initials}
                    />
                  )}
                  <Typography sx={style.activityEntryHeaderUserName}>
                    {entry?.updatedBy === "Student"
                      ? "The Student"
                      : entry?.updatedBy}
                  </Typography>
                  {entry?.message}
                </Container>
                <Typography sx={style.activityEntryHeaderTime}>
                  {entry?.time}
                </Typography>
              </Container>
              {entry?.body && (
                <Container sx={style.activityEntryBody}>
                  {entry[entry?.body]}
                </Container>
              )}
            </Container>
          ))}
        </Container>
      )}
    </Container>
  );
};

export default ActivityTab;
