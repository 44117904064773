
/**
 * This is the default columns for Districts
 * field - This is the property on the object that wil be displayed within this column
 * headerName - This is the header that will be displayed for the column
 * hideableable: This is a custom field that will determine whether a column will be able to be added to the grid or not (visibility)
 */
export const getDistrictSchoolsColumns = async (data) => {
  return [
    {
      field: "name",
      headerName: "School Name",
      cellType: "text",
      required: true,
      type: "text",
      dataType: "string",
      showColumn: true,
      showAdd: true,
    },
    {
      field: 'district',
      headerName: 'District',
      cellType: 'text',
      showColumn: false,
      showAdd: true,
      addDefaultValue: data.id,
      disabled: true,
      required: true,
      type: 'select',
      options: [{value: data.id, label: data.name}],
      dataType: 'string',
    },
    {
      field: "districtName",
      headerName: "District",
      cellType: "text",
      showColumn: true,
      showAdd: false,
    },
    {
      field: "activeIncidents",
      headerName: "Active Incidents",
      cellType: "text",
      showColumn: true,
      showAdd: false,
    },
    {
      field: "closedIncidents",
      headerName: "Closed Incidents",
      cellType: "text",
      showColumn: true,
      showAdd: false,
    },
    {
      field: "escalated",
      headerName: "Escalated",
      cellType: "text",
      showColumn: true,
      showAdd: false,
    },
    {
      field: "emergencies",
      headerName: "Emergencies",
      cellType: "text",
      showColumn: true,
      showAdd: false,
    },
    {
      field: "students",
      headerName: "Students",
      cellType: "text",
      showColumn: true,
      showAdd: false,
    },
    {
      field: "principal",
      headerName: "Principal",
      cellType: "text",
      required: true,
      type: "text",
      dataType: "string",
      showColumn: false,
      showAdd: true,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      cellType: "text",
      required: true,
      type: "text",
      dataType: "phone",
      showColumn: false,
      showAdd: true,
    },
    {
      field: "email",
      headerName: "Email",
      cellType: "text",
      required: true,
      type: "text",
      dataType: "email",
      showColumn: false,
      showAdd: true,
    },
    {
      field: "address",
      headerName: "Address",
      cellType: "text",
      required: true,
      type: "text",
      dataType: "string",
      showColumn: false,
      showAdd: true,
    },
  ];
};
