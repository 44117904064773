import React, { useState } from "react";
import {
  Container,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import { styles } from "../../styles/dataTableMD/dataTableFilters";
import { useTheme } from "@mui/material/styles";

import { FilterIcon } from "../../customIcons";
import ApiSelect from "../apiSelect";

const DataTableFilters = ({ filters, processFilter }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const tempValues = {};
  for (let filter in filters) {
    if (filters[filter].field === "district" && user.permission !== "disclosureAdmin") {
      tempValues[filters[filter].field] = user.district;
    } else if (filters[filter].field === "school" && user.permission !== "disclosureAdmin" && user.permission !== "districtAdmin") {
      tempValues[filters[filter].field] = user.school;
    }else if (filters[filter].returnType === "list"){
      tempValues[filters[filter].field] = [];
    } else if (filters[filter].returnType === "string") {
      tempValues[filters[filter].field] = null;
    }
  }
  const [selectValues, setSelectValues] = useState(tempValues);

  const theme = useTheme();
  const style = styles(theme);

  const handleFilterChange = async (field, value, label) => {
    selectValues[field] = value;
    setSelectValues({ ...selectValues });
    await processFilter(field, value);
  };

  const renderInput = (params, filter) => {
    return (<TextField {...params}
        label={filter.title}
        key={params.id}
        InputLabelProps={{shrink: true,}}
        placeholder={`Select a ${filter.title}`}/>)
  };

  return (
    <Container sx={style.DataGridFiltersContainer}>
      <FilterIcon sx={style.DataGridFilterIcon} />
      <Typography variant="subtitle1">Filters:</Typography>

      {filters.map((filter, index) => (
        <Container sx={style.dataGridFilterwrapper} key={index}>
          {(!filter.permission || filter.permission.includes(user.permission)) && (
            <Container sx={style.dataGridFilterContainer}>
              {filter.type === "select" && (
                  <Autocomplete
                    multiple
                    disablePortal
                    getOptionLabel={(option) => option.label || "error"}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={filter.options}
                    value={selectValues[filter.field]}
                    onChange={(e, value) => handleFilterChange(filter.field, value.id, value.label)}
                    sx={style.autoCompleteSelect}
                    renderInput={(params) => renderInput(params, filter)}
                    fullWidth = {true}
                    size="small"
                />
              )}
              {filter.type === "apiSelect" && (
                <ApiSelect
                  filterValues={selectValues}
                  handleFilterChange={handleFilterChange}
                  config={filter}
                />
              )}
            </Container>
          )}
        </Container>
      ))}
    </Container>
  );
};

export default DataTableFilters;
