export const cardStyles = (theme) => {
  return {
    mdCard: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.common.white,
      margin: "20px",
      borderRadius: "4px",
      border: `1px solid ${theme.palette.custom.borderColor}`,
    },
    mdCardHeader: {
      padding: "15px !important",
      borderBottom: `1px solid ${theme.palette.custom.borderColor}`,
    },
    mdCardHeaderTitle: {
      marginLeft: "15px",
      fontSize: "18px",
      fontWeight: "400",
    },
    mdCardBody: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      width: "auto",
      margin: "30px",
      padding: "20px !important",
      border: `1px solid ${theme.palette.custom.borderColor}`,
      borderRadius: "4px",
    },
    mdCardBodyLabel: {
      fontSize: "18px",
      fontWeight: "400",
    },
    mdCardBodyButton: {
      marginTop: "20px",
      width: "100px",
      display: "flex",
      justifyContent: "center",
      padding: "10px",
      backgroundColor: `${theme.palette.primary.main}`,
      color: `${theme.palette.custom.white}`,
      "&:hover": {
        backgroundColor: `${theme.palette.primary.main}`,
      },
    },
  };
};
