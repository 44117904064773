import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { styles } from "../../styles/cards/user";
import { useTheme } from "@mui/material/styles";
import Card from "../cards/card";

const DetailInfoCardPage = ({
  data,
  cards,
  values,
  setValues,
  validations,
  setValidations,
  setEdited,
  checkListValues,
}) => {
  const [cardsLeft, setCardsLeft] = useState([]);
  const [cardsRight, setCardsRight] = useState([]);

  const theme = useTheme();
  const style = styles(theme);

  useEffect(
    () => {
      const tempCardsLeft = [];
      const tempCardsRight = [];
      cards.forEach((card) => {
        if (card.column === 1) {
          tempCardsLeft.push(card);
        } else {
          tempCardsRight.push(card);
        }
      });
      setCardsLeft([...tempCardsLeft]);
      setCardsRight([...tempCardsRight]);
    },
    // eslint-disable-next-line
    [cards]
  );

  return (
    <Container sx={style.userInfoCardContainer}>
      <Container sx={style.userInfoCardContainerLeft}>
        {cardsLeft.map((card, index) => (
          <Container key={index}>
            {(!card.requires || (card.requires.permission && card.requires.permission.requiresValues.includes(data.permission))) && (
              <Card
                key={card.title}
                title={card.title}
                fields={card.fields}
                columns={card.columns}
                apiType={card.apiType}
                apiBody={card.apiBody}
                data={data}
                values={values}
                setValues={setValues}
                validations={validations}
                setValidations={setValidations}
                bodyType={card.bodyType}
                footer={false}
                setEdited={setEdited}
                checkListValues={checkListValues ? checkListValues[card.title] : {}}
              />
            )}
          </Container>
        ))}
      </Container>
      <Container sx={style.userInfoCardContainerRight}>
        {cardsRight.map((card, index) => (
          <Container key={index}>
            {(!card.requires || (card.requires.permission && card.requires.permission.requiresValues.includes(data.permission))) && (
              <Card
                key={card.title}
                title={card.title}
                fields={card.fields}
                data={data}
                values={values}
                setValues={setValues}
                validations={validations}
                setValidations={setValidations}
                bodyType={card.bodyType}
                footer={false}
                setEdited={setEdited}
                checkListValues={checkListValues ? checkListValues[card.title] : {}}
              />
            )}
          </Container>
        ))}
      </Container>
    </Container>
  );
};

export default DetailInfoCardPage;
