import React, { useEffect, useState}  from 'react';
import DataTableMD from '../core/dataTableMD';
import { useLocation } from 'react-router-dom';
import { incidentsColumns, getIncidentsFilters, incidentsToggles } from './configs/incidentsDataTableConfig';

/*
 * This generates the Incidents Page for the application
 * @param {*} props These are the properties required for the Incidents page (user, etc)
 * @returns This returns a generated Incidents page for the user
 */


const IncidentsPage = (props) => {
    const { state } = useLocation();
    const [filters, setFilters] = useState([])
    useEffect(() => {
        const getFilters = async () => {
            const filters = await getIncidentsFilters(state)
            setFilters(filters)
        }
        getFilters()
        // eslint-disable-next-line
    }, [])
    return (
        <DataTableMD
            title="Incidents"
            dbTable={"incidents"}
            filters = {filters}
            toggles = {incidentsToggles} // or null for none
            columns = {incidentsColumns}
            sort={{field: 'number', order: 'desc'}}
        />
    );
};

export default IncidentsPage;