import React from 'react';
import DataTableMD from '../core/dataTableMD';
import { districtsColumns } from './configs/districtsDataTableConfig';

/**
 * This generates the Districts Page for the application
 * @param {*} props These are the properties required for the Districts page (user, etc)
 * @returns This returns a generated Districts page for the user
 */


const DistrictsPage = () => {
    const addSuccessMessage = "The new district has been successfully added to the system. "
    return (
        <DataTableMD
            title="Districts"
            dbTable={"districts"}
            filters={null}
            columns={districtsColumns}
            addButton={true}
            addSuccessMessage={addSuccessMessage}
            sort={{field: 'name', order: 'asc'}}
        />
    );
};

export default DistrictsPage;