export const styles = (theme) => ({
  headerBreadCrumbs: {
    display: "flex",
    marginTop: "20px",
    marginBottom: "20px",
    marginLeft: "40px",
    width: "auto",
  },
  headerBreadCrumbsFirst: {
    color: `${theme.palette.custom.gray5} !important`,
    fontFamily: "Nunito Sans",
  },
  headerBreadCrumbsSecond: {
    fontFamily: "Nunito Sans",
    color: `${theme.palette.primary.main} !important`,
  },
});
