export const styles = (theme) => {
  return {
    mainContainer: {
      marginTop: "30px",
      padding: "20px !important",
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.custom.gray1,
    },
    heading: {
      fontSize: "24px",
      color: theme.palette.custom.darkBlue,
      marginRight: "10px",
      height: "32px",
      textWrap: 'nowrap',
      marginBottom: "10px",
    },
    bodyContainer: {
      display: "flex",
      flexDirection: "row",
      gap: "20px",
      marginTop: "20px",
    },
    incidentCard: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      borderRadius: "4px",
      background: theme.palette.custom.white,
      border: `1px solid ${theme.palette.custom.gray3}`,
      padding: "22px !important",
      width: "32%",
    },
    title: {
      fontSize: "16px",
      color: theme.palette.custom.darkBlue,
      fontWeight: 600,
      marginBottom: "30px",
    },
    breakDownContainer: {
      margin: "0px",
      padding: "0px !important",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      marginBottom: "10px",
    },
    subHeadingContainer: {
      margin: "0px !important",
      padding: "0px !important",
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      width: "150px",
      overflow: "hidden",
    },
    percentageContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
    },
    link: {
      color: theme.palette.primary.main,
      fontSize: "12px",
      lineHeight: "12px",
    },
    percentageWrapper: {
      padding: "0px !important",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      height: "32px",
      marginBottom: "10px",
    },
    subHeading: {
      fontSize: "12px",
      color: theme.palette.custom.gray6,
      fontWeight: 600,
      height: "32px",
      textWrap: 'nowrap',
      marginRight: "10px",
      marginBottom: "10px",
    },
    percentageBody: function (bgColor, percentage) {
      return {
        margin: "0px !important",
        padding: "0px !important",
        display: "flex",
        flexDirection: "row",
        background: bgColor,
        width: `${percentage}%`,
        height: "28px",
        borderRadius: "4px",
        marginRight: "10px",
      };
    },
    percentageNumber: {
      marginLeft: "10px",
      fontWeight: 600,
      fontSize: "14px",
      color: theme.palette.custom.gray6,
    },
  };
};
