export const styles = (theme) => {
  return {
    DataGridFiltersContainer: {
      display: "flex",
      height: "60px",
      flexDirection: "row",
      flexWrap: "wrap",
      margin: "20px 0px 0px 0px",
      backgroundColor: `${theme.palette.custom.white}`,
      alignItems: "center",
      minWidth: "100%",
      borderRadius: "4px 4px 0px 0px",
      border: `1px solid ${theme.palette.custom.borderColor}`,
      borderBottom: "none",
    },

    autoCompleteSelect: {
      width: "100%",
      minWidth: "220px",
      marginTop: "10px",
      marginBottom: "10px",
      "& .MuiFormLabel-root": {
          width: "300px !important",
        color: `${theme.palette.custom.black}`,
        top: "-12px",
        left: "12px",
      },
      "&.Mui-focused .MuiFormLabel-root": {
        color: `${theme.palette.custom.black}`,
      },
      "& .MuiAutoComplete-root": {
        color: `${theme.palette.custom.black}`,
      },
      "& .MuiOutlinedInput-notchedOutline": {
          color: `${theme.palette.custom.black}`,
          border: `1px solid ${theme.palette.custom.borderColor}`,
      },
    },

    DataGridFilterIcon: {
      width: "25px",
      height: "25x",
      marginRight: "15px",
      marginLeft: "15px",
    },

    DataGridFilterFormControl: {
      display: "flex",
      marginLeft: "20px",
    },
    
    dataGridFilterContainer: {
      display: "flex",
      flexDirection: "row",
      width: "auto",
      margin: "0px 20px 0px 20px",
    },
    dataGridFilterwrapper: {
      display: "flex",
      flexDirection: "row",
      width: "auto",
      margin: "0px",
    }
  };
};
