export const styles = (theme) => {
  return {
    mainContainer: {
      display: "flex",
      flexDirection: "column",
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      padding: "10px",
      marginBottom: "60px",
      width: "calc(100% - 80px)",
    },
    buttonCancel: {
      backgroundColor: `${theme.palette.custom.white}`,
      color: `${theme.palette.custom.red}`,
      marginRight: "10px",
      padding: "15px 20px",
      border: `1px solid ${theme.palette.custom.borderColor}`,
      "&:hover": {
        backgroundColor: `${theme.palette.custom.backgroundColor}`,
      },
    },
    buttonSave: {
      backgroundColor: `${theme.palette.secondary.main}`,
      padding: "15px 20px",
      color: `${theme.palette.custom.white}`,
      "&:hover": {
        backgroundColor: `${theme.palette.secondary.light}`,
      },
    },
    buttonProgress: {
      color: `${theme.palette.custom.white}`,
      marginLeft: "10px",
      width: "20px !important",
      height: "20px !important",
    },
  };
};
