export const styles = (theme) => {
  return {
    editableContentItemTextField: (string) => {
      let width = string?.length * 15;
      if (width < 100) {
        width = 100;
      }
      return {
        color: `${theme.palette.custom.black}`,
        fontWeight: "400",
        fontFamily: "Nunito Sans",
        width: width,
        padding: "0px !important",
        "& .MuiOutlinedInput-input": {
          color: `${theme.palette.custom.black}`,
          padding: "2.5px !important",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none !important",
        },
        "&.Mui-focused .MuiOutlinedInput-input": {
          padding: "0px !important",
        },
      };
    },

    editableContentItemText: {
      color: `${theme.palette.custom.black}`,
      fontWeight: "400",
      fontFamily: "Nunito Sans",
      alignItems: "center",
      display: "flex",
    },

    editableContentItemSelect: {
      "& .MuiOutlinedInput-input": {
        padding: "3px 30px 3px 0px !important",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none !important",
      },
      "&.Mui-focused .MuiOutlinedInput-input": {
        padding: "3px 30px 3px 0px !important",
      },
    },

    editableContentItemMenuItem: {},

    editableContentItemData: {
      padding: "6px 0px 5px 0px !important",
      marginRight: "30px",
      borderRadius: "6px",
      display: "flex",
      alignItems: "center",
    },

    editableContentItemDataIconShow: {
      marginRight: "0px",
    },

    editableContentItemDataEditing: {
      padding: "4px 0px 5px 0px !important",
      marginRight: "20px!important",
      border: `1px solid ${theme.palette.custom.borderColor}`,
      backgroundColor: `${theme.palette.custom.lightPink}`,
    },

    editableContentEditIcon: {
      marginRight: "0px",
      marginLeft: "6px",
      cursor: "pointer",
      padding: "0px !important",
    },

    editableContentItemTextProgress: {
      marginRight: "0px",
      marginLeft: "10.5px",
      color: "#0082E1",
      height: "20px !important",
      width: "20px !important",
    },
  };
};
