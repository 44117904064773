import React, { useState, useEffect } from 'react';
import { Container, Button, CircularProgress } from '@mui/material';
import DetailInfoCardPage from '../../core/components/detailsView/detailInfoCardPage';
import { getCardConfig } from '../configs/changePasswordConfigs';
import { useTheme } from '@mui/material/styles';
import { styles } from '../styles/myAccount.jsx';
import { api } from '../../../api';
import { handlePasswordValidation } from '../../../utils/dataUtils.js';

const MyAccountConfig = ({data, handleOpen, updating, setUpdating}) => {
    const [values, setValues] = useState({});
    const [validations, setValidations] = useState({});
    const [checkListValues, setCheckListValues] = useState({});
    const [edited, setEdited] = useState(false);
    const [cards, setCards] = useState([]);

    const theme = useTheme();
    const style = styles(theme);



    const getDefaultValues = () => {
        const initValues = {};
        for (let field in data){
            initValues[field] = data[field]
        }
        for (let card in cards){
            for (let field in cards[card].fields){
                if (!(cards[card].fields[field].field in initValues)){
                    initValues[cards[card].fields[field].field] = ""
                }
            }
        }
        return initValues
    }

    const getDefaultCheckListValues = () => {
        const initCheckListValues = {};
        for (let card in cards){
            if (cards[card].bodyType === "checkList"){
                initCheckListValues[cards[card].title] = {}
                for (let field in cards[card].fields){
                    initCheckListValues[cards[card].title][cards[card].fields[field].field] = false
                }
            }
        }
        return initCheckListValues
    }

    useEffect(() => {
        const fetchData = async () => {
            const cards = await getCardConfig(data);
            setCards(cards);
        };
        fetchData();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (data){
            setValues(getDefaultValues())
            setCheckListValues(getDefaultCheckListValues())
        }
        // eslint-disable-next-line
    }, [cards]);

    const setPasswordValidation = (value) => {
        checkListValues["Password Must Contain"] = value
        setCheckListValues({...checkListValues})
    }

    useEffect(() => {
        if (Object.keys(values).length > 0){
            handlePasswordValidation(values.newPassword, "new", checkListValues["Password Must Contain"], setPasswordValidation, values.newPassword)
            handlePasswordValidation(values.confirmPassword, "confirm", checkListValues["Password Must Contain"], setPasswordValidation, values.newPassword)
        }
        // eslint-disable-next-line
    }, [values]);




    const handleSaveClick = async() => {
        if (!updating){
            setUpdating(true);
            const authData = {
                password: values.currentPassword,
                newPassword: values.newPassword
            }
            const results = await api("auth", authData, "changePassword")
            if (results.status === 200){
                // TODO - add success message
            } else {
                 // TODO - add error message
            }
            setUpdating(false);
        }
    }

    const handleCancelClick = () => {
        const tempValues = getDefaultValues();
        setValues({...tempValues});
        setEdited(false);
    }

    const handleButtonDisabled = () => {
        for (let card in checkListValues){
            for (let field in checkListValues[card]){
                if (checkListValues[card][field] === false){
                    return true
                }
            }
        }
        if (values.currentPassword === ""){
            return true
        }
        return false
    }


    return (
        <Container sx={style.mainContainer}>
            {cards.length > 0 && 
                <DetailInfoCardPage
                    data={data}
                    cards={cards}
                    values={values}
                    setValues={setValues}
                    validations={validations}
                    setValidations={setValidations}
                    setEdited={setEdited}
                    checkListValues={checkListValues}
                />
            }
            {cards.length > 0 && edited &&
                <Container sx={style.buttonContainer}>
                    <Button sx={style.buttonCancel} onClick={handleCancelClick}>Cancel</Button>
                    <Button sx={style.buttonSave} onClick={handleSaveClick} disabled={handleButtonDisabled()} >
                        {updating ? "Updating" : "Update"}
                        {updating && <CircularProgress sx={style.buttonProgress}/>}
                    </Button>
                </Container>
            }
        </Container>
    );
};

export default MyAccountConfig;