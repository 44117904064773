import React from 'react';
import InfoContainerMD from '../../core/infoContainerMD';
import { districtsHeadings } from '../configs/districtsInfoConfig';


const DistrictInformationTab = ({data, handleOpen, updating, setUpdating}) => {
    return (
        <InfoContainerMD
            config={districtsHeadings}
            infoData={data}
            incidentData={true}
            handleOpen={handleOpen}
            updating={updating}
            setUpdating={setUpdating}
            type={"districts"}
        />
    );
};

export default DistrictInformationTab;