import { utilsApplicationStyle, utilsPrimaryColors } from '../../utils/utilsApplicationStyle';

/**
 * This is the styling for the Component Content
 * Values that are not pulled from the styles constant should never be changed and should remain static in this file.
 */
export const appContent = {
    margin: '0px',
    paddingRight: "0px",
    paddingLeft: "0px",
    height: utilsApplicationStyle.content.content.height,
    maxHeight: utilsApplicationStyle.content.content.height,
    maxWidth: utilsApplicationStyle.content.content.maxWidth,
    overflowY: 'scroll',
    backgroundColor: utilsPrimaryColors.light1,
}

export const contentContainer = {

};