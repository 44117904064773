export const screenContainer =  {
    height: '100vh',
    maxHeight: '100vh',
    display: 'flex',
    padding: '0px !important',
    margin: '0px',
    '& .MuiContainer-root': {
        maxWidth: '100vw',
        padding: '0px',
    }
};

export const screenContentRightClose = {
    padding: '0px !important',
    margin: '0px',
    minWidth: 'calc(100vw - 65px)',
    transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
}

export const screenContentRightOpen = {
    padding: '0px !important',
    margin: '0px',
    minWidth: 'calc(100vw - 240px)',
    transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
}

export const screenContentOpen = {
    '& .MuiAppBar-root': {
        margin: '0px',
        padding: '0px',
        width: 'calc(100vw - 240px)',
    },
}

export const screenContentClose = {
    '& .MuiAppBar-root': {
        margin: '0px',
        padding: '0px',
        width: 'calc(100vw - 59px)',
        height: '100%',
    },
}

export const screenMenuLeft = {
    margin: '0px',
    padding: '0px',
    height: '100%',
}

export const screenMenuLeftOpen = {
    width: '216px !important',
}

export const screenMenuLeftClose = {
    width: '35px !important',
}


export const screenContainerClose = {
    '& .MuiContainer-root': {
        margin: '0px',
        padding: '0px',
        width: 'calc(100vw - 59px)',
    },
}

export const screenContainerOpen = {
    '& .MuiContainer-root': {
        margin: '0px',
        padding: '0px',
        width: 'calc(100vw - 240px)',
    },
}