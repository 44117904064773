export const styles = (theme) => {
  return {
    DataGridHeaderContainer: {
      alignItems: "center",
      display: "flex",
      margin: "0px",
      minWidth: "100%",
    },

    DataGridHeaderTitle: {
      margin: "0 5px 0 0",
    },

    DataGridHeaderWithAddButton: {
      display: "flex",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "end",
      paddingRight: "0px !important",
      maxWidth: "100% !important",
    },

    DataGridHeaderWithOutAddButton: {
      display: "flex",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "space-between",
      paddingRight: "0px !important",
      maxWidth: "100% !important",
    },

    DataGridHeaderButton: {
      marginLeft: "10px",
      borderRadius: "8px",
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "14px",
      letterSpacing: "0.5px",
      textAlign: "left",
      height: "35px",
      boxShadow: "none",
    },

    DataGridHeaderButtonActive: {
      backgroundColor: `${theme.palette.primary.main}`,
      color: `${theme.palette.custom.white}`,
      "&:hover": {
        backgroundColor: `${theme.palette.primary.main}`,
        color: `${theme.palette.custom.white}`,
      },
    },

    DataGridHeaderButtonInactive: {
      backgroundColor: `${theme.palette.custom.borderColor}`,
      color: `${theme.palette.custom.black}`,
      "&:hover": {
        backgroundColor: `${theme.palette.custom.borderColor}`,
        color: `${theme.palette.custom.black}`,
      },
    },
  };
};
