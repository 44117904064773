export const styles = (theme) => {
  return {
    detailViewDrawerContainer: {
      padding: "0px !important",
      flexDirection: "column",
      margin: "0px",
      boxShadow: `0px 4px 12px 0px ${theme.palette.custom.turquoise}`,
      borderRadius: "4px",
      width: "450px",
      alignSelf: "flex-end",
      alignItems: "center",
      zIndex: "3",
    },
    detailViewDrawerContainerMin: {
      position: "fixed",
      // left: "calc(100% - 450px)",
      right: "0",
      bottom: "0",
    },
    detailViewDrawerContainerMax: {
      height: "calc(100% - 70px)",
      position: "fixed",
      bottom: "0",
      right: "0",
    },
    detailViewDrawerContainerOpen: {
      display: "flex",
    },
    detailViewDrawerContainerClose: {
      display: "none",
    },
    detailViewDrawerContainerHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "row",
      backgroundColor: `${theme.palette.primary.main}`,
      minHeight: "50px !important",
      minWidth: "100%",
      borderRadius: "4px 0px 0px 0px",
    },

    detailViewDrawerContainerComments: {
      height: "calc(100% - 100px)",
      justifyContent: "center",
      textAlign: "center",
      padding: "10px !important",
      width: "calc(100% - 20px)",
      overflowY: "auto",
      "&::-webkit-scrollbar": { display: "none" },
      msOverflowStyle: "none",
      scrollbarWidth: "none",
      backgroundColor: `${theme.palette.custom.white}`,
    },

    detailViewDrawerContainerCommentsTitle: {
      marginLeft: "2rem",
      color: `${theme.palette.custom.white}`,
      fontSize: "25px",
      fontWeight: "600",
    },

    detailViewDrawerMinMaxContainer: {
      textAlign: "right",
      width: "auto",
      marginRight: "0.5rem",
    },

    detailViewDrawerCommentContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "end",
      margin: "10px",
      backgroundColor: `${theme.palette.custom.white}`,
    },

    detailViewDrawerContainerComment: {
      marginBottom: "30px",
      padding: "10px",
    },

    responderIcon: {
      width: "40px !important",
    },

    studentIcon: {},

    commentResponder: {
      backgroundColor: `${theme.palette.custom.lightPink}`,
      borderRadius: "17px 17px 0px 17px",
      position: "relative",

      "&::after": {
        content: '""',
        display: "flex",
        position: "absolute",
        top: "27px",
        left: "calc(100% - 22px)",
        width: "15px",
        height: "15px",
        transform: "rotate(70deg) translate(40%)",
        borderTop: `15px solid  ${theme.palette.custom.lightPink}`,
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
      },
    },

    commentStudent: {
      backgroundColor: `${theme.palette.custom.lightBlue}`,
      borderRadius: "17px 17px 17px 0px",
      position: "relative",

      "&:after": {
        content: '""',
        display: "flex",
        position: "absolute",
        top: "27px",
        left: "-5px",
        width: "15px",
        height: "15px",
        transform: "rotate(-70deg) translate(-30%)",
        borderTop: `15px solid ${theme.palette.custom.lightBlue}`,
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
      },
    },

    detailViewDrawerContainerFooter: {
      borderTop: `0.5px solid ${theme.palette.custom.lightGray}`,
      padding: "20px 10px 20px 10px !important",
      backgroundColor: `${theme.palette.custom.white}`,
    },

    detailViewDrawerContainerFooterTextField: {
      padding: "0px !important",
      paddingLeft: "20px !important",
      minWidth: "100%",
      backgroundColor: `${theme.palette.custom.lighGray}`,
      borderRadius: "4px",
      border: "none !important",

      "& .MuiOutlinedInput-input": {
        color: theme.palette.text.primary,
        border: "none !important",
      },

      "& .MuiOutlinedInput-notchedOutline": {
        border: "none !important",
      },

      "&.Mui-focused .MuiOutlinedInput-input": {
        padding: "0px !important",
      },
    },

    detailViewDrawerContainerHeaderIcon: {
      cursor: "pointer",
      height: "20px !important",
      minWidth: "10px !important",
      margin: "0px",
      marginRight: "10px",
      color: "white",
    },

    hide: {
      display: "none",
      color: "pink",
    },
    show: {
      display: "flex",
    },

    detailViewDrawerContainerCommentText: {
      color: `${theme.palette.custom.black}`,
      fontSize: "16px",
      fontWeight: "400",
      fontFamily: "Nunito Sans",
    },
    detailViewDrawerContainerCommentDate: {
      paddingLeft: "10px",
      paddingRight: "10px",
      color: `${theme.palette.custom.black}`,
      fontSize: "11px",
      fontWeight: "400",
      fontFamily: "Nunito Sans",
    },
    responderDate: {
      top: "20px",
      left: "10px",
      textAlign: "right",
    },
    studentDate: {
      textAlign: "left",
      top: "20px",
      left: "-10px",
    },
  };
};
