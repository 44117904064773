import React from "react";

import DefaultLayout from "../layouts/defaultLayout";
import Dashboard from "../feature/dashboard/dashboard";
import DistrictsPage from "../feature/districts/districts";
import Schools from "../feature/schools/schools";
import Students from "../feature/students/students";
import Responders from "../feature/responders/responders";
import SuperAdmins from "../feature/superAdmins/superAdmins";
import Incidents from "../feature/incidents/incidents";
import Settings from "../feature/help/help";

import DistrictPage from "../feature/districts/district";
import School from "../feature/schools/school";
import Student from "../feature/students/student";
import Responder from "../feature/responders/responder";
import SuperAdmin from "../feature/superAdmins/superAdmin";
import Incident from "../feature/incidents/incident";
import Account from "../feature/account/account";
import User from "../feature/users/user";

import Error404 from "../feature/core/error404";

const privateRoutes = (setThemeName) => {
  return [
    {
      path: "/",
      element: <DefaultLayout setThemeName={setThemeName} />,
      children: [
        { index: true, element: <Dashboard /> },
        { path: "dashboard", element: <Dashboard /> },
        { path: "districts", element: <DistrictsPage /> },
        { path: "district/:id", element: <DistrictPage /> },
        { path: "schools", element: <Schools /> },
        { path: "school/:id", element: <School /> },
        { path: "students", element: <Students /> },
        { path: "student/:id", element: <Student /> },
        { path: "responders", element: <Responders /> },
        { path: "responder/:id", element: <Responder /> },
        { path: "superadmins", element: <SuperAdmins /> },
        { path: "superadmin/:id", element: <SuperAdmin /> },
        { path: "incidents", element: <Incidents /> },
        { path: "incident/:id", element: <Incident /> },
        { path: "user/:id", element: <User /> },
        { path: "help", element: <Settings /> },
        { path: "account", element: <Account /> },
        { path: "*", element: <Error404 /> },
      ],
    },
  ];
};

export default privateRoutes;
