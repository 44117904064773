import DistrictInformationTab from '../components/districtInformationTab';
import UsersTab from '../components/usersTab';
import SchoolsTab from '../components/schoolsTab';
import { TrashCanIcon } from '../../core/customIcons';


export const getDetailViewConfig = async () => {
    return {
        titleConfig: {
            breadcrumbs: true,
            field: 'name',
            mainAction: {
                type: 'text',
                text: 'Delete This District',
                field: 'delete',
                textColor: 'warning.main',
                textStyles: ["underline"],
                preIcon: <TrashCanIcon />,
                modalConfig: {
                    delete: {
                        title: 'Delete District',
                        type: 'text',
                        message: 'Are you sure you want to delete {data.name} district?',
                        submitLabel: 'Delete',
                        cancelLabel: 'Cancel',
                        submitColor: 'darkRed',
                        cancelColor: 'blue',
                        submitType: 'delete',
                    }
                }
            },
        },
        title: 'districts',
        type: 'districts',
        tabs: [
            {title: 'District Information', component:DistrictInformationTab},
            {title: 'Users', component:UsersTab},
            {title: 'Schools', component:SchoolsTab},
            // {title: 'Schools', component:SchoolsTab, chip: {field: "numberOfSchools"}},
        ],
        actions: {
            edit: false,
            delete: false
        }
    }
}