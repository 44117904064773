import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DetailsView from '../core/detailsView';

import { getDetailViewConfig } from './configs/districtsDetailViewConfig';

const DistrictPage = (props) => {
    const { state } = useLocation();
    const [detailViewConfig, setDetailViewConfig] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            const detailViewConfig = await getDetailViewConfig();
            setDetailViewConfig(detailViewConfig);
        };
        fetchData();
    }, []);

    return (
        <>
        {detailViewConfig?.type ? (
        <DetailsView
            data={state}
            titleConfig={detailViewConfig.titleConfig}
            type={detailViewConfig.type}
            title={detailViewConfig.title}
            tabs={detailViewConfig.tabs}
            mainAction={detailViewConfig.mainAction}
            actionItems={detailViewConfig.actionItems}
        />
        ) : (
            <div></div>
        )}
        </>
    );
};

export default DistrictPage;