import { useContext, useState, useEffect } from 'react';
import { Container, Typography } from '@mui/material';
import GlobalAppContext from '../../context';
import { useTheme } from '@mui/material/styles';
import { styles } from '../styles/reportBreakdownStyles';

const ReportBreakdown = ({config, data}) => {
  const [breakdownData, setBreakdownData] = useState(data);

  useEffect(() => {
    setBreakdownData(data);
  }, [data]);

  const theme = useTheme();
  const { namespace  } = useContext(GlobalAppContext);
  const style = styles(theme);
  return (
    <Container sx={style.mainContainer}>
        <Typography sx={style.heading}>
          {namespace.dashboard.reportBreakdown}
        </Typography>
      <Container sx={style.bodyContainer}>
        {config.map((item, index) => {
          return (
            <Container key={index} sx={style.incidentCard}>
              <Typography sx={style.title}>{item.title}</Typography>
              <Container sx={style.breakDownContainer}>
                <Container sx={style.subHeadingContainer}>
                  {breakdownData[item.field].map((sItem, index) => (
                        <Typography sx={style.subHeading} key={index}>{sItem.label}</Typography>                    
                  ))}
                </Container>
                <Container sx={style.percentageContainer}>
                  {breakdownData[item.field].map((sItem, index) => (
                    <Container sx={style.percentageWrapper} key={index}>
                      <Container sx={style.percentageBody(item.colors[index], sItem.percentage)}></Container>
                      <Typography sx={style.percentageNumber}>{sItem.value}</Typography>
                    </Container>
                  ))}
                </Container>
                </Container>
            </Container>
          )
        })}
      </Container>
    </Container>
  )
}

export default ReportBreakdown;