/**
 * This generates the Help Page for the application
 * @param {*} props These are the properties required for the Help Page (user, etc)
 * @returns This returns a generated Help Page for the user
 */
import React from "react";
import { Container, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { helpPageStyles } from "../core/styles/cards/helpPageStyles";
import { Link } from "@mui/material";
import HelpInfo from "./components/helpInfo";

const HelpPage = ({ props }) => {
  const theme = useTheme();
  const styles = helpPageStyles(theme);

  return (
    <Container sx={styles.mdHelpCard}>
      <Container>
        <Container>
          <Typography variant="body1" sx={styles.mdHelpTitle}>
            <p>
              Home / <span style={styles.mdHighlightText}>Help & Support</span>
            </p>
          </Typography>
        </Container>
      </Container>

      <Container>
        <Typography sx={styles.mdHelpText}>Need Help?</Typography>
      </Container>

      <Container>
        <Typography sx={styles.mdFreAsked}>
          Frequently Asked Questions
        </Typography>
      </Container>

      <HelpInfo />

      <Container sx={styles.mdContactText}>
        <Typography>
          If you need to contact our technical support directly please write us
          at{" "}
          <Link component="a" href="mailto:lumahn@disclosureinc.net">
            lumahn@disclosureinc.net
          </Link>
        </Typography>
      </Container>
    </Container>
  );
};

export default HelpPage;
