export const styles = (theme) => {
  return {
    averageContainer: {
      height: '453px',
      borderRadius: '4px',
      border: `1px solid ${theme.palette.custom.gray1}`,
      backgroundColor: theme.palette.custom.white,
      borderRadius: '12px',
      justify: 'space-between',
      padding: '24px',
      marginTop: '10px',
    },
    heading: {
      display: 'flex',
      flexDirection: 'column'
    },
    margin: function(position, value) {
      switch (position.toLowerCase()) {
        case 'top':
          return { marginTop: value };
        case 'bottom':
          return { marginBottom: value };
        case 'left':
          return { marginLeft: value };
        case 'right':
          return { marginRight: value };
        default:
          return { margin: value };
      }
    }
  };
};