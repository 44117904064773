import { useContext, useState, useEffect } from 'react';
import { Container, Typography } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import DashboardFilter from './components/filter';
import ActivityCard from './components/activityCard';
import IncidentsOverTime from './components/incidentsOverTime';
import BarGraphCard from './components/barGraphCard';
import ReportBreakdown from './components/reportBreakdown';
import AverageResolveTimes from './components/averageTimes';
import PaginatedResolveTime from './components/paginatedResolveTime';
import AverageNumberofIncidents from './components/averageNumber';
import GlobalAppContext from '../context';

import { useTheme } from '@mui/material/styles';

import { styles } from './styles/dashboardStyles';

import { ActivityData } from './configs/activityData';
import { barGraphConfig } from './configs/barGraphConfig';
import { breakdownConfig } from './configs/breakdownConfig';
import { overTimeConfig } from './configs/overTimeConfig';
import { multiGraphConfig } from './configs/multiGraphConfig';
import { LoadingIcon } from '../core/loadingIcon';

import { api } from '../../api/index';
const DashboardPage = () => {
  dayjs.extend(utc)
  const user = JSON.parse(localStorage.getItem('user'));
  const theme = useTheme();
  const { namespace  } = useContext(GlobalAppContext);
  const style = styles(theme);
  const [data, setData] = useState({});
  const [filterValues, setFilterValues] = useState(
    {
      "district": user.permission !== "disclosureAdmin" ? user.district : "",
      "school": user.permission !== "disclosureAdmin" && user.permission !== "districtAdmin" ? user.school : "",
      "startDate": dayjs().local().subtract(1, 'months').startOf('day'),
      "endDate": dayjs().utc().local().endOf('day')
    });
  const [loading, setLoading] = useState(true);


  const makeApiCall = async () => {
    setLoading(true)
    const backUpData = {...data}
    setData({})
    const getBody = {
      districtId: filterValues.district,
      schoolId: filterValues.school,
    }
    if (user.permission === "districtAdmin") {
      getBody.districtId = user.district
    }
    if (user.permission === "schoolAdmin" || user.permission === "responder") {
      getBody.districtId = user.district
      getBody.schoolId = user.school
    }
    getBody.startDate = filterValues.startDate.utc().format('YYYY-MM-DDTHH:mm:ss')
    getBody.endDate = filterValues.endDate.utc().format('YYYY-MM-DDTHH:mm:ss')
    const results = await api("get", getBody, "dashboard")
    if (results.status === 200) {
      setData(results.data)
      setLoading(false)
    } else {
      setData(backUpData)
      setLoading(true)
    }
  }

  useEffect (() => {
    const fetchData = async () => {
      if (user.permission === "schoolAdmin" || user.permission === "responder") {
        await makeApiCall()
      }
    }
    fetchData()
    // eslint-disable-next-line
  }, []);

  useEffect (() => {
    const fetchData = async () => {
      if (
        ((filterValues.district !== "" && filterValues.district) && user.permission === "disclosureAdmin") || 
        ((filterValues.school !== "" && filterValues.school) && user.permission === "districtAdmin")
      ) {
        await makeApiCall()
      }
    }
    fetchData()
    // eslint-disable-next-line
  }, [filterValues]);

  return (
    <Container sx={style.mainContainer}>
      {/* TOP FILTER */}
      <DashboardFilter 
        filterValues={filterValues}
        setFilterValues={setFilterValues}
      />
      {/* PAGE CONTENT */}
        <Container sx={style.dashboardContainer}>
          <Typography sx={style.title}>
            {namespace.dashboard.overview}
          </Typography>
          {!loading ? (
            <>
              {/* Analytic Cards */}
              <ActivityCard config={ActivityData} data={data} />
              {/* Incidents Cards */}
              <Container sx={style.secondRowContainer}>
                {/* Incidents Over Time */}
                  <IncidentsOverTime config={JSON.parse(JSON.stringify(overTimeConfig))} data={data}/>
                  {/* Active Incident Priority levels */}
                  <Container sx={style.barGraphContainer}>
                    <BarGraphCard config={barGraphConfig} data={data.priorityData} type={"priority"}/>
                    <BarGraphCard config={barGraphConfig} data={data.outcomeData}  type={"outcome"}/>
                  </Container>
              </Container>
              {/* Report Break Down Cards */}
              <ReportBreakdown config={breakdownConfig} data={data}/>
              {/* Resolve Times */}
              <Container sx={style.resolveTimesContainer}>
                <Typography variant='h5'>
                  {namespace.dashboard.resolveTimes}
                </Typography>
                <Container sx={style.averageResolveTimeConatiner}>
                    <AverageResolveTimes config={JSON.parse(JSON.stringify(overTimeConfig))} data={data}/>
                    <PaginatedResolveTime data={data.averageResolveTimes}/>
                </Container>
              </Container>
              {/* Student Overview */}
              <Container sx={style.studentOverViewContainer}>
                <Typography variant='h5'>
                  {namespace.dashboard.studentOverview}
                </Typography>
                <AverageNumberofIncidents config={multiGraphConfig} data={data.incidentsPerStudentByPriority}/>
              </Container>
            </>
          ) : (
            <>
            {
              ((filterValues.district === "" || !filterValues.district) && user.permission === "disclosureAdmin") || 
              ((filterValues.school === "" || !filterValues.school) && user.permission === "districtAdmin") ? (
              <Typography sx={style.noDistrict}>
                {`Please Select A ${user.permission === "disclosureAdmin" ? "District" : "School"} To View Data`}
              </Typography>
            ) : (
              <LoadingIcon page="dashboard" />
            )}
            </>
          )} 
        </Container>
    </Container> 
  )
}

export default DashboardPage;