
/**
 * This is the colour guide entire application
 */

export const utilsPrimaryColors = {
    black: '#262626',
    active: '#0082E1',
    light1: '#FAFAFA'
}

/**
 * This is the style guide entire application
 */
export const utilsApplicationStyle = {
    text: {
        navigation: {
            color: utilsPrimaryColors.black,
            selected: utilsPrimaryColors.active
        }
    },
    sidebar: {
        drawer: {
            width: '300px',
            minHeight: '500px',
            padding: '0 20px',
            margin: ' 0 0 0 20px'
        },
        logo: {
            padding: '20px'
        }
    },
    content: {
        topbar: {
            padding: '5px',
            height: '58px',
            account: {
                fontWeight: '100',
                margin: '0 0 0 10px',
                color: utilsPrimaryColors.black,
                colorIcon: utilsPrimaryColors.active
            },
            notificationIcon: {
                colorTextIcon: '#F1F1F1',
                badgeIconBackground: '#0082E1'
            }
        },
        content: {
            height: `calc(100vh - 58px)`, // This is the height - the top bar height
            maxWidth: '100vw !important'
        }
    }
};
