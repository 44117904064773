export const outcomeConfig = {
    modalConfig: {
        confirm: {
            title: 'You are about to send the outcome to the student',
            type: 'text',
            message: 'Please note that once you confirm this you cannot edit the investigation outcome.',
            submitLabel: 'Confirm',
            cancelLabel: 'Cancel',
            submitColor: 'red',
            cancelColor: 'blue',
            submitType: 'update',
            submittingLabel: 'Sending',
        },
        sent: {
            title: 'Investigation Outcome Sent',
            type: 'text',
            message: 'You investigation outcome has been sent. Please note that if the student provides a positive feedback this incident will be automatically closed. If not, the incident will be re-opened to continue the investigation',
            submitLabel: 'Close',
            submitColor: 'red',
            submittingLabel: 'Closing',
            submitType: 'close',
        }
    }
}