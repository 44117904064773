import React from "react";
import { Container, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { cardStyles } from "../../styles/cards/cardStyles";
import Form from "../forms/form";
import BodyTable from "./bodyTable";
import CheckList from "./checkList";

const Card = ({
  title,
  fields,
  columns,
  apiType,
  apiBody,
  data,
  values,
  setValues,
  validations,
  setValidations,
  bodyType,
  footer,
  setEdited,
  checkListValues,
}) => {
  const theme = useTheme();
  const styles = cardStyles(theme);

  return (
    <Container sx={styles.mdCard}>
      <Container sx={styles.mdCardHeader}>
        <Typography sx={styles.mdCardHeaderTitle}>{title}</Typography>
      </Container>
      <Container sx={styles.mdCardBody}>
        {bodyType === "form" ? (
          <Form
            fields={fields}
            values={values}
            setValues={setValues}
            setEdited={setEdited}
            validations={validations}
            setValidations={setValidations}
          />
        ) : bodyType === "table" ? (
          <BodyTable
            title={title}
            columns={columns}
            data={data}
            apiType={apiType}
            apiBody={apiBody}
            userID={apiBody.userId}
          />
        ) : (
          bodyType === "checkList" && (
            <CheckList fields={fields} checkListValues={checkListValues} />
          )
        )}
      </Container>
    </Container>
  );
};

export default Card;
