import React from "react";
import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import DataTableMD from "../../core/dataTableMD";
import {
  getDistrictUsersColumns,
  getDistrictUsersFilters,
} from "../configs/districtsUserDataConfig";

const UsersTab = (props) => {
  const { state } = useLocation();
  const [filters, setFilters] = useState([]);
  const [columns, setColumns] = useState([]);
  useEffect(() => {
    const getFilters = async () => {
      const filters = await getDistrictUsersFilters();
      const columns = await getDistrictUsersColumns(state);
      setFilters(filters);
      setColumns(columns);
    };
    getFilters();
    // eslint-disable-next-line
  }, []);
  const addSuccessMessage =
    "The new user has been successfully added to the system. ";

  return (
    <Container>
      <DataTableMD
        title="Users"
        dbTable={"users"}
        filters={filters}
        columns={columns}
        addButton={true}
        addSuccessMessage={addSuccessMessage}
        sort={{ field: "firstName", order: "asc" }}
        isTab={true}
        apiBody={{
          filters: [{ key: "district", value: state.id, expression: "=" }]
        }}
      />
    </Container>
  );
};

export default UsersTab;
