import { utilsApplicationStyle } from '../../utils/utilsApplicationStyle';

/**
 * This is the styling for the Component Sidebar
 * Values that are not pulled from the styles constant should never be changed and should remain static in this file.
 */

export const sideBarDrawer = {
    flexShrink: 0,
    '& ul': {
        padding: '0',
        marginLeft: '-70px',
        paddingBottom: '40px',
    },
    '& ul a': {
        textDecoration: 'none',
    },
    '& ul li': {
        color: utilsApplicationStyle.text.navigation.color,
        ':hover': {
            background: 'rgba(155,160,152, 0.5)'
        }
    },
    '& ul .selected li, ul .selected li svg': {
        color: utilsApplicationStyle.text.navigation.selected
    },
    '& ul .not-selected li, ul .not-selected li svg': {
        color: utilsApplicationStyle.text.navigation.color
    },
    '& ul li svg': {
        margin: utilsApplicationStyle.sidebar.drawer.margin
    },
    '& .expand': {
        height: '100%'
    },
    '& .MuiDrawer-paper': {
        boxSizing: 'border-box',
        minHeight: utilsApplicationStyle.sidebar.drawer.minHeight
    },
};

export const SideBarLogo = {
    padding: utilsApplicationStyle.sidebar.logo.padding,
    img: {
        width: '100%'
    }
};
