import React, { useState, useContext, useEffect } from "react";
import { Container, Button, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styles } from "./styles/detailsView/detailView";
import { useTheme } from "@mui/material/styles";
import DetailViewHeader from "./components/detailsView/detailViewHeader";
import DetailViewTabs from "./components/detailsView/detailViewTabs";
import DetailViewDrawer from "./components/detailsView/detailViewDrawer";
import DetailInfoCardPage from "./components/detailsView/detailInfoCardPage.js";
import ModalMD from "./modalMD";
import { WebSocketContext } from "../context/websocketsContext.js";
import { api } from "../../api";
import { localGet } from "../../utils/localStorageUtils.js";

const DetailsView = ({
  data,
  titleConfig,
  type,
  title,
  tabs,
  actionItems,
  headerConfig,
  cards,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState("closed");
  const [updating, setUpdating] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalConfig, setmodalConfig] = useState({});
  const [edited, setEdited] = useState(false);

  const [subscribe, unsubscribe] = useContext(WebSocketContext);

  const theme = useTheme();
  const style = styles(theme);

  const getDefaultValues = () => {
    const initValues = {};
    for (let field in data) {
      initValues[field] = data[field];
    }
    for (let card in cards) {
      for (let field in cards[card].fields) {
        if (!(cards[card].fields[field].field in initValues)) {
          initValues[cards[card].fields[field].field] = "";
        }
      }
    }
    return initValues;
  };

  const [values, setValues] = useState(getDefaultValues());
  const [validations, setValidations] = useState({});

  const deleteNotifications = async () => {
    const userData = await localGet("user", true);
    const tempData = {
      userId: userData.id,
      objectId: data.id,
    };
    await api("delete", tempData, "Notifications");
  };

  useEffect(() => {
    deleteNotifications();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    subscribe("Incidents", (messageData) => {
      let tempData = { ...data };
      if (messageData.command === "updateIncident") {
        const incidentData = messageData.incident;
        const tempIncident = tempData;
        for (let field in incidentData) {
          if (field !== "id") {
            tempIncident[field] = incidentData[field];
          }
        }
        tempData = tempIncident;
      }
      navigate(".", { replace: true, state: tempData });
    });
    return () => {
      unsubscribe("Incidents");
    };
    // eslint-disable-next-line
  }, [data, subscribe, unsubscribe]);

  const handleOpen = (open) => {
    setOpen(open);
  };

  const handleModalSubmit = async (value) => {
    await modalConfig.submitFunction(
      modalConfig.field,
      value,
      modalConfig.saveToDb,
      modalConfig.changeValue,
      modalConfig.changeField
    );
    setModalOpen(false);
  };

  const handleModalOpen = (modalConfigObj) => {
    setmodalConfig(modalConfigObj);
    setModalOpen(modalConfigObj.title);
  };

  const handleSaveClick = async () => {
    if (!updating) {
      setUpdating(true);
      let tempData = { ...data };
      for (const field in values) {
        tempData[field] = values[field];
      }
      const results = await api("patch", tempData, type);
      if (results.status === 200) {
        navigate(".", { replace: true, state: tempData });
        setEdited(false);
      } else {
        navigate(".", { replace: true, state: { ...data } });
      }
      setUpdating(false);
    }
  };

  const handleCancelClick = () => {
    const tempValues = getDefaultValues();
    setValues({ ...tempValues });
    setEdited(false);
  };

  return (
    <Container sx={style.detailViewContainer}>
      <Container
        sx={[
          style.detailViewLeftContainer,
          open === "max" && style.detailViewLeftContainerOpen,
        ]}
      >
        <DetailViewHeader
          data={data}
          titleConfig={titleConfig}
          type={type}
          title={title}
          actionItems={actionItems}
          headerConfig={headerConfig}
          updating={updating}
          setUpdating={setUpdating}
          handleModalOpen={handleModalOpen}
        />
        {cards ? (
          <DetailInfoCardPage
            data={data}
            cards={cards}
            values={values}
            setValues={setValues}
            validations={validations}
            setValidations={setValidations}
            setEdited={setEdited}
          />
        ) : (
          <DetailViewTabs
            data={data}
            tabs={tabs}
            handleOpen={handleOpen}
            updating={updating}
            setUpdating={setUpdating}
            handleModalOpen={handleModalOpen}
          />
        )}
        {cards && edited && (
          <Container sx={style.detailViewButtonContainer}>
            <Button
              sx={style.detailViewButtonCancel}
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
            <Button
              sx={style.detailViewButtonSave}
              onClick={handleSaveClick}
              disabled={Object.values(validations).includes(false)}
            >
              {updating ? "Saving" : "Save"}
              {updating && (
                <CircularProgress sx={style.detailViewButtonProgress} />
              )}
            </Button>
          </Container>
        )}
      </Container>
      <DetailViewDrawer
        data={data}
        open={open}
        handleOpen={handleOpen}
        updating={updating}
        setUpdating={setUpdating}
      />
      <ModalMD
        title={modalConfig.title}
        type={modalConfig.type}
        label={modalConfig.label}
        cancelLabel={modalConfig.cancelLabel}
        submitLabel={modalConfig.submitLabel}
        submittingLabel={modalConfig.submittingLabel}
        open={modalOpen}
        submitFunction={handleModalSubmit}
        message={modalConfig.message}
        data={data}
        setModalOpen={setModalOpen}
      />
    </Container>
  );
};

export default DetailsView;
