import React, { useState } from "react";
import { Snackbar, Alert as MuiAlert } from "@mui/material";
import {
  Container,
  FormControl,
  Typography,
  Button,
  Box,
  Modal,
  Divider,
} from "@mui/material";
import { CloseIcon } from "../../customIcons";
import { styles } from "../../styles/modal/bodyTableModal";
import { useTheme } from "@mui/material/styles";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import BodyTableSuccessModal from "./bodyTableSuccessModal";
import { api } from "../../../../api/index";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const BodyTableModal = ({
  key,
  open,
  handleClose,
  title,
  contentBody,
  columns,
  apiType,
  userID,
  fetchData,
}) => {
  const [selectedDates, setSelectedDates] = useState(columns.map(() => null));
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [error, setError] = useState(null);
  const [submit, setSubmit] = useState(false);

  const theme = useTheme();
  const style = styles(theme);

  const [adding, setAdding] = useState(false);

  const handleDateChange = (columnIndex, newDate) => {
    const updatedSelectedDates = [...selectedDates];

    const selectedDate = dayjs(newDate).tz();
    let date = selectedDate.format();

    updatedSelectedDates[columnIndex] = date;
    setSelectedDates(updatedSelectedDates);
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarOpen(false);
  };

  const handleCloseSubmitModel = async () => {
    handleClose();
    setSubmit(false);
    await fetchData();
  };

  const handleConfirm = async () => {
    const startDate = selectedDates[0];
    const endDate = selectedDates[1];

    if (startDate > endDate) {
      setSnackBarOpen(true);
      setError("Start date can not be after the end date.");
      return;
    }

    if (startDate === null || endDate === null) {
      setSnackBarOpen(true);
      setError("Date can not be empty.");
      return;
    }

    if (startDate === endDate) {
      setSnackBarOpen(true);
      setError("Start date can not be the same as end date.");
      return;
    }

    try {
      setAdding(true);
      setError(null);
      await api(
        "post",
        {
          startDate: startDate,
          endDate: endDate,
          user: userID,
        },
        apiType
      );
    } catch (error) {
      setError(error);
    } finally {
      setSubmit(true);
      setAdding(false);
    }
  };

  return (
    <>
      {submit ? (
        <BodyTableSuccessModal
          title={"Personal Time Off Created"}
          contentBody={
            "Your personal time off has been created. The system will not assign you any new incidents during the period of time you selected."
          }
          open={submit}
          handleClose={handleCloseSubmitModel}
        />
      ) : (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style.bodyTableModalContainer}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={style.bodyTableModalBodyModalHeader}
            >
              {title}
              <CloseIcon sx={style.closeIcon} onClick={handleClose} />
            </Typography>
            <Divider sx={style.modalHeaderDivider} />
            <Typography sx={style.bodyTableSubText}>{contentBody}</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Container sx={style.formContainer}>
                {columns.map(
                  (column, index) =>
                    column.name !== "Count" && (
                      <FormControl
                        key={column.name}
                        fullWidth
                        sx={style.formControl}
                      >
                        <Typography sx={style.inputLabel} key={column.name}>
                          {column.name}
                        </Typography>
                        <DatePicker
                          key={index}
                          format="DD-MM-YYYY"
                          onChange={(newDate) =>
                            handleDateChange(index, newDate)
                          }
                        />
                      </FormControl>
                    )
                )}
              </Container>
            </LocalizationProvider>
            <Divider sx={style.modalFooterDivider} />
            <Box sx={style.footerConatiner}>
              <>
                <Button
                  sx={style.cancelButton}
                  size="medium"
                  variant="text"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  sx={style.addButton}
                  size="medium"
                  variant="contained"
                  disabled={adding}
                  onClick={handleConfirm}
                >
                  {adding ? "Adding..." : "Confirm"}
                </Button>
                <Snackbar
                  open={snackBarOpen}
                  autoHideDuration={6000}
                  onClose={handleSnackBarClose}
                >
                  <MuiAlert
                    onClose={handleSnackBarClose}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {error}
                  </MuiAlert>
                </Snackbar>
              </>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default BodyTableModal;
