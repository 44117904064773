import React from 'react';
import InfoContainerMD from '../../core/infoContainerMD';
import { Container, Typography } from "@mui/material";
import { incidentsHeadings } from '../configs/incidentsInfoConfig';
import { studentInfo } from "../configs/studentDetailsCardConfig";
import { styles } from "../styles/incidentInfoTab";
import { useTheme } from "@mui/material/styles";


const IncidentInfoTab = ({data, handleOpen, updating, setUpdating }) => {
    const theme = useTheme();
    const style = styles(theme);
    return (
        <>
            {data.studentData && (
                <Container sx={style.studentInfoContainer}>
                    <Container sx={style.studentInfoHeader}>
                        <Typography sx={style.studentInfoTitle}>
                            Student Personal Information
                        </Typography>
                    </Container>
                    <InfoContainerMD
                        config={studentInfo}
                        infoData={data.studentData}
                    />
                </Container>
            )}
            <InfoContainerMD
                config={incidentsHeadings}
                infoData={data}
                isIncident={true}
                handleOpen={handleOpen}
                updating={updating}
                setUpdating={setUpdating}
                type={"incidents"}
            />
        </>
    );
};

export default IncidentInfoTab;