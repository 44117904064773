export const styles = (theme) => ({
  detailViewTabContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "90%",
    padding: "0px",
    margin: "0px",
    marginLeft: "60px",
  },
  responderCommentTextArea: {
    display: "flex",
    marginTop: "60px",
    width: "100%",
    borderRadius: "4px",
    boxShadow: `0px 0px 8px 0px ${theme.palette.custom.turquoise} inset`,
    color: `${theme.palette.custom.black} !important`,
    "& .MuiOutlinedInput-root": {
      padding: "0px !important",
    },
    "& .MuiOutlinedInput-input": {
      color: `${theme.palette.custom.gray}`,
      border: "none !important",
      fontSize: "18px !important",
      fontWeight: "600 !important",
      fontFamily: "Nunito Sans",
      paddingLeft: "20px !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    "&.Mui-focused .MuiOutlinedInput-input": {
      padding: "0px !important",
    },
  },
  responderCommentTextAreaButton: {
    backgroundColor: `${theme.palette.primary.main}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "60px",
    height: "120px",
    margin: "0px",
    padding: "0px !important",
    borderRadius: "0px 4px 4px 0px",
  },
  responderCommentTextAreaButtonIcon: {
    color: `${theme.palette.custom.white}`,
    fontSize: "30px",
  },
  activityEntriesWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    margin: "0px",
    padding: "0px !important",
    marginTop: "40px",
  },
  activityEntryWrapper: {
    margin: "0px",
    padding: "0px !important",
    marginBottom: "40px !important",
    borderLeft: `2px solid ${theme.palette.custom.borderColor}`,
    paddingLeft: "20px !important",
  },
  blackDot: {
    display: "flex",
    position: "relative",
    left: "-25px",
    content: "''",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    backgroundColor: `${theme.palette.custom.black}`,
    margin: "0px",
    padding: "0px !important",
  },
  lastEntry: {
    borderLeft: "none",
  },

  activityEntryHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0px",
    padding: "0px !important",
    position: "relative",
    top: "-15px",
    minWidth: "100%",
  },
  activityEntryHeaderLeft: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "0px",
    padding: "0px !important",
    position: "relative",
    top: "-9px",
    width: "auto",
  },
  activityEntryHeaderUserName: {
    color: `${theme.palette.custom.darkGray}`,
    fontSize: "18px",
    fontWeight: "600",
    marginRight: "5px",
  },
  activityEntryHeaderLeftChip: {
    backgroundColor: `${theme.palette.primary.light}`,
    color: `${theme.palette.primary.black}`,
    fontSize: "14px",
    fontWeight: "600",
    borderRadius: "50%",
    padding: "0px !important",
    height: "35px",
    width: "35px",
    marginRight: "10px",
    "& .MuiChip-label": {
      padding: "0px !important",
    },
  },

  graylogo: {
    marginTop: "5px",
    color: `${theme.palette.custom.blue}`,
    fontSize: "20px",
  },
  activityEntryHeaderLeftChipStudent: {
    backgroundColor: `${theme.palette.custom.blue}`,
  },
  activityEntryHeaderMessageContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  activityEntryHeaderMessage: {
    color: `${theme.palette.custom.darkGray}`,
    fontSize: "18px",
    fontWeight: "400",
  },
  activityEntryHeaderMessageChip: {
    marginLeft: "10px",
    marginRight: "10px",
  },
  activityEntryHeaderTime: {
    color: `${theme.palette.custom.darkGray}`,
    fontSize: "16px",
    fontWeight: "600",
    width: "auto",
  },
  activityEntryBody: {
    backgroundColor: `${theme.palette.custom.backgroundColor}`,
    margin: "0px",
    marginTop: "-10px",
    minWidth: "100%",
    borderRadius: "4px",
    border: `1px solid ${theme.palette.custom.borderColor}`,
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  responderCommentTextAreaButtonProgress: {
    color: `${theme.palette.custom.white}`,
    width: "30px !important",
    height: "30px !important",
  },
});
