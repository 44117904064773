import {
    getUrgencyOptions,
    getTypeOptions,
    getStatusOptions,
    getCategoryOptions,
    getPerpetratorOptions,
} from '../../../utils/incidentConfig';
/**
 * This is the detault columns for Incidents
 * field - This is the property on the object that wil be displayed within this column
 * headerName - This is the header that will be displayed for the column
 * hideableable: This is a custom field that will determine whether a column will be able to be added to the grid or not (visibility) 
 */
export const incidentsColumns = [
    {
        field: 'number',
        headerName: 'Incident ID',
        showColumn: true,
        textConfig: {bold: true},
        preString: "#",
        alertIcons: [
            {
                field: 'escalated',
                value: "True",
                icon: "emergency",
                placement: "post"
            },
            {
                field: 'emergency',
                value: "True",
                icon: "escalated",
                placement: "post"
            }
        ]
    }, {
        field: 'priority',
        headerName: 'Priority',
        cellType: 'chip',
        typeConfig: {colors: {"Low": "green", "Medium": "orange", "High": "red"}},
        showColumn: true,
    }, {
        field: 'type',
        headerName: 'Incident Type',
        cellType: 'text',
        showColumn: true,
    }, {
        field: 'category',
        headerName: 'Category Type',
        cellType: 'text',
        showColumn: true,
    }, {
        field: 'perpetrator',
        headerName: 'Persons Involved',
        cellType: 'text',
        showColumn: true,
    }, {
        field: 'assignedUsersNames',
        headerName: 'Assignees',
        cellType: 'text',
        showColumn: true,
    }, {
        field: 'status',
        headerName: 'Status',
        cellType: 'chip',
        typeConfig: {
            colors: {
                "New": "green",
                "Open": "gray",
                "Investigate": "blue",
                "Waiting": "pink",
                "Closed": "darkGray",
                "Blocked": "yellow",
            }
        },
        showColumn: true,
    }, {
        field: 'dateCreated',
        headerName: 'Created',
        cellType: 'hoursAgoDate',
        showColumn: true,
    }
];

export const getIncidentsFilters = async () => {
    const urgencyOptions = await getUrgencyOptions();
    const typeOptions = await getTypeOptions();
    const statusOptions = await getStatusOptions();
    const categoryOptions = await getCategoryOptions();
    const perpetratorOptions = await getPerpetratorOptions();
    return [
        { 
            title: "Severity",
            field: "urgency",
            options: urgencyOptions,
            type: "select",
        },
        { 
            title: "Incident Type",
            field: "type",
            options: typeOptions,
            type: "select",
        },
        { 
            title: "Category Type",
            field: "category",
            options: categoryOptions,
            type: "select",
        },
        { 
            title: "Person Involved",
            field: "perpetrator",
            options: perpetratorOptions,
            type: "select",
        },
        {
            title: "Assignee",
            field: "assignedUsers",
            labelField: "assignedUsersName",
            type: "apiSelect",
            returnType: "string",
            multiple: true,
            apiConfig: {
              apiBody: {filters: [{ key: "permission", value: "responder", expression: "=",}]},
              dataType: "users",
              label: "Assignee",
              addedOptions: [{ id: "unassigned", label: "Unassigned" }],
            },
        },
        { 
            title: "Status",
            field: "status",
            options: statusOptions,
            type: "select",
        }
    ]
}

export const incidentsToggles = {
    field: "status",
    options: ["All", "Open", "Closed"],
    active: ["All"],
    type: "string"
}