/**
 * This is the detault columns for students
 * field - This is the property on the object that wil be displayed within this column
 * headerName - This is the header that will be displayed for the column
 * hideableable: This is a custom field that will determine whether a column will be able to be added to the grid or not (visibility)
 */

export const getStudentsColumns = async () => {
  return [
    {
      field: "number",
      headerName: "Disclosure ID",
      cellType: "text",
      showColumn: true,
      showAdd: false,
      preString: "#",
    },
    {
      field: "activeIncidents",
      headerName: "Active Incidents",
      cellType: "text",
      showAdd: false,
      showColumn: true,
    },
    {
      field: "closedIncidents",
      headerName: "Closed Incidents",
      cellType: "text",
      showAdd: false,
      showColumn: true,
    },
    {
      field: "escalatedIncidents",
      headerName: "Escalated",
      cellType: "text",
      showAdd: false,
      showColumn: true,
    },
    {
      field: "emergencyIncidents",
      headerName: "Emergencies",
      cellType: "text",
      showAdd: false,
      showColumn: true,
    },
    {
      field: "dateCreated",
      headerName: "Account Creation",
      cellType: "hoursAgoDate",
      showAdd: false,
      showColumn: true,
    },
    {
      field: "firstName",
      headerName: "First Name",
      cellType: "text",
      showColumn: false,
      showAdd: true,
      required: true,
      type: "text",
      dataType: "string",
    },
    {
      field: "lastName",
      headerName: "Last Name",
      cellType: "text",
      showColumn: false,
      showAdd: true,
      required: true,
      type: "text",
      dataType: "string",
    },
    {
      field: "email",
      headerName: "Email",
      cellType: "text",
      showColumn: false,
      showAdd: true,
      required: true,
      type: "text",
      dataType: "email",
    },
    {
      field: "district",
      headerName: "District",
      cellType: "text",
      showColumn: false,
      showAdd: true,
      required: true,
      type: "apiSelect",
      showPermissions: ["disclosureAdmin"],
      apiConfig: {
        apiBody: {},
        label: "District",
        dataType: "districts",
      },
      dataType: "string",
    },
    {
      field: "school",
      headerName: "School",
      cellType: "text",
      showPermissions: ["disclosureAdmin", "districtAdmin"],
      showColumn: false,
      showAdd: true,
      required: true,
      type: "apiSelect",
      apiConfig: {
        apiBody: {filters: [{ key: "district", value: "{district}", expression: "=", valueObj: "filterValues" }]},
        label: "School",
        dataType: "schools",
      },
      dataType: "string",
      requires: {
        district: {},
      },
    },
  ];
};

export const getStudentFilters = async () => {
  return [
    {
      title: "District",
      field: "district",
      labelField: "districtName",
      permission: ["disclosureAdmin"],
      type: "apiSelect",
      returnType: "string",
      apiConfig: {
        apiBody: {},
        label: "District",
        dataType: "districts",
      },
    },
    {
      title: "School",
      field: "school",
      labelField: "schoolName",
      permission: ["disclosureAdmin", "districtAdmin"],
      type: "apiSelect",
      returnType: "string",
      apiConfig: {
        apiBody: {filters: [{ key: "district", value: "{district}", expression: "=", valueObj: "filterValues" }]},
        dataType: "schools",
        label: "School",
        requires: ["district"],
      },
    },
  ];
};
