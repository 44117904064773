import React from 'react';
import { useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import DataTableMD from '../core/dataTableMD';
import { getUsersColumns, getUsersFilters} from './configs/superAdminsDataTableConfig';
/*
 * This generates the Users Page for the application
 * @param {*} props These are the properties required for the Users page (user, etc)
 * @returns This returns a generated Users page for the user
 */
const SuperAdminsPage = (props) => {
    const { state } = useLocation();
    const [filters, setFilters] = useState([])
    const [columns, setColumns] = useState([])  
    useEffect(() => {
        const getFilters = async () => {
            const filters = await getUsersFilters()
            const columns = await getUsersColumns(state)
            setFilters(filters)
            setColumns(columns)
        }
        getFilters()
        // eslint-disable-next-line
    }, [])
    const addSuccessMessage = "The new user has been successfully added to the system. "
    return (
        <DataTableMD
            title="Super Admins"
            dbTable="users"
            filters = {filters}
            columns = {columns}
            addButton={true}
            addSuccessMessage={addSuccessMessage}
            sort={{field: 'firstName', order: 'asc'}}
            apiBody={{
                filters: [{ key: "permission", value: "disclosureAdmin", expression: "=" }],
            }}
        />
    );
};

export default SuperAdminsPage;