import { getAvailabilityOptions } from "../../../utils/userConfig";
/**
 * This is the detault columns for Users
 * field - This is the property on the object that wil be displayed within this column
 * headerName - This is the header that will be displayed for the column
 * hideableable: This is a custom field that will determine whether a column will be able to be added to the grid or not (visibility)
 */

export const getUsersColumns = async (data) => {
  return [
    {
      field: "firstName",
      secondField: "lastName",
      fieldConfig: "combined",
      headerName: "User Name",
      showColumn: true,
      cellType: "userName",
      showAdd: false,
    },
    {
      field: "avgResolveTime",
      headerName: "Avg. Resolve Time",
      cellType: "text",
      showColumn: true,
      showAdd: false,
    },
    {
      field: "permission",
      headerName: "Role",
      cellType: "chip",
      typeConfig: {
        colors: {
          "District Admin": "lightblue",
          "School Admin": "yellow",
          Responder: "blue",
          "Super Admin": "lightpurple",
        },
      },
      displayConfig: {
        districtAdmin: "District Admin",
        schoolAdmin: "School Admin",
        responder: "Responder",
        disclosureAdmin: "Super Admin",
      },
      showColumn: true,
      showAdd: true,
      required: true,
      disabled: true,
      addDefaultValue: "disclosureAdmin",
      type: "select",
      options: [{ value: "disclosureAdmin", label: "Super Admin" }],
      dataType: "string",
    },
    {
      field: "availability",
      headerName: "Availability",
      cellType: "Availability",
      typeConfig: {
        colors: {
          Available: "green",
          "Out of Office": "red",
        },
      },
      showColumn: true,
      showAdd: false,
    },
    {
      field: "firstName",
      headerName: "First Name",
      cellType: "text",
      showColumn: false,
      showAdd: true,
      required: true,
      type: "text",
      dataType: "string",
    },
    {
      field: "lastName",
      headerName: "Last Name",
      cellType: "text",
      showColumn: false,
      showAdd: true,
      required: true,
      type: "text",
      dataType: "string",
    },
    {
      field: "email",
      headerName: "Email",
      cellType: "text",
      showColumn: false,
      showAdd: true,
      required: true,
      type: "text",
      dataType: "email",
    },
    {
      field: "phone", 
      headerName: "Phone",
      cellType: "text",
      showColumn: false,
      showAdd: true,
      required: true,
      type: "text",
      dataType: "phone",
    }
  ];
};

export const getUsersFilters = async () => {
  const availabilityOptions = await getAvailabilityOptions();
  return [
    {
      title: "Availability",
      field: "availability",
      returnType: "list",
      options: availabilityOptions,
      type: "select",
    },
  ];
};
