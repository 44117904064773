import { useEffect, useState, useContext } from 'react';
import { Typography, Pagination, Container, Table, TableBody, TableRow, TableCell } from '@mui/material';
import GlobalAppContext from '../../context';
import { useTheme } from '@mui/material/styles';
import { styles } from '../styles/paginatedResolveTimeStyles';

const PaginatedResolveTime = ({data}) => {
  const theme = useTheme();
  const { namespace  } = useContext(GlobalAppContext);
  const style = styles(theme);
  const [paginationCount,] = useState(parseInt(data.length / 10));
  const [pageData, setPageData] = useState([]);

  useEffect(() => {
    setPageData(data.slice(0, 10));
    // eslint-disable-next-line
  }, []);

  const handlePageChange = (event, value) => {
    const start = (value - 1) * 10;
    const end = start + 10;
    setPageData(data.slice(start, end));
  }

  return (
    <Container sx={style.resolveContainer}>
        <Typography sx={style.title}>
          {namespace.dashboard.averageResolveTime}
        </Typography>
        <Table sx={style.tableContainer}>
          <TableBody sx={style.tableBody}>
              {pageData.map((item, index) => (
                <TableRow key={index} sx={[style.tableRow, style.rowColor(index)]} >
                  <TableCell sx={style.labelCell}>{index + 1}. {item.label}</TableCell>
                  <TableCell sx={style.percentageCell}>
                    <Container sx={style.percentageContainer(item.percentage)} />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {data.length > 10 && pageData.length > 1
            && <Pagination 
                count={paginationCount}
                variant="outlined"
                shape="rounded"
                sx={style.pagination}
                onChange={(e, page) => handlePageChange(e, page)}
              />
          }
    </Container>
  )
}

export default PaginatedResolveTime;