import React from "react";
import { Container } from "@mui/material";
import { LoadingIndicatorComponent } from "./components/loadingIndicator";
import { styles } from "./styles/loadingIcon";
import { useTheme } from "@mui/material/styles";
export const LoadingIcon = (props) => {
  const theme = useTheme();
  const style = styles(theme);

  return (
    <Container sx={style.loadingIcon(props.page)}>
      <LoadingIndicatorComponent />
    </Container>
  );
};
