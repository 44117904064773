export const styles = (theme) => {
  return {
    detailViewHeaderContainer: {
      margin: "0px",
      paddingTop: "30px",
      paddingBottom: "30px",
      minWidth: "100%",
      borderBottom: `1px solid ${theme.palette.custom.lightGray} !important`,
    },

    detailViewHeaderRightButton: (color) => {
      let backgroundColor;
      let textColor;
      if (color === "yellow") {
        backgroundColor = theme.palette.custom.yellow;
        textColor = theme.palette.custom.black;
      }
      if (color === "darkYellow") {
        backgroundColor = theme.palette.custom.darkYellow;
        textColor = theme.palette.custom.black;
      }
      return {
        margin: "0px",
        borderRadius: "8px",
        height: "50px",
        paddingRight: "20px",
        paddingLeft: "20px",
        fontSize: "20px",
        fontWeight: "600",
        fontFamily: "Nunito Sans",
        paddingTop: "auto",
        paddingBottom: "auto",
        backgroundColor: backgroundColor,
        color: textColor,
        "&:hover": {
          backgroundColor: backgroundColor,
          color: textColor,
        },
      };
    },

    detailViewHeaderRightButtonAfterIcon: {
      marginLeft: "12px",
      marginTop: "5px",
      marginBottom: "-5px",
    },

    detailViewHeaderActionsContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      width: "calc(100% - 120px)",
      margin: "0px",
      marginLeft: "60px",
      marginTop: "20px",
      marginBottom: "40px",
    },

    detailViewHeaderRightButtonAfterContainer: {
      display: "flex",
      flexDirection: "row",
      width: "auto",
    },

    detailViewHeaderRightButtonAfterProgress: {
      margin: "0px",
      marginTop: "7px",
      marginRight: "10px",
    },

    breadcrumbReplacement: {
      fontSize: "20px",
      fontWeight: "400",
      fontFamily: "Nunito Sans",
      color: "#0082E1",
      marginTop: "60px",
      marginBottom: "20px",
      marginLeft: "60px",
    },
  };
};
