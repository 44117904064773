import {
    DashboardIcon,
    DistrictsIcon,
    SchoolsIcon,
    UsersIcon,
    StudentsIcon,
    IncidentsIcon,
    HelpIcon,
    LogoutIcon  
} from '../feature/core/customIcons';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';


/**
 * This retrieves all nagivation items the user has access to.
 * @param {*} user The user whose persmission needs to be checked against the access levels.
 * @returns This returns all the navigation items the user has access to in a formatted list (rendered).
 */
export const generateNavigationList = (user, location) => {
    const path = location.pathname.split('/');
    const navigationItems = [];
    const navigationItemsBottom = [];
    const items = getNavigationItems(user, path);
    items.forEach((item) => {
        const navItem = (
            <Link key={item.name} className={
                (path[1] === item.name.toLowerCase()) || (path[1] === 'help' && item.name === 'Help & Support')
                ? 'selected' : 'not-selected'
            } to={item.navigateTo}>
                <ListItem key={item.name} disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.name} />
                    </ListItemButton>
                </ListItem>
            </Link>
        );
        if (item.list === 'top') {
            navigationItems.push(navItem);
        } else {
            navigationItemsBottom.push(navItem);
        };
    });
    return [navigationItems, navigationItemsBottom];
};

/**
 * This retrieves all nagivation items the user has access to.
 * @param {any} user The user whose persmission needs to be checked against the access levels.
 * @returns This returns all the navigation items the user has access to.
 */
const getNavigationItems = (user, path) => {
    const permission = user?.permission;
    const navList = [];
    if (permission === 'disclosureAdmin' || permission === 'districtAdmin' || permission === 'schoolAdmin' || permission === 'responder') {
        let color = path[1] === 'dashboard' ? '#0082E1' : 'black';
        navList.push({ name: 'Dashboard', navigateTo: '/dashboard', icon: <DashboardIcon color={color} />, selected: false, list: 'top' })
    }
    if (permission === 'disclosureAdmin') {
        let color = path[1] === 'districts' ? '#0082E1' : 'black';
        navList.push({ name: 'Districts', navigateTo: '/districts', icon: <DistrictsIcon color={color} />, selected: false, list: 'top' });
    }
    if (permission === 'disclosureAdmin' || permission === 'districtAdmin') {
        let color = path[1] === 'schools' ? '#0082E1' : 'black';
        navList.push({ name: 'Schools', navigateTo: '/schools', icon: <SchoolsIcon color={color} />, selected: false, list: 'top' });
    }
    if (permission === 'disclosureAdmin' || permission === 'districtAdmin' || permission === 'schoolAdmin') {
        let color = path[1] === 'responders' ? '#0082E1' : 'black';
        navList.push({ name: 'Responders', navigateTo: '/responders', icon: <UsersIcon color={color} />, selected: false, list: 'top' });
    }
    if (permission === 'disclosureAdmin') {
        let color = path[1] === 'superadmins' ? '#0082E1' : 'black';
        navList.push({ name: 'Super Admins', navigateTo: '/superadmins', icon: <UsersIcon color={color} />, selected: false, list: 'top' })
    }
    if (permission === 'disclosureAdmin' || permission === 'districtAdmin' || permission === 'schoolAdmin' || permission === 'responder') {
        navList.push({ name: 'Students', navigateTo: '/students', icon: <StudentsIcon color={path[1] === 'students'  ? '#0082E1' : 'black'} />, selected: false, list: 'top' });
        navList.push({ name: 'Incidents', navigateTo: '/incidents', icon: <IncidentsIcon color={path[1] === 'incidents'  ? '#0082E1' : 'black'} />, selected: false, list: 'top' });
    }
    navList.push({ name: 'Help & Support', navigateTo: '/help', icon: <HelpIcon color={path[1] === 'help'  ? '#0082E1' : 'black'} />, selected: false, list: 'bottom' });
    navList.push({ name: 'Logout', navigateTo: '/auth/logout', icon: <LogoutIcon />, selected: false, list: 'bottom' });

    return navList;
};