import { useContext } from 'react';
import { Container, Typography} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styles } from '../styles/filterStyles';
import FilterIcon from '../assets/filterIcon.svg';

import GlobalAppContext from '../../context';
import { useTheme } from '@mui/material/styles';
import ApiSelect from '../../core/components/apiSelect';

const DashboardFilter = ({filterValues, setFilterValues}) => {
  const theme = useTheme();
  const { namespace  } = useContext(GlobalAppContext);
  const style = styles(theme);
  const user = JSON.parse(localStorage.getItem('user'));

  const handleFilterChange = (fieldVar, finalValue, label) => {
    filterValues[fieldVar] = finalValue
    setFilterValues({...filterValues})
  };

  const handleDateChange = (type, value) => {
    filterValues[type] = value
    setFilterValues({...filterValues})
  };
  const districtFilterConfig = {
    title: "District",
    field: "district",
    labelField: "districtName",
    type: "apiSelect",
    returnType: "string",
    apiConfig: {
      apiBody: {},
      label: "District",
      dataType: "districts",
    },
  }
  const schoolFilterConfig = {
    title: "School",
    field: "school",
    labelField: "schoolName",
    type: "apiSelect",
    returnType: "string",
    apiConfig: {
      apiBody: {filters: [{ key: "district", value: "{district}", expression: "=", valueObj: "filterValues" }]},
      dataType: "schools",
      label: "School",
      requires: ["district"],
    },
  }

  return (
    <Container sx={style.filterContainer}>
      <Container sx={style.leftContainer}>
        <img src={FilterIcon} alt='filter-icon' />
        <Typography sx={style.title}>{namespace.dashboard.filter}:</Typography>
        {user.permission === "disclosureAdmin" &&
          <ApiSelect
            filterValues={filterValues}
            handleFilterChange={handleFilterChange}
            config={districtFilterConfig}
          />
        }
        {(user.permission === "disclosureAdmin" || user.permission === "districtAdmin") &&
          <ApiSelect
            filterValues={filterValues}
            handleFilterChange={handleFilterChange}
            config={schoolFilterConfig}
          />
        }
      </Container>
      <Container sx={style.rightContainer}>
        <Container sx={style.startDate}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={filterValues.startDate}
              onChange={(newValue) => handleDateChange("startDate", newValue)}
            />
          </LocalizationProvider>
        </Container>
        <Container sx={style.endDate}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End Date"
              value={filterValues.endDate}
              onChange={(newValue) => handleDateChange("endDate", newValue)}
            />
          </LocalizationProvider>
        </Container>
      </Container>
    </Container>
  )
}

export default DashboardFilter;