import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container } from "@mui/material";
import DataTableMD from "../../core/dataTableMD";
import {
  getDistrictSchoolsColumns,
} from "../configs/districtsSchoolDataConfig";

const SchoolsTab = (props) => {
  const { state } = useLocation();
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    const getFilters = async () => {
      const columns = await getDistrictSchoolsColumns(state);
      setColumns(columns);
    };
    getFilters();
    // eslint-disable-next-line
  }, []);
  const addSuccessMessage =
    "The new school has been successfully added to the system. ";

  return (
    <Container>
      <DataTableMD
        title="Schools"
        dbTable={"schools"}
        filters={{}}
        columns={columns}
        addButton={true}
        addSuccessMessage={addSuccessMessage}
        sort={{ field: "firstName", order: "asc" }}
        isTab={true}
        apiBody={{
          filters: [{ key: "district", value: state.id, expression: "=" }]
        }}
      />
    </Container>
  );
};

export default SchoolsTab;
