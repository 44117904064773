import TranslationNamespaces from "../translation-namespaces";

// TODO: This is just a custom sample of what we can do
// but we want to extend MUI(s) locale as well as move our
// custom locale to the BE and call it one load maybe
const enUS = {
  [TranslationNamespaces.Dashboard]: {
    overview: "Overview",
    resolveTimes: "Resolve Times",
    studentOverview: "Student Overview",
    filter: "Filter",
    allDistricts: "All Districts",
    districts: "Districts",
    allSchools: "All Schools",
    schools: "Schools",
    incidentsOverTime: "Active Incidents Over Time",
    compareSchools: "Compare Schools",
    activeIncidentPriorityLevels: "Active Incident Priority Levels",
    lowPriority: "Low Priority",
    mediumPriority: "Medium Priority",
    highPriority: "High Priority",
    averageResolveTimeInDays: "Average Resolve Time in Days",
    reportBreakdown: "Report Breakdown",
    averageResolveTime: "Average Resolve Time",
    averageNumberOfIncidentsPerStudent:
      "Average Number of Incidents per Student",
  },
  AddaNew: "Add a New",
  district: "District",
  school: "School",
  student: "Student",
  user: "User",
  cancel: "Cancel",
  close: "Close",
  add: "Add",
  selectDistricts: "Select Districts",
  role: "Role",
  addedSuccessfully: "Added Successfully",
  districtSuccessMessage:
    "The new district has been successfully added to the system.",
  schoolSuccessMessage:
    "The new school has been successfully added to the system.",
  userSuccessMessage: "The new user has been successfully added to the system.",
  studentSuccessMessage:
    "The new user has been successfully added to the system. The student information will stay anonymous unless determinate users need to de-anonymize the information temporally.",
  districtName: "District Name",
  superintendent: "Superintendent",
  email: "email",
  phoneNumber: "Phone Number",
  schoolName: "School Name",
  principal: "Principal",
  address: "Address",
  name: "Name",
  lastName: "Last Name",
};

export default enUS;
