export const getAvailabilityOptions = async () => {
    const availabilityOptions = [
        {
            label: "Available",
            id: "Available"
        }, {
            label: "Out of Office",
            id: "Out of Office"
        }
    ]
    return availabilityOptions;
}

export const getPermissionOptions = async (level) => {
    const permissionOptions = []
    if (level === "responder") {
        permissionOptions.push({
            label: "Responder",
            value: "responder"
        })
    }
    if (level === "schoolAdmin" || level === "responder") {
        permissionOptions.push({
            label: "School Admin",
            value: "schoolAdmin"
        })
    }
    if (level === "districtAdmin" || level === "schoolAdmin") {
        permissionOptions.push({
            label: "District Admin",
            value: "districtAdmin"
        })
    }
    if (level === "disclosureAdmin") {
        permissionOptions.push({
            label: "Super Admin",
            value: "disclosureAdmin"
        })
    }
    return permissionOptions;
}
