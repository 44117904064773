import React from "react";
import { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { styles } from "../styles/apiSelect";
import { useTheme } from "@mui/material/styles";
import { useLocation } from 'react-router-dom';

import { api } from "../../../api";
import { getTitleString, getFieldVariables } from "../../../utils/dataUtils";

const ApiSelect = ({
    filterValues,
    handleFilterChange,
    config,
    thisValue,
    width,
    fieldConfig,
    multiple,
}) => {
    const { state } = useLocation();
    const apiConfig = config.apiConfig;
    const checkIfNotNull = (value) => {
        if (value === null || value === undefined || value === "" || (Array.isArray(value) && value.length === 0)) {
            return false;
        }
        return true;
    }

    const checkRequiredFilters = (filters) => {
        for (let filter in filters) {
            if (!checkIfNotNull(filterValues[filters[filter]])) {
                return false;
            }
        }
        return true;
    }
    const [options, setOptions] = useState(thisValue ? (multiple ? thisValue : [thisValue]) : []);
    const [disabled, setDisabled] = useState(true);
    const [passRequired, setPassRequired] = useState(apiConfig.requires ? checkRequiredFilters(apiConfig.requires) : true);
    const [loaded, setLoaded] = useState(false);
    const [thisLoading, setThisLoading] = useState(false);
    const [value, setValue] = useState(thisValue ? thisValue : null);
    const [lastRequiresValues, setLastRequiresValues] = useState({});
    const [placeholder, setPlaceholder] = useState("");
    const [dynamicWidth, ] = useState(width? false : true);
    const [thisWidth, setThisWidth] = useState(width ? width : "200px");
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);

    const theme = useTheme();
    const style = styles(theme, thisWidth);

    const fetchData = async (thisPassRequired) => {
        if (!thisLoading && thisPassRequired){
            if (!thisValue && !lastEvaluatedKey) {
                setPlaceholder("Loading...");
            }
            const tempOptions = [...options];
            const beforeOptions = [...options];
            if (lastEvaluatedKey) {
                tempOptions.push({id: "loading more...", label: "loading more...", disabled: true});
                setOptions(tempOptions);
            }
            setThisLoading(true);
            const finalApiBody = JSON.parse(JSON.stringify(apiConfig.apiBody));
            if (finalApiBody.filters && finalApiBody.filters.length > 0){
                for (let filter in finalApiBody.filters){
                    const thisFilter = finalApiBody.filters[filter].value;
                    let fieldVariableObj = null;
                    if (finalApiBody.filters[filter].valueObj){
                        if (finalApiBody.filters[filter].valueObj === "state") {
                            fieldVariableObj = state;
                        } else if (finalApiBody.filters[filter].valueObj === "filterValues") {
                            fieldVariableObj = filterValues;
                        }
                    }
                    delete finalApiBody.filters[filter].valueObj;
                    let convertedfieldValue = thisFilter;
                    if (fieldVariableObj){
                        convertedfieldValue = await getFieldVariables(thisFilter, fieldVariableObj);
                    }
                    finalApiBody.filters[filter].value = convertedfieldValue
                }
            }
            finalApiBody.filters = JSON.stringify(finalApiBody.filters);
            finalApiBody.pageSize = 10;
            if (lastEvaluatedKey) {
                finalApiBody.pageKey = JSON.stringify(lastEvaluatedKey);
            }   
            const response = await api("get", finalApiBody, apiConfig.dataType);
            if (response.status === 200) {
                setLastEvaluatedKey(response.data.LastEvaluatedKey ? response.data.LastEvaluatedKey : null);
                const finalOptions = []
                for (let item in response.data.data) {
                    let labelValue = response.data.data[item].name;
                    if (!response.data.data[item].name){
                        labelValue = response.data.data[item].firstName + " " + response.data.data[item].lastName;
                    }
                    const tempOption = {
                        id: response.data.data[item].id,
                        label: labelValue
                    };
                    finalOptions.push(tempOption);
                }
                if (!thisValue) {
                    setPlaceholder(`Select A ${getTitleString(apiConfig.dataType)}`);
                    setValue(null);
                }
                if (apiConfig.addedOptions) {
                    for (let option in apiConfig.addedOptions) {
                        finalOptions.push(apiConfig.addedOptions[option]);
                    }
                }
                if (lastEvaluatedKey) {
                    setOptions([...beforeOptions, ...finalOptions]);
                } else {
                    setOptions([...finalOptions]);
                }
            }
            setThisLoading(false);
            setLoaded(true);
        } else if (!passRequired) {
            const placeholderText = "Select Other Filters First...";
            setPlaceholder(placeholderText);
            const newWidth = placeholderText.length * 9;
            if (dynamicWidth) {
                setThisWidth(newWidth);
            }
        }
    }

    const handleChange = async (value) => {
        let finalValue = null;
        if (value) {
            if (!multiple){
                finalValue = value.id;
                const newWidth = value.label.length * 12;
                if (dynamicWidth) {
                    setThisWidth(newWidth);
                }
            } else {
                finalValue = [];
                for (let item in value) {
                    finalValue.push(value[item].id);
                }
            }
        }
        if (config.returnType === "list") {
            if (value) {  
                finalValue = [value.id];
            } else{
                finalValue = [];
            }
        }
        let fieldVar = config.field
        if (fieldConfig){
            fieldVar = fieldConfig;
        }
        let label = ""
        if (!multiple){
            for (let option in options) {
                if (options[option].id === finalValue) {
                    label = options[option].label;
                }
            }
        } else {
            label = [];
            for (let item in finalValue) {
                for (let option in options) {
                    if (options[option].id === finalValue[item]) {
                        label.push(options[option].label);
                    }
                }
            }
        }
        await handleFilterChange(fieldVar, finalValue, label);
        setValue(value);
    };

    useEffect(() => {
        const runFunction = async () => { 
            if (apiConfig.requires && apiConfig.requires.length > 0) {
                const tempLastRequiresValues = {};
                for (let require in apiConfig.requires) {
                    const filterField = apiConfig.requires[require];
                    if (filterValues[filterField] !== lastRequiresValues[filterField]) {
                        if (checkIfNotNull(filterValues[filterField])) {
                            setPassRequired(true);
                            setDisabled(false);
                            const placeholderText = "Loading...";
                            setPlaceholder(placeholderText);
                            setValue(null);
                            setLastRequiresValues(tempLastRequiresValues);
                            fetchData(true);
                        } else {
                            setPassRequired(false);
                            setDisabled(true);
                            const placeholderText = "Select Other Filters First...";
                            setValue(null);
                            setPlaceholder(placeholderText);
                            await handleFilterChange(filterField, null);
                            setLastRequiresValues(tempLastRequiresValues);
                        }
                    }
                    tempLastRequiresValues[filterField] = filterValues[filterField];
                }
            }
        }
        runFunction();
        // eslint-disable-next-line
    }, [filterValues]);

    useEffect(() => {
        const fetchDataFunc = async () => {
            await fetchData(passRequired);
        };
        if (!thisLoading) {
            fetchDataFunc();
        }
        // eslint-disable-next-line
    } , []);

    useEffect(() => {
        const fetchDataFunc = async () => {
            await fetchData(passRequired);
        };
        if (passRequired && loaded) {
            fetchDataFunc();
        }
        // eslint-disable-next-line
    } , [passRequired]);

    useEffect(() => {
        if ((thisLoading || !passRequired) && !lastEvaluatedKey) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
        // eslint-disable-next-line
    } , [thisLoading]);

    const handleScroll = async (target) => {
        const bottom = target.scrollHeight - target.scrollTop <= target.clientHeight;
        if (bottom && lastEvaluatedKey) {
            if (!thisLoading) {
                await fetchData(passRequired);
            }
        }

    }

    // const handleSearch = async (e) => {
        // console.log("searching", e.target.value);
    // }

    const renderInput = (params) => {
        return (<TextField {...params}
            label={getTitleString(apiConfig.label)}
            key={params.id}
            InputLabelProps={{shrink: true,}}
            placeholder={placeholder}/>)
    };

  return (
    <Autocomplete
        disablePortal
        multiple={multiple}
        getOptionLabel={(option) => option.label || "error"}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={options}
        value={value}
        onChange={(e, value) => handleChange(value)}
        sx={style.autoCompleteSelect}
        renderInput={(params) => renderInput(params)}
        disabled={disabled}
        fullWidth = {true}
        size="small"
        ListboxProps={{
            onScroll: (event) => handleScroll(event.target),
            sx: style.listBox
        }}
        // onInputChange={(e) => handleSearch(e)}
    />
  );
};

export default ApiSelect;
