export const breakdownConfig = [
    {
        field: "incidentTypeBreakdown",
        title: "Incident Type",
        colors: ['#09305F', '#09305F80', '#09305F60', '#09305F40']
    },
    {
        field: "incidentCategoryBreakdown",
        title: "Category Type",
        colors: ['#1890FF', '#1890FF80', '#1890FF60', '#1890FF40']
    },
    {
        field: "incidentPersonBreakdown",
        title: "Person Involved",
        colors: ['#5ADFFC', '#5ADFFC80', '#5ADFFC60', '#5ADFFC40']
    }
]