export const localGet = async (name, json=false) => {
    if (json) {
        return await JSON.parse(localStorage.getItem(name));
    }
    return localStorage.getItem(name);
}

export const localSet = async (name, value, json=false) => {
    if (json) {
        localStorage.setItem(name, JSON.stringify(value));
    } else {
        localStorage.setItem(name, value);
    }
}