import { populateTheme } from "../utils/themeUtils";

const colors = {
  primary: {
    main: "#e6743a",
  },
  secondary: {
    main: "#333333",
  },
  error: {
    main: "#f44336",
  },
  warning: {
    main: "#ff9800",
  },
  info: {
    main: "#2196f3",
  },
  success: {
    main: "#4caf50",
  },
  white: "#ffffff",
  borderColor: "#D3D3D3",
};

const theme = populateTheme(colors);

export default theme;
