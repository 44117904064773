export const styles = (theme) => ({
  detailViewContainerModal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "30%",
    height: "auto",
    backgroundColor: "white",
    padding: "0px !important",
    margin: "0px",
    position: "absolute",
    top: "20%",
    left: "35%",
    outline: "none",
  },
  detailViewContainerModalHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "10px",
    paddingTop: "20px",
    paddingBottom: "20px",
    borderBottom: `1px solid ${theme.palette.custom.borderColor}`,
    color: `${theme.palette.custom.darkGray}`,
  },
  detailViewContainerModalHeaderTitle: {
    fontSize: "20px",
    fontWeight: "700",
    color: `${theme.palette.custom.darkGray}`,
  },
  detailViewContainerModalHeaderClose: {
    fontSize: "20px",
    cursor: "pointer",
    color: `${theme.palette.custom.darkGray}`,
  },
  detailViewContainerModalBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxHeight: "400px",
    height: "auto",
    flexGrow: "2",
    width: "100%",
    padding: "10px",
    paddingTop: "20px",
    paddingBottom: "20px",
    color: `${theme.palette.custom.darkGray}`,
  },
  detailViewContainerModalBodyLabel: {
    fontSize: "16px",
    fontWeight: "400",
    color: `${theme.palette.custom.darkGray}`,
  },
  detailViewContainerModalBodyTextArea: {
    width: "100%",
    height: "300px",
    marginTop: "10px",
    marginBottom: "10px",
    padding: "0px !important",
    borderRadius: "5px",
    border: `1px solid ${theme.palette.custom.borderColor}`,
    color: `${theme.palette.custom.darkGray}`,
    backgroundColor: ` ${theme.palette.custom.borderColor}`,
    overflow: "scroll",
    maxHeight: "300px !important",
    "& .MuiOutlinedInput-root": {
      padding: "0px !important",
      backgroundColor: `${theme.palette.custom.borderColor}`,
    },
    "& .MuiOutlinedInput-input": {
      color: `${theme.palette.custom.gray}`,
      border: "none !important",
      fontSize: "18px !important",
      fontWeight: "600 !important",
      fontFamily: "Nunito Sans",
      paddingLeft: "20px !important",
      overflow: "scroll",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    "&.Mui-focused .MuiOutlinedInput-input": {
      padding: "0px !important",
    },
  },
  detailViewContainerModalFooter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    borderTop: `1px solid ${theme.palette.custom.borderColor}`,
    margin: "0px",
    height: "90px",
  },
  detailViewContainerModalFooterCancelButton: {
    width: "auto",
    margin: "0px",
    fontSize: "16px",
    fontWeight: "400",
    cursor: "pointer",
    color: `${theme.palette.custom.darkRed}`,
  },

  detailViewContainerModalFooterSubmitButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "0px",
    paddingTop: "10px",
    paddingBottom: "10px",
    borderRadius: "4px",
    width: "auto",
    fontSize: "16px",
    fontWeight: "400",
    cursor: "pointer",
    color: `${theme.palette.custom.white}`,
    backgroundColor: `${theme.palette.primary.main}`,
  },
  detailViewContainerModalFooterSubmitProgress: {
    width: "15px !important",
    height: "15px !important",
    color: `${theme.palette.custom.white}`,
    marginLeft: "10px",
  },
});
