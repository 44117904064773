import { httpEvent } from '../utils/utilsApi';

/**
 * This is the main API function for the application.
 * This function will handle all API calls for the application.
 * @param {string} apiType - The type of API call to make. options are: 'auth', 'get', 'post', 'patch', 'delete'
 * @param {object} data - The data to send to the API
 * @param {string} type - The type of API call to make. options are: 'incidents', 'users', 'students', 'schools', 'districts', 'comments', 'paidTimeOffs'
 *                capitalization and pluralization does not matter incidents, Incident, INCIDENT, Incidents are all valid
 *
 * @returns {object} - The response from the API
 */
export const api = async (apiType, data, type) => {
    const apiName = apiType === 'auth' ? 'auth' : 'crud';
    let method = 'POST';    // This can be refactored to be apiType.toUpperCase()
    switch (apiType) {
        case 'delete':
            method = 'DELETE';
            break;
        case 'get':
            method = 'GET';
            break;
        case 'patch':
            method = 'Patch';
            break;
        default:
            method = 'POST';
            break;
    }
    return await httpEvent(apiName, method, type, data)
}