import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DetailsView from "../core/detailsView";

import { getDetailViewConfig } from "./configs/incidentsDetailViewConfig";

const IncidentPage = () => {
  const { state } = useLocation();
  const [detailViewConfig, setDetailViewConfig] = useState({});
  const [data, setData] = useState(state);

  useEffect(() => {
    const fetchData = async () => {
      const detailViewConfig = await getDetailViewConfig(state);
      setDetailViewConfig(detailViewConfig);
    };
    fetchData();
  }, []);

  useEffect(() => {
    setData({ ...state });
  }, [state]);

  return (
    <>
      {detailViewConfig?.type ? (
        <DetailsView
          data={data}
          titleConfig={detailViewConfig.titleConfig}
          type={detailViewConfig.type}
          title={detailViewConfig.title}
          tabs={detailViewConfig.tabs}
          mainAction={detailViewConfig.mainAction}
          actionItems={detailViewConfig.actionItems}
          headerConfig={detailViewConfig.headerConfig}
        />
      ) : (
        <div></div>
      )}
    </>
  );
};

export default IncidentPage;
