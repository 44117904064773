import { Container, Typography } from "@mui/material";
import { styles } from "../styles/activityStyles";
import { useTheme } from "@mui/material/styles";
import DblChevronRight from "../assets/dbl-chevron-right.svg";

const ActivityCard = ({ config, data }) => {
  const theme = useTheme();
  const style = styles(theme);

  return (
    <Container sx={style.activityContainer}>
      {config.map((item, index) => {
        return (
          <Container key={index} sx={style.activityCard}>
            <Container sx={style.row1}>
              <Typography sx={style.title}>
                {item.name}
              </Typography>
              <Typography sx={style.chevronRight}>
                <img src={DblChevronRight} alt="chevron-right" />
              </Typography>
            </Container>
            <Container sx={style.row2}>
              <Container sx={style.dataContainer}>
                <img src={item.icon} alt="icon"/>
                <Typography sx={style.data}>
                  {data[item.field]}
                </Typography>
              </Container>
              <Typography sx={style.activityCardMessage}>
                {item.message}
              </Typography>
            </Container>
          </Container>
        );
      })}
    </Container>
  );
};

export default ActivityCard;
