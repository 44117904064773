import React from "react";
import { Typography, Button, Modal, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styles } from "../../styles/modal/bodyTableSuccessModal";

const BodyTableSuccessModal = ({ title, contentBody, open, handleClose }) => {
  const theme = useTheme();
  const style = styles(theme);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style.bodyTableSuccessModelContainer}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={style.bodyTableSuccessModelHeading}
        >
          {title}
        </Typography>
        <Typography sx={style.bodyTableSuccessModelBody}>
          {contentBody}
        </Typography>
        <Box sx={style.bodyTableSuccessModelFooterContainer}>
          <>
            <Button
              variant="contained"
              sx={style.bodyTableSuccessModelCloseButton}
              onClick={handleClose}
            >
              Close
            </Button>
          </>
        </Box>
      </Box>
    </Modal>
  );
};

export default BodyTableSuccessModal;
