import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/material";
import { styles } from "./styles/dataTableMD/dataTableMD.jsx";
import { useTheme } from "@mui/material/styles";
import DataGridHeader from "./components/dataTableMD/dataTableHeader.js";
import DataTableFilters from "./components/dataTableMD/dataTableFilters.js";
import DataGridFooter from "./components/dataTableMD/dataTableFooter.js";
import DataTable from "./components/dataTableMD/dataTable.js";
import { api } from "../../api/index";
import { LoadingIcon } from "./loadingIcon.js";
import { WebSocketContext } from "../context/websocketsContext.js";

const DataTableMD = ({
  title,
  dbTable,
  filters,
  toggles,
  columns,
  addButton,
  addSuccessMessage,
  sort,
  isTab,
  apiBody,
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [apiCalls, setApiCalls] = useState({});
  const [apiCallCount, setApiCallCount] = useState(0);
  const [activeToggles, setActiveToggles] = useState(toggles);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageSizes, setPageSizes] = useState([pageSize, 25, 50, 100, 200]);
  const [totalPages, setTotalPages] = useState(0);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [subscribe, unsubscribe] = useContext(WebSocketContext);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState("");
  const [totalCount, setTotalCount] = useState(0);

  const theme = useTheme();
  const style = styles(theme);

  useEffect(() => {
    for (let call in apiCalls){
      if (apiCalls[call].count < apiCallCount){
        const tempApiCalls = apiCalls;
        tempApiCalls[call].val = false;
        setApiCalls({...tempApiCalls});
      }
    }
    // eslint-disable-next-line
  }, [apiCallCount]);

  const fetchData = async () => {
    setTotalCount(0);
    setData([]);
    const apiCallId = (Math.random()*16).toString(16);
    const tempApiCalls = apiCalls;
    if (loading){
      tempApiCalls[apiCallId] = {count: apiCallCount + 1, val: true};
      setApiCallCount(apiCallCount + 1);
    } else {
      tempApiCalls[apiCallId] = {count: 0, val: true};;
      setApiCallCount(0);
    }
    setApiCalls({...tempApiCalls});
    setLoading(true);
    setLoaded(false);
    let currentApiBody = {};
    if (apiBody){
      currentApiBody = JSON.parse(JSON.stringify(apiBody));
    }
    currentApiBody.pageSize = pageSize;
    if (lastEvaluatedKey) {
      if (Object.keys(filterData).length > 0) {
        setLastEvaluatedKey(null);
      } else {
        currentApiBody.pageKey = lastEvaluatedKey;
      }
    }
    for (let field in filterData) {
      const tempFilterDict = {
        key: field,
        value: filterData[field],
        expression: "=",
      };
      if (!currentApiBody.filters) {
        currentApiBody.filters = [];
      }
      currentApiBody.filters.push(tempFilterDict);
    }
    const finalApiBody = {...currentApiBody};
    if (currentApiBody.filters){
      finalApiBody.filters = JSON.stringify(currentApiBody.filters);
    }
    try {
      const response = await api("get", finalApiBody, dbTable);

      if (response.status === 200) {
        if (tempApiCalls[apiCallId].val){
          let tempLastEvaluatedKey = null;
          if (response.data.LastEvaluatedKey) {
            tempLastEvaluatedKey = JSON.stringify(response.data.LastEvaluatedKey);
            setLastEvaluatedKey(tempLastEvaluatedKey);
          } else {
            setLastEvaluatedKey(null);
          }

          let tempData = response.data.data;
          if (tempLastEvaluatedKey) {
            tempData = [...data, ...response.data.data];
          }
          setData([...tempData]);
          setCurrentPageData([...tempData]);
          setTotalCount(response.data.TotalCount);
        }
      }
    } catch (error) {
    } finally {
      if (tempApiCalls[apiCallId].val){
        setLoaded(true);
        setLoading(false);
      }
    }
    const tempApiCalls2 = apiCalls;
    delete tempApiCalls2[apiCallId];
    setApiCalls({...tempApiCalls});
  };

  useEffect(() => {
    const fetchDataFunc = async () => {
      if (!loading) {
        await fetchData()
      }
    }
    if (
      (page === totalPages - 1 && lastEvaluatedKey) ||
      (lastEvaluatedKey && pageSize > 10)
    ) {
      fetchDataFunc();
    }
    // eslint-disable-next-line
  }, [page, pageSize]);

  useEffect(() => {
    subscribe(dbTable, (messageData) => {
      const tempData = [...data];
      if (messageData.command === "newIncident") {
        const incidentData = messageData.incident;
        tempData.push(incidentData);
      } else if (messageData.command === "updateIncident") {
        const incidentData = messageData.incident;
        const index = tempData.findIndex(
          (record) => record.id === incidentData.id
        );
        if (index !== -1) {
          const tempIncident = tempData[index];
          for (let field in incidentData) {
            if (field !== "id") {
              tempIncident[field] = incidentData[field];
            }
          }
          tempData[index] = tempIncident;
        }
      }
      setData(tempData);
    });
    return () => {
      unsubscribe(dbTable);
    };
  }, [data, subscribe, unsubscribe, dbTable]);

  const processFilter = async (field, values) => {
    const tempFilterData = filterData;
    if (field && (values || values === null)) {
      if (!Array.isArray(values)) {
        if (values){
          values = [values];
        } else {
          values = [];
        }
      }
      if (activeToggles) {
        if (activeToggles.field === field) {
          const tempActiveToggles = activeToggles;
          tempActiveToggles.active = values;
          setActiveToggles(tempActiveToggles);
        }
      }
      if (values.includes("All") || values.length === 0) {
        for (let filter in filters) {
          if (filters[filter].apiConfig && filters[filter].apiConfig.requires){
            if (filters[filter].apiConfig.requires.includes(field)){
              delete tempFilterData[filters[filter].field];
            }
          }
        }

        delete tempFilterData[field];
      } else {
        tempFilterData[field] = values;
      }
      setFilterData({...tempFilterData});
    }
  };

  const handleRowClick = async (id) => {
    const formattedTitle = title.replace(/s$/, "").replace(" ", "");
    const lowercaseTitle = formattedTitle.toLowerCase();
    const thisRecord = data.filter((record) => record.id === id);
    navigate(`/${lowercaseTitle}/${id}`, { state: thisRecord[0] });
  };

  useEffect(() => {
    processFilter();
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      const widthObject = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      setWindowSize({ ...widthObject });
    });
    const fetchDataFunc = async () => {
      if (!loading) {
        await fetchData()
      }
    }
    fetchDataFunc();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchDataFunc = async () => {
      if (loaded) {
        await fetchData()
      }
    }
    fetchDataFunc();
    // eslint-disable-next-line
  }, [filterData]);

  useEffect(() => {
    const cellHeight = 75;
    const pageSize = parseInt((windowSize.height - 400) / cellHeight);
    setPageSize(pageSize);
    setPageSizes([pageSize, 25, 50, 100, 200]);
    setPage(0);
  }, [windowSize]);

  useEffect(() => {
    let tempTotalPages = Math.ceil(data.length / pageSize);
    if (totalCount > data.length){
      tempTotalPages += 1
    }
    setTotalPages(tempTotalPages);
    if (data.length > pageSize && pageSize !== 0) {
      const startIndex = page * pageSize;
      setCurrentPageData([...data.slice(startIndex, startIndex + pageSize)]);
    } else {
      setCurrentPageData([...data]);
    }
    // eslint-disable-next-line
  }, [pageSize, data, page]);

  return (
    <Container sx={style.dataGridStyleContainer}>
      <DataGridHeader
        title={title}
        dbTable={dbTable}
        toggles={activeToggles}
        processFilter={processFilter}
        addButton={addButton}
        columns={columns}
        addSuccessMessage={addSuccessMessage}
        allData={data}
        setAllData={setData}
        isTab={isTab}
      />

      {filters?.length > 0 && (
        <DataTableFilters filters={filters} processFilter={processFilter} />
      )}
      {loaded ? (
          <DataTable
            data={currentPageData}
            fields={columns}
            handleRowClick={handleRowClick}
          />
      ) : (
        <Container sx={style.dataTableLoadingContainer}>
          <LoadingIcon page="dataTable" />
        </Container>
      )}
                <DataGridFooter
            page={page}
            pageSize={pageSize}
            pageSizes={pageSizes}
            setPage={setPage}
            setPageSize={setPageSize}
            totalPages={totalPages}
            totalRecords={totalCount}
            loadedRecords={data.length}
            title={title}
          />
    </Container>
  );
};

export default DataTableMD;
