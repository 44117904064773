import React, { useState } from "react";
import { Container, Typography, Button, Box } from "@mui/material";
import { AddIcon } from "../../customIcons";
import { styles } from "../../styles/dataTableMD/dataTableHeader";
import { useTheme } from "@mui/material/styles";
import AddModal from "../modal/modal";
import { api } from "../../../../api";
import { getTitleString } from "../../../../utils/dataUtils";

const DataGridHeader = ({
  title,
  dbTable,
  toggles,
  processFilter,
  addButton,
  columns,
  addSuccessMessage,
  allData,
  setAllData,
  isTab,
}) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const getInitValues = () => {
    const initValues = {};
    columns.forEach((column) => {
      if (column.showAdd) {
        if (column.field === "district" && user.permission !== "disclosureAdmin") {
          initValues[column.field] = user.district;
        } else if (column.field === "school" && user.permission !== "disclosureAdmin" && user.permission !== "districtAdmin") {
          initValues[column.field] = user.school;
        } else if (column.addDefaultValue) {
          initValues[column.field] = column.addDefaultValue;
        } else {
          initValues[column.field] = "";
        }
      }
    });
    return initValues;
  };
  const theme = useTheme();
  const style = styles(theme);

  const [submit, setSubmit] = useState(false);
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState(getInitValues());

  const handleOpen = () => {
    setOpen(true);
    setValues(getInitValues());
  };

  const handleClose = () => {
    setOpen(false);
    setSubmit(false);
  };

  const handleSubmit = async (values, setAdding) => {
    if (user.permission !== "disclosureAdmin") {
      values.district = user.district;
    }
    if (user.permission !== "disclosureAdmin" && user.permission !== "districtAdmin") {
      values.school = user.school;
    }
    setAdding(true);
    for (let value in values) {
      if (values[value] === "") {
        delete values[value];
      }
    }
    const results = await api("post", values, dbTable);
    if (results.status === 200) {
      setSubmit(true);
      setAdding(false);
      setAllData([...allData, results.data]);
    } else {
      setAdding(false);
    }
  };

  const handleToggleClick = async (toggleOption) => {
    await processFilter(toggles.field, [toggleOption]);
  };

  return (
    <Container
      sx={
        addButton
          ? isTab
            ? style.DataGridHeaderWithAddButton
            : style.DataGridHeaderWithOutAddButton
          : style.DataGridHeaderContainer
      }
    >
      {!isTab && (
        <Typography sx={style.DataGridHeaderTitle} variant="h4">
          {title}
        </Typography>
      )}
      <Box>
        {addButton && (
          <Button
            sx={{
              color: theme.palette.common.white,
            }}
            size="medium"
            variant="contained"
            onClick={() => handleOpen()}
            endIcon={<AddIcon />}
          >
            {`Add A New ${getTitleString(title)}`}
          </Button>
        )}

        {toggles &&
          toggles.options.map((toggleOption, index) => (
            <Button
              sx={[
                style.DataGridHeaderButton,
                toggles.active && toggles.active.includes(toggleOption)
                  ? style.DataGridHeaderButtonActive
                  : style.DataGridHeaderButtonInactive,
              ]}
              size="medium"
              variant="contained"
              key={index}
              onClick={(e) => handleToggleClick(toggleOption)}
            >
              {toggleOption}
            </Button>
          ))}
      </Box>
      {addButton && (
        <AddModal
          open={open}
          submit={submit}
          handleClose={handleClose}
          title={getTitleString(title)}
          handleSubmit={handleSubmit}
          columns={columns}
          addSuccessMessage={addSuccessMessage}
          values={values}
          setValues={setValues}
        />
      )}
    </Container>
  );
};

export default DataGridHeader;
