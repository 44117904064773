export const helpPageStyles = (theme) => {
  return {
    mdHelpCard: {
      backgroundColor: theme.palette.common.white,
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
    },

    mdHelpTitle: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.common.white,
      padding: "12px 49px 12px 49px",
      fontSize: "0.875rem",
      color: `${theme.palette.custom.gray}`,
    },

    mdHighlightText: {
      color: `${theme.palette.primary.main}`,
    },

    mdHelpText: {
      color: `${theme.palette.custom.black}`,
      fontSize: "1.5rem",
      fontWeight: "700",
      backgroundColor: `${theme.palette.custom.backgroundColor}`,
      padding: "32px 49px 32px 49px",
    },

    mdFreAsked: {
      padding: "12px 49px 12px 49px",
      fontSize: "1.375rem",
      fontWeight: "700",
      color: `${theme.palette.custom.black}`,
      gap: "32px",
      backgroundColor: theme.palette.common.white,
    },

    mdAccordionContainer: {
      boxShadow: "none",
      backgroundColor: `${theme.palette.custom.white}`,
      width: "58.125rem",
      "::before": {
        display: "none !important",
      },
    },

    mdAccordionSummary: {
      width: "58.125rem",
      margin: "12px 49px 12px 49px",
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },

    mdAccordionSummaryExpanded: {
      width: "58.125rem",
      margin: "12px 49px 12px 49px",
      backgroundColor: `${theme.palette.custom.gray}`,
      color: `${theme.palette.custom.white}`,
    },

    mdAccordionSummaryExpandedIcon: {
      fontSize: "45px",
      color: `${theme.palette.custom.white}`,
    },
    mdAccordionSummaryIcon: {
      fontSize: "45px",
      color: `${theme.palette.custom.gray}`,
    },

    mdAccordionContent: {
      width: "58.125rem",
      margin: "5px 0px 10px 80px",
    },

    mdAccordionContentHeading: {
      fontSize: "1.125rem",
      fontWeight: "700",
      color: `${theme.palette.custom.darkGray}`,
      marginBottom: "1rem;",
    },

    mdAccordionContentBody: {
      fontSize: "1.125rem",
      fontWeight: "500",
      color: `${theme.palette.custom.darkGray}`,
    },

    mdAccordionLine: {
      borderBottom: `${theme.palette.primary.light}`,
      width: "58.125rem",
      margin: "12px 49px 12px 49px",
    },

    mdContactText: {
      margin: "12px 49px 12px 49px",
      width: "90%",
      fontWeight: "600",
      fontSize: "1.25rem",
    },
  };
};
