export const styles = (theme) => ({
    studentInfoContainer: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "60px",
      marginRight: "60px",
      marginTop: "40px",
      width: "auto",
      paddingLeft: "0px !important",
      border: `1px solid ${theme.palette.custom.gray}`,
      borderRadius: "4px"
    },
    studentInfoHeader: {
        borderBottom: `1px solid ${theme.palette.custom.gray}`,
    },
    studentInfoTitle: {
        fontSize: "20px",
        fontWeight: "700",
        marginTop: "20px",
        marginBottom: "20px",
        marginLeft: "20px",
    }
});