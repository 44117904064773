
/**
 * This is the default headings for Schools.
 * field - This is the property name inside the payload related to the heading ie DB property name.
 * heading - This is the Title that will be displayed for the Values.
 * editable- true if field should be editable, false otherwise.
 */

export const schoolInfoConfig = [
    {
        field: 'districtName',
        heading: 'District',
        type: 'string',
        editable: false,
        column: 1
    },
    {
        field: 'principal',
        heading: 'Principal',
        type: 'string',
        editable: true,
        column: 1
    },
    {
        field: 'phone',
        heading: 'Phone Number',
        type: 'phone',
        editable: true,
        column: 1
    },
    {
        field: 'address',
        type: 'string',
        heading: 'Address',
        editable: true,
        column: 1
    }
]
