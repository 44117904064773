import React from "react";
import { Container, Typography } from "@mui/material";

import { styles } from "../../styles/detailsView/detailViewHeaderActions";
import { useTheme } from "@mui/material/styles";
import EditableContent from "../editableContent";
import EditableContentDefault from "../editableContentDefault";


const DetailViewHeaderActions = ({
  data,
  actionItems,
  type,
  updating,
  setUpdating,
}) => {
  const theme = useTheme();
  const style = styles(theme);
  return (
    <Container sx={style.detailViewHeaderActionsContainer}>
      {actionItems?.map((action, index) => (
        <Container
          sx={style.detailViewHeaderActionItemContainer}
          key={index}
        >
          <Typography variant="h6" sx={style.detailViewHeaderActionItemTitle}>
            {action.title}
          </Typography>
          {action.editable && !action.defaultToEditing && (
            <EditableContent
              action={action}
              data={data}
              type={type}
              updating={updating}
              setUpdating={setUpdating}
            />
          )}
          {action.editable && action.defaultToEditing && (
            <EditableContentDefault
              action={action}
              data={data}
              type={type}
              updating={updating}
              setUpdating={setUpdating}
            />
          )}
          {!action.editable && action.type === "text" && (
            <Container sx={style.detailViewHeaderActionItemData}>
              <Typography
                variant="subtitle1"
                sx={style.detailViewHeaderActionItemText}
              >
                {data[action.field]}
              </Typography>
            </Container>
          )}
        </Container>
      ))}
    </Container>
  );
};

export default DetailViewHeaderActions;
