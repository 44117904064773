import LogoIcon from '../../core/icons/logoIcon.svg';

/**
 * This is the default headings for Incidents.
 * field - This is the property name inside the payload related to the heading ie DB property name.
 * heading - This is the Title that will be displayed for the Values.
 * editable- true if field should be editable, false otherwise.
 */

export const incidentsHeadings = [
    {
        field: 'category',
        heading: 'Incident Description',
        editable: false,
        column: 1
    },
    {
        field: 'stressor',
        heading: 'Stressor',
        editable: false,
        column: 1
    },
    {
        field: 'perpetrator',
        heading: 'Involved Parties',
        editable: false,
        column: 1
    },
    {
        field: 'timeframe',
        heading: 'Incident Duration',
        editable: false,
        column: 1
    },
    {
        field: 'emergencyContext',
        heading: 'Safety Perception',
        editable: false,
        column: 2
    }, 
    {
        field: 'emotionalContext',
        heading: 'Emotional State',
        editable: false,
        column: 2
    },
    {
        field: 'description',
        heading: 'Additional Details',
        editable: false,
        column: 2
    },
    {
        field: 'messageStudent',
        type: 'button',
        buttonConfig: {
            icon: LogoIcon,
            title: 'Message the Student',
        },
        column: 2
    }
]