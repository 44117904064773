export const studentInfo = [
  {
    field: 'image',
    heading: 'Image',
    editable: false,
    column: 1,
    type: "image"
  },
  {
    field: '{firstName} {lastName}',
    heading: 'Full Name',
    editable: false,
    column: 2,
  },
  {
    field: 'year',
    heading: 'Year',
    editable: false,
    column: 1
  },
  {
    field: 'email',
    heading: 'Email Address',
    editable: false,
    column: 2
  },
  {
    field: 'schoolId',
    heading: 'School Id',
    editable: false,
    column: 2
  },
  {
    field: 'phone',
    heading: 'Phone Number',
    editable: false,
    column: 1
  },
  {
    field: 'currentClass',
    heading: 'Current Class',
    editable: false,
    column: 1
  },
  {
    field: 'currentRoom',
    heading: 'Current Room',
    editable: false,
    column: 1
  },
  {
    field: 'currentInstructor',
    heading: 'Current instructor',
    editable: false,
    column: 2
  }
];
