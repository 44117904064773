import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Container, Typography } from "@mui/material";
import { styles } from "../styles/componentTopbarStyle";
import { useTheme } from "@mui/material/styles";
import GlobalAppContext from "../../feature/context";
import { MenuToggleIcon } from "../../feature/core/customIcons";

export const TopBarComponent = (props) => {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("user"));
  const { sideNavigationOpen, setSideNavigationOpen } =
    useContext(GlobalAppContext);

  const theme = useTheme();
  const style = styles(theme);

  const handleDrawerOpen = () => {
    setSideNavigationOpen(!sideNavigationOpen);
  };

  const handleAccountClick = async () => {
    const user = await JSON.parse(localStorage.getItem("user"));
    navigate("/account", { state: user });
  };

  useEffect(() => {
    const handleResize = (e) => {
      if (e.target.innerWidth <= 1160) {
        setSideNavigationOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <AppBar sx={style.appTopBar} position="static" color="transparent">
      <MenuToggleIcon
        onClick={() => handleDrawerOpen()}
        sx={style.menuToggleIcon}
      />
      <Container sx={style.rightTopBarContainer}>
        <Container sx={style.notificationContainer}>
          {/* <Badge badgeContent={4} sx={notificationIconBadge}>
                        <NotificationsNoneIcon color='action' />
                    </Badge> */}
        </Container>
        <Container
          sx={style.accountcontainer}
          onClick={() => handleAccountClick()}
        >
          <AccountCircleIcon sx={style.accountCircle} />
          <Typography variant="h6" sx={style.userName}>
            {userData?.firstName
              ? `${userData.firstName} ${userData.lastName}`
              : "Unkown User"}
          </Typography>
        </Container>
      </Container>
    </AppBar>
  );
};
