/**
 * This is the styling for the Component Content
 * Values that are not pulled from the styles constant should never be changed and should remain static in this file.
 */

export const styles = (theme) => {
  return {
    appTopBar: {
      marginBottom: "0px",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      flexDirection: "row",
      height: "60px",
      maxHeight: "60px",
      borderBottom: `1px solid ${theme.palette.custom.borderColor}`,
    },

    rightTopBarContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginRight: "10px",
      width: "auto",
    },

    accountcontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "auto",
      marginRight: "30px",
      cursor: "pointer",
    },

    notificationContainer: {
      display: "flex",
      width: "auto",
      marginRight: "30px",
    },

    accountCircle: {
      color: `${theme.palette.primary.main}`,
    },

    userName: {
      color: `${theme.palette.custom.darkGray}`,
      fontWeight: "100",
      marginLeft: "10px",
    },

    notificationIconBadge: {
      color: `${theme.palette.custom.backgroundColor}`,
      "& span": {
        backgroundColor: `${theme.palette.primary.dark}`,
      },
    },

    menuToggleIcon: {
      marginLeft: "20px",
      cursor: "pointer",
    },
  };
};
