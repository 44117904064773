
/**
 * This is the detault columns for Districts
 * field - This is the property on the object that wil be displayed within this column
 * headerName - This is the header that will be displayed for the column
 * hideableable: This is a custom field that will determine whether a column will be able to be added to the grid or not (visibility) 
 */
export const districtsColumns = [
    {
        field: 'name',
        headerName: 'District Name',
        cellType: 'text',
        required: true,
        type: 'text',
        dataType: 'string',
        showColumn: true,
        showAdd: true,
    }, {
        field: 'activeIncidents',
        headerName: 'Active Incidents',
        cellType: 'text',
        showColumn: true,
        showAdd: false,
    }, {
        field: 'closedIncidents',
        headerName: 'Closed Incidents',
        cellType: 'text',
        showColumn: true,
        showAdd: false,
    }, {
        field: 'escalatedIncidents',
        headerName: 'Escalated',
        cellType: 'text',
        showColumn: true,
        showAdd: false,
    },
    {
        field: 'emergencyIncidents',
        headerName: 'Emergencies',
        cellType: 'text',
        showColumn: true,
        showAdd: false,
    },

    {
        field: 'responders',
        headerName: 'Responders',
        cellType: 'text',
        showColumn: true,
        showAdd: false,
    },
    {
        field: 'students',
        headerName: 'Students',
        cellType: 'text',
        showColumn: true,
        showAdd: false,
    },
    {
        field: 'superintendent',
        headerName: 'Superintendent',
        cellType: 'text',
        required: true,
        type: 'text',
        dataType: 'string',
        showColumn: false,
        showAdd: true,
    },
    {
        field: 'phone',
        headerName: 'Phone Number',
        cellType: 'text',
        required: true,
        type: 'text',
        dataType: 'phone',
        showColumn: false,
        showAdd: true,
    },
    {
        field: 'email',
        headerName: 'Email',
        cellType: 'text',
        required: true,
        type: 'text',
        dataType: 'email',
        showColumn: false,
        showAdd: true,
    },
    {
        field: 'address',
        headerName: 'Address',
        cellType: 'text',
        required: true,
        type: 'text',
        dataType: 'string',
        showColumn: false,
        showAdd: true,
    },
];


