import { getAvailabilityOptions } from "../../../utils/userConfig";
import { getPermissionOptions } from "../../../utils/userConfig";
/**
 * This is the detault columns for Users
 * field - This is the property on the object that wil be displayed within this column
 * headerName - This is the header that will be displayed for the column
 * hideableable: This is a custom field that will determine whether a column will be able to be added to the grid or not (visibility)
 */

export const getUsersColumns = async (data) => {
  const user = localStorage.getItem("user");
  return [
    {
      field: "firstName",
      secondField: "lastName",
      fieldConfig: "combined",
      headerName: "User Name",
      showColumn: true,
      cellType: "userName",
      showAdd: false,
    },
    {
      field: "openIncidents",
      headerName: "Open Incidents",
      cellType: "text",
      showColumn: true,
      showAdd: false,
    },
    {
      field: "schoolName",
      headerName: "School",
      cellType: "text",
      showColumn: true,
      showAdd: false,
    },
    {
      field: "avgResolveTime",
      headerName: "Avg. Resolve Time",
      cellType: "text",
      showColumn: true,
      showAdd: false,
    },
    {
      field: "permission",
      headerName: "Role",
      cellType: "chip",
      typeConfig: {
        colors: {
          "District Admin": "lightblue",
          "School Admin": "yellow",
          Responder: "blue",
          "Super Admin": "lightpurple",
        },
      },
      displayConfig: {
        districtAdmin: "District Admin",
        schoolAdmin: "School Admin",
        responder: "Responder",
        disclosureAdmin: "Super Admin",
      },
      showColumn: true,
      showAdd: true,
      required: true,
      disabled: true,
      addDefaultValue: "responder",
      type: "select",
      dataType: "string",
      options: await getPermissionOptions("responder"),
    },
    {
      field: "availability",
      headerName: "Availability",
      cellType: "Availability",
      typeConfig: {
        colors: {
          Available: "green",
          "Out of Office": "red",
        },
      },
      showColumn: true,
      showAdd: false,
    },
    {
      field: "firstName",
      headerName: "First Name",
      cellType: "text",
      showColumn: false,
      showAdd: true,
      required: true,
      type: "text",
      dataType: "string",
    },
    {
      field: "lastName",
      headerName: "Last Name",
      cellType: "text",
      showColumn: false,
      showAdd: true,
      required: true,
      type: "text",
      dataType: "string",
    },
    {
      field: "email",
      headerName: "Email",
      cellType: "text",
      showColumn: false,
      showAdd: true,
      required: true,
      type: "text",
      dataType: "email",
    },
    {
      field: "phone",
      headerName: "Phone",
      cellType: "text",
      showColumn: false,
      showAdd: true,
      required: true,
      type: "text",
      dataType: "phone",
    },
    {
      field: "district",
      headerName: "District",
      cellType: "text",
      showColumn: false,
      showAdd: true,
      required: true,
      showPermissions: ["disclosureAdmin"],
      addDefaultValue: user.district,
      type: "apiSelect",
      apiConfig: {
        apiBody: {},
        label: "District",
        dataType: "districts",
      },
      dataType: "string",
    },
    {
      field: "school",
      headerName: "School",
      cellType: "text",
      showPermissions: ["disclosureAdmin", "districtAdmin"],
      showColumn: false,
      showAdd: true,
      required: true,
      type: "apiSelect",
      apiConfig: {
        apiBody: {filters: [{ key: "district", value: "{district}", expression: "=", valueObj: "filterValues" }]},
        label: "School",
        dataType: "schools",
      },
      dataType: "string",
      requires: {
        district: {},
      },
    },
  ];
};

export const getUsersFilters = async () => {
  const availabilityOptions = await getAvailabilityOptions();
  return [
    {
      title: "District",
      field: "district",
      labelField: "districtName",
      type: "apiSelect",
      returnType: "string",
      permission: ["disclosureAdmin"],
      apiConfig: {
        apiBody: {},
        label: "District",
        dataType: "districts",
      },
    },
    {
      title: "School",
      field: "school",
      labelField: "schoolName",
      type: "apiSelect",
      permission: ["disclosureAdmin", "districtAdmin"],
      returnType: "string",
      apiConfig: {
        apiBody: {filters: [{ key: "district", value: "{district}", expression: "=", valueObj: "filterValues" }]},
        dataType: "schools",
        label: "School",
        requires: ["district"],
      },
    },
    {
      title: "Availability",
      field: "availability",
      returnType: "list",
      options: availabilityOptions,
      type: "select",
    },
  ];
};
