import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DetailsView from "../core/detailsView";
import { getDetailViewConfig } from "./configs/respondersInfoConfigs";

const ResponderPage = () => {
  const { state } = useLocation();
  const [detailViewConfig, setDetailViewConfig] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const tempDetailViewConfig = await getDetailViewConfig(state);
      setDetailViewConfig(tempDetailViewConfig);
    };
    fetchData();
  }, [state]);

  return (
    <>
      {detailViewConfig && (
        <DetailsView
          data={state}
          titleConfig={detailViewConfig.titleConfig}
          type={detailViewConfig.type}
          title={detailViewConfig.title}
          tabs={detailViewConfig.tabs}
          mainAction={detailViewConfig.mainAction}
          actionItems={detailViewConfig.actionItems}
          cards={detailViewConfig.cards}
        />
      )}
    </>
  );
};

export default ResponderPage;
