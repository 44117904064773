import React from 'react';
import { createSvgIcon } from '@mui/material/utils';
const LoginLogoIcon = createSvgIcon(
    <svg width="668" height="124" viewBox="0 0 668 124" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M199.555 26.3037H220.124C225.103 26.3037 229.835 26.8578 234.32 27.9735C238.805 29.0891 242.714 30.8862 246.054 33.3796C249.393 35.873 252.044 39.1077 254.005 43.0912C255.967 47.0747 256.948 51.9118 256.948 57.5949C256.948 62.6342 255.997 67.0744 254.088 70.9156C252.186 74.7568 249.618 77.9766 246.398 80.5823C243.171 83.188 239.457 85.1648 235.234 86.5126C231.011 87.8604 226.593 88.5343 221.958 88.5343H199.54V26.3037H199.555ZM213.265 75.88H220.386C223.553 75.88 226.496 75.558 229.221 74.9141C231.947 74.2701 234.305 73.1994 236.297 71.7093C238.289 70.2118 239.854 68.25 240.999 65.8165C242.145 63.383 242.714 60.4103 242.714 56.8911C242.714 53.8436 242.145 51.1929 240.999 48.9391C239.854 46.6853 238.319 44.8209 236.387 43.3608C234.455 41.8932 232.179 40.8 229.573 40.0662C226.967 39.3324 224.227 38.9655 221.351 38.9655H213.265V75.88Z" fill="white"/>
        <path d="M261.868 32.3681C261.868 30.2566 262.616 28.4595 264.107 26.962C265.604 25.4644 267.401 24.7231 269.513 24.7231C271.624 24.7231 273.421 25.4719 274.919 26.962C276.416 28.4595 277.158 30.2566 277.158 32.3681C277.158 34.4797 276.409 36.2767 274.919 37.7743C273.421 39.2718 271.624 40.0131 269.513 40.0131C267.401 40.0131 265.604 39.2643 264.107 37.7743C262.616 36.2842 261.868 34.4797 261.868 32.3681ZM262.923 45.8236H276.109V88.5411H262.923V45.8236Z" fill="white"/>
        <path d="M308.141 58.3884C306.21 55.9849 303.656 54.7868 300.497 54.7868C299.381 54.7868 298.303 55.0489 297.247 55.5805C296.191 56.1047 295.667 57.0182 295.667 58.3061C295.667 59.3618 296.206 60.1405 297.292 60.6347C298.377 61.1364 299.755 61.5707 301.425 61.9526C303.095 62.3345 304.884 62.7463 306.786 63.1806C308.688 63.6223 310.478 64.3112 312.147 65.2472C313.817 66.1831 315.195 67.4561 316.281 69.0734C317.366 70.6833 317.905 72.8397 317.905 75.5353C317.905 78.2908 317.306 80.5596 316.101 82.3492C314.895 84.1387 313.36 85.5764 311.488 86.6546C309.609 87.7403 307.505 88.5041 305.161 88.9384C302.818 89.3801 300.504 89.5973 298.22 89.5973C295.233 89.5973 292.215 89.1705 289.168 88.3244C286.12 87.4783 283.544 85.9657 281.433 83.7943L289.43 74.9138C290.658 76.4413 292.02 77.5945 293.518 78.3882C295.015 79.1819 296.753 79.5787 298.744 79.5787C300.264 79.5787 301.642 79.3616 302.878 78.9198C304.106 78.478 304.727 77.6768 304.727 76.5012C304.727 75.3856 304.188 74.5544 303.102 73.9929C302.017 73.4388 300.639 72.967 298.969 72.5852C297.299 72.2033 295.51 71.7915 293.608 71.3572C291.706 70.9154 289.916 70.2565 288.247 69.3804C286.577 68.5043 285.199 67.2539 284.113 65.644C283.028 64.0342 282.489 61.8777 282.489 59.1821C282.489 56.6662 282.998 54.4948 284.024 52.6753C285.049 50.8632 286.397 49.3657 288.067 48.1901C289.737 47.022 291.653 46.1535 293.825 45.5994C295.989 45.0453 298.19 44.7607 300.414 44.7607C303.23 44.7607 306.067 45.1726 308.943 45.9887C311.81 46.8124 314.244 48.2725 316.236 50.384L308.141 58.3884Z" fill="white"/>
        <path d="M351.301 59.6237C350.657 58.6878 349.751 57.909 348.575 57.295C347.4 56.6811 346.142 56.3741 344.794 56.3741C341.454 56.3741 338.864 57.3699 337.014 59.3617C335.165 61.3534 334.244 63.9666 334.244 67.1863C334.244 70.4136 335.165 73.0193 337.014 75.011C338.864 77.0028 341.454 77.9986 344.794 77.9986C346.261 77.9986 347.519 77.6767 348.575 77.0327C349.631 76.3888 350.537 75.625 351.301 74.7489L360.001 83.8915C357.95 86.003 355.531 87.4781 352.746 88.3317C349.96 89.1778 347.31 89.6046 344.794 89.6046C341.514 89.6046 338.422 89.0805 335.517 88.0247C332.611 86.9689 330.095 85.4639 327.954 83.4946C325.812 81.5328 324.128 79.1742 322.9 76.4187C321.672 73.6632 321.05 70.5858 321.05 67.1863C321.05 63.7869 321.664 60.7094 322.9 57.954C324.128 55.1985 325.812 52.8398 327.954 50.8781C330.095 48.9163 332.611 47.4037 335.517 46.348C338.414 45.2922 341.507 44.7681 344.794 44.7681C347.31 44.7681 349.968 45.1949 352.746 46.041C355.531 46.8946 357.942 48.3697 360.001 50.4812L351.301 59.6237Z" fill="white"/>
        <path d="M363.394 22.0879H376.58V88.5415H363.394V22.0879Z" fill="white"/>
        <path d="M382.638 67.179C382.638 63.7796 383.252 60.7021 384.487 57.9466C385.715 55.1912 387.4 52.8325 389.541 50.8707C391.683 48.9089 394.199 47.3964 397.104 46.3407C400.002 45.2849 403.094 44.7607 406.381 44.7607C409.661 44.7607 412.753 45.2849 415.659 46.3407C418.556 47.3964 421.08 48.9015 423.221 50.8707C425.363 52.8325 427.047 55.1912 428.275 57.9466C429.503 60.7021 430.125 63.7796 430.125 67.179C430.125 70.5784 429.511 73.6559 428.275 76.4114C427.047 79.1669 425.363 81.5255 423.221 83.4873C421.08 85.4491 418.564 86.9616 415.659 88.0174C412.753 89.0731 409.668 89.5973 406.381 89.5973C403.102 89.5973 400.009 89.0731 397.104 88.0174C394.206 86.9616 391.683 85.4566 389.541 83.4873C387.4 81.5255 385.715 79.1669 384.487 76.4114C383.252 73.6559 382.638 70.5784 382.638 67.179ZM395.823 67.179C395.823 70.4062 396.744 73.012 398.594 75.0037C400.443 76.9954 403.034 77.9913 406.374 77.9913C409.713 77.9913 412.304 76.9954 414.153 75.0037C416.003 73.012 416.924 70.4062 416.924 67.179C416.924 63.9593 416.003 61.3461 414.153 59.3543C412.304 57.3626 409.713 56.3667 406.374 56.3667C403.034 56.3667 400.443 57.3626 398.594 59.3543C396.744 61.3536 395.823 63.9593 395.823 67.179Z" fill="white"/>
        <path d="M468.722 41.5144C467.607 40.1067 466.102 39.0659 464.192 38.392C462.29 37.7181 460.486 37.3812 458.786 37.3812C457.79 37.3812 456.764 37.501 455.709 37.7331C454.653 37.9652 453.657 38.3321 452.721 38.8338C451.785 39.3355 451.021 39.9944 450.437 40.8105C449.853 41.6342 449.561 42.6301 449.561 43.7981C449.561 45.6776 450.265 47.1077 451.673 48.1036C453.08 49.0995 454.848 49.9531 456.989 50.6494C459.131 51.3533 461.429 52.0571 463.893 52.761C466.356 53.4648 468.655 54.4607 470.796 55.7486C472.938 57.0365 474.705 58.7661 476.113 60.9376C477.52 63.109 478.224 66.0068 478.224 69.6383C478.224 73.0976 477.58 76.1152 476.292 78.691C475.005 81.2668 473.26 83.4083 471.066 85.108C468.865 86.8077 466.319 88.0656 463.421 88.8893C460.523 89.7129 457.453 90.1173 454.234 90.1173C450.19 90.1173 446.439 89.5033 442.98 88.2678C439.52 87.0398 436.3 85.0481 433.313 82.2926L443.069 71.5701C444.477 73.4496 446.244 74.8947 448.386 75.9205C450.527 76.9463 452.736 77.4555 455.02 77.4555C456.136 77.4555 457.259 77.3207 458.404 77.0587C459.55 76.7966 460.568 76.3997 461.482 75.8681C462.388 75.344 463.122 74.6701 463.676 73.8464C464.23 73.0303 464.514 72.0568 464.514 70.9487C464.514 69.0767 463.795 67.5942 462.358 66.5084C460.92 65.4227 459.116 64.5017 456.952 63.738C454.78 62.9742 452.437 62.218 449.921 61.4542C447.397 60.6905 445.054 59.6646 442.89 58.3768C440.718 57.0889 438.921 55.3892 437.484 53.2776C436.046 51.1661 435.327 48.3881 435.327 44.9288C435.327 41.5893 435.986 38.6616 437.304 36.1382C438.622 33.6223 440.381 31.5108 442.575 29.8111C444.769 28.1113 447.307 26.8384 450.175 25.9848C453.043 25.1387 456.008 24.7119 459.056 24.7119C462.575 24.7119 465.967 25.2136 469.254 26.2095C472.534 27.2053 475.499 28.8751 478.134 31.2188L468.722 41.5144Z" fill="#3BB5FD"/>
        <path d="M525.3 88.5461H512.646V82.7431H512.473C512.002 83.5668 511.403 84.3829 510.669 85.2066C509.935 86.0303 509.044 86.7566 507.988 87.408C506.932 88.0519 505.734 88.5761 504.387 88.9879C503.039 89.3997 501.571 89.6019 499.991 89.6019C496.652 89.6019 493.941 89.0852 491.86 88.0669C489.778 87.0411 488.168 85.6184 487.023 83.8064C485.877 81.9944 485.121 79.8529 484.739 77.3894C484.357 74.9259 484.17 72.2603 484.17 69.3925V45.8286H497.356V66.7493C497.356 67.9773 497.401 69.2577 497.49 70.5756C497.58 71.8934 497.842 73.1139 498.284 74.2221C498.726 75.3378 499.4 76.2438 500.306 76.9476C501.212 77.6515 502.515 78.0034 504.214 78.0034C505.914 78.0034 507.292 77.6964 508.348 77.0824C509.403 76.4684 510.205 75.6448 510.766 74.6189C511.32 73.5931 511.687 72.4325 511.867 71.1446C512.039 69.8567 512.129 68.509 512.129 67.1013V45.8286H525.315V88.5461H525.3Z" fill="#3BB5FD"/>
        <path d="M533.228 45.8276H546.414V52.6863H546.586C547.994 50.0507 549.664 48.0739 551.595 46.7561C553.527 45.4382 555.961 44.7793 558.889 44.7793C559.652 44.7793 560.409 44.8092 561.172 44.8692C561.936 44.9291 562.64 45.0414 563.284 45.2211V57.2613C562.348 56.9693 561.419 56.7522 560.513 56.6024C559.607 56.4602 558.649 56.3853 557.653 56.3853C555.13 56.3853 553.138 56.7372 551.678 57.441C550.21 58.1449 549.087 59.1258 548.293 60.3837C547.5 61.6417 546.991 63.1542 546.758 64.9138C546.526 66.6734 546.406 68.6053 546.406 70.7168V88.5601H533.221V45.8276H533.228Z" fill="#3BB5FD"/>
        <path d="M607.155 81.162C605.044 83.8576 602.378 85.9392 599.158 87.4068C595.931 88.8744 592.592 89.6007 589.14 89.6007C585.86 89.6007 582.768 89.0766 579.863 88.0208C576.965 86.965 574.441 85.46 572.3 83.4907C570.158 81.5289 568.474 79.1703 567.246 76.4148C566.018 73.6593 565.396 70.5819 565.396 67.1824C565.396 63.783 566.01 60.7055 567.246 57.9501C568.474 55.1946 570.158 52.8359 572.3 50.8742C574.441 48.9124 576.957 47.3998 579.863 46.3441C582.76 45.2883 585.853 44.7642 589.14 44.7642C592.187 44.7642 594.958 45.2883 597.444 46.3441C599.937 47.3998 602.041 48.9049 603.771 50.8742C605.501 52.8359 606.833 55.1946 607.769 57.9501C608.705 60.7055 609.177 63.783 609.177 67.1824V71.3157H578.59C579.114 73.839 580.259 75.8457 582.019 77.3358C583.779 78.8333 585.943 79.5746 588.526 79.5746C590.69 79.5746 592.524 79.0879 594.022 78.122C595.519 77.1561 596.822 75.9131 597.93 74.3856L607.155 81.162ZM595.991 62.3528C596.051 60.129 595.317 58.2196 593.79 56.6397C592.262 55.0598 590.3 54.2661 587.897 54.2661C586.429 54.2661 585.141 54.4982 584.033 54.9699C582.918 55.4417 581.967 56.0407 581.173 56.7745C580.379 57.5083 579.765 58.3544 579.323 59.3203C578.882 60.2862 578.635 61.2971 578.575 62.3528H595.991Z" fill="#3BB5FD"/>
        <path d="M130.241 14.064H98.2833C76.4865 14.064 58.823 31.735 58.823 53.5243C58.823 75.3211 76.494 92.9847 98.2833 92.9847H130.241C152.038 92.9847 169.701 75.3136 169.701 53.5243C169.701 31.7275 152.038 14.064 130.241 14.064Z" fill="url(#paint0_linear_1815_27873)"/>
        <path d="M130.241 14.064H98.2834C79.9759 14.064 64.5811 26.531 60.1259 43.4458C80.6198 35.696 95.191 48.9343 115.333 41.5739C131.259 35.7559 136.194 23.2963 137.661 14.7753C135.258 14.3111 132.779 14.064 130.241 14.064Z" fill="url(#paint1_linear_1815_27873)"/>
        <path d="M167.29 109.913C167.335 109.868 167.365 109.839 167.402 109.794H132.659C114.015 109.794 98.8894 94.6684 98.8894 76.0089C98.8894 57.357 114.007 42.2393 132.659 42.2393C151.311 42.2393 166.436 57.357 166.436 76.0089C166.436 83.1298 164.235 89.7339 160.476 95.185L168.308 108.753C168.331 108.73 168.346 108.7 168.361 108.685L169.087 109.936L167.29 109.913Z" fill="url(#paint2_linear_1815_27873)"/>
        <path d="M115.797 46.6293C115.797 46.6293 125.366 88.5456 160.476 95.1872C172.831 78.0179 164.213 57.4865 154.846 50.1785C141.824 40.0251 127.186 40.2947 115.797 46.6293Z" fill="url(#paint3_linear_1815_27873)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M118.082 75.9414C120.209 75.9414 121.938 77.6711 121.938 79.7976C121.938 81.9241 120.209 83.6538 118.082 83.6538C115.956 83.6538 114.226 81.9241 114.226 79.7976C114.233 77.6711 115.956 75.9414 118.082 75.9414Z" fill="#09305F"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M149.62 75.9414C151.746 75.9414 153.476 77.6711 153.476 79.7976C153.476 81.9241 151.754 83.6538 149.62 83.6538C147.493 83.6538 145.764 81.9241 145.764 79.7976C145.764 77.6711 147.493 75.9414 149.62 75.9414Z" fill="#09305F"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M133.851 74.3091C136.876 74.3091 139.332 76.7651 139.332 79.7976C139.332 82.8226 136.876 85.2861 133.851 85.2861C130.819 85.2861 128.363 82.8301 128.363 79.7976C128.363 76.7651 130.826 74.3091 133.851 74.3091Z" fill="#09305F"/>
        <defs>
            <linearGradient id="paint0_linear_1815_27873" x1="114.262" y1="90.294" x2="114.262" y2="-42.6521" gradientUnits="userSpaceOnUse">
                <stop stopColor="#04518F"/>
                <stop offset="0.2459" stopColor="#0082E1"/>
                <stop offset="0.7875" stopColor="#036ABA"/>
                <stop offset="1" stopColor="#0280DE"/>
            </linearGradient>
            <linearGradient id="paint1_linear_1815_27873" x1="131.517" y1="85.6872" x2="91.1393" y2="15.6518" gradientUnits="userSpaceOnUse">
                <stop stopColor="#09305F"/>
                <stop offset="1" stopColor="#0280DE"/>
            </linearGradient>
            <linearGradient id="paint2_linear_1815_27873" x1="108.786" y1="52.1333" x2="167.842" y2="111.189" gradientUnits="userSpaceOnUse">
                <stop stopColor="white"/>
                <stop offset="0.9984" stopColor="#B9B9B9"/>
            </linearGradient>
            <linearGradient id="paint3_linear_1815_27873" x1="66.8612" y1=" 12.3752" x2="162.42" y2="83.1842" gradientUnits="userSpaceOnUse">
                <stop stopColor="#989898"/>
                <stop offset="0.1875" stopColor="#AAAAAA"/>
                <stop offset="0.5612" stopColor="#D8D8D8"/>
                <stop offset="0.8404" stopColor="white"/>
            </linearGradient>
        </defs>
    </svg>,
  'LoginLogoIcon'
);

export default LoginLogoIcon;