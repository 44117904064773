/**
 * This is the styling for the Data Grid Component
 * Values that are not pulled from the styles constant should never be changed and should remain static in this file.
 */

export const styles = (theme) => ({
  dataGridStyleContainer: {
    fontFamily: "Nunito Sans",
    margin: "0px",
    marginLeft: "20px",
    marginTop: "20px",
    width: "calc(100% - 40px)",
    "& h4": {
      display: "flex",
      "& .MuiToggleButtonGroup-root": {
        margin: "0 0 0 20px",
      },
    },
  },
  dataTableLoadingContainer: {
    display: "flex",
    minHeight: "calc(100vh - 353px)",
    maxHeight: "calc(100vh - 353px)",
    minWidth: "100%",
    borderRadius: "0px",
    margin: "0px",
    backgroundColor: `${theme.palette.custom.white}`,
    border: `1px solid ${theme.palette.custom.borderColor}`,
    alignItems: "center",
  },
});
