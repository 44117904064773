import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container } from "@mui/material";
import DataTableMD from "../../core/dataTableMD";
import {
  incidentsColumns,
  getIncidentsFilters,
} from "../configs/studentsAllIncidentsDataConfig";

const AllIncidentsTab = () => {
  const { state } = useLocation();
  const [filters, setFilters] = useState([]);
  useEffect(() => {
    const getFilters = async () => {
      const filters = await getIncidentsFilters();
      setFilters(filters);
    };
    getFilters();
  }, []);
  return (
    <Container>
      <DataTableMD
        title="Incidents"
        dbTable={"incidents"}
        filters={filters}
        toggles={null} // or null for none
        columns={incidentsColumns}
        sort={{ field: "number", order: "desc" }}
        isTab={true}
        apiBody={{ studentId: state.id }}
      />
    </Container>
  );
};

export default AllIncidentsTab;
