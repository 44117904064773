import React, { useState, useEffect } from 'react';
import {
    Typography
} from '@mui/material';
import Form from '../forms/form';

const ModalBody = ({ submit, columns, values, setValues, addSuccessMessage}) => {
  const initOptions = {};
  for (let column of columns) {
    if (column.showAdd && column.type === 'select') {
      initOptions[column.field] = column.options;
    }
  }

  const [options, setOptions] = useState(initOptions);
  const [show, setShow] = useState({});

  const handleConfigOptions = async () => {
    const tempShow = show;
    for (let column of columns) {
      if (column.showAdd && column.requires) {
        let showColumn = true;
        for (let require in column.requires) {
          const requiresValue = values[require];
          if (requiresValue === '' || requiresValue === null || requiresValue === undefined) {
            showColumn = false;
          } else {
            if (column.requires[require].filter){
              const tempOptions = options
              const filteredOptions = column.options.filter((option) => {
                return option.district === requiresValue
              });
              tempOptions[column.field] = filteredOptions;
              setOptions({...tempOptions});
            }
            if (!(!column.requires[require].requiresValues || column.requires[require].requiresValues.includes(requiresValue))){
              showColumn = false;
            }
          }
        }
        tempShow[column.field] = showColumn;
        const tempValues = values;
        if (!showColumn){
          tempValues[column.field] = null;
        } else if (tempValues[column.field] === null)  {
          tempValues[column.field] = '';
        }
        setValues(tempValues);
      } else {
        tempShow[column.field] = true;
      }
    }
    setShow({...tempShow});
  }

  useEffect(() => {
    handleConfigOptions();
    // eslint-disable-next-line
  }, [values, columns])

  return (
      submit ? (
        <Typography variant='body'>
          {addSuccessMessage}
        </Typography>
      ) : (
        <Form fields={columns} values={values} setValues={setValues}/>
      )
  )
};

export default ModalBody;