import React from 'react';
import OpenIncidentsTab from '../components/openIncidents';
import ClosedIncidentsTab from '../components/closedIncidentsTab';
import AllIncidentsTab from '../components/allIncidentsTab';
import { TrashCanIcon } from '../../core/customIcons';

export const getDetailViewConfig = async (data) => {
  return {
    titleConfig: {
      breadcrumbs: true,
      field: 'name',
      subTitleConfig: {
          field: 'dateCreated',
          preString: 'Added on ',
          type: 'date'
      },
      mainAction: {
        type: 'text',
        text: 'Delete This Student',
        field: 'delete',
        textColor: 'warning.main',
        textStyles: ["underline"],
        preIcon: <TrashCanIcon />,
        modalConfig: {
          delete: {
              title: 'Delete Student',
              type: 'text',
              message: 'Are you sure you want to delete student #{data.number}?',
              submitLabel: 'Delete',
              cancelLabel: 'Cancel',
              submitColor: 'darkRed',
              cancelColor: 'blue',
              submitType: 'delete',
          }
        }
      },
    },
    actionItems: [
    {
      title: "District",
      field: "districtName",
      type: "text",
      editable: false,
    },
    {
      title: "School",
      field: "school",
      labelField: "schoolName",
      type: "apiSelect",
      apiConfig: {
        apiBody: {filters: [{ key: "district", value: "{district}", expression: "=", valueObj: "filterValues" }]},
        label: "School",
        dataType: "schools",
      },
      editable: true,
    },
    ],
    type: "students",
    title: "students",
    tabs: [
      { title: "Open Incidents", component: OpenIncidentsTab },
      { title: "Closed Incidents", component: ClosedIncidentsTab },
      { title: "All Incidents", component: AllIncidentsTab },
    ],
    actions: {
      edit: false,
      delete: false,
    },
  };
};
