export const styles = (theme) => {
  return {
    priorityContainer: {
      height: '209px',
      borderRadius: '4px',
      border: `1px solid ${theme.palette.custom.borderColor}`,
      justify: 'space-between',
      padding: '24px',
      backgroundColor: theme.palette.custom.white,
    },
    heading: {
      display: 'flex',
      flexDirection: 'column',
    },
    link: {
      color: theme.palette.primary.main,
      fontSize: '12px',
      lineHeight: '12px'
    },
    chartSpacing: {
      marginTop: '-20px',
      marginLeft: '-31px'
    },
    info: {
      paddingLeft: '10px !important',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '22px',
      borderLeft: `2px solid ${theme.palette.custom.green}`,
      display: 'grid'
    },
    infoColor: function(color) {
        return { borderLeft: `2px solid ${color}` };
    },
    infoSpan: {
      color: theme.palette.custom.gray5,
      fontSize: '12px',
      lineHeight: '22px'
    }
  };
};
