import { getPermissionOptions } from "../../../utils/userConfig";
import { TrashCanIcon } from "../../core/customIcons";

export const getDetailViewConfig = async (data) => {
  let permissionOptions = await getPermissionOptions("responder");
  let permissionDisabled = false;
  if (data.permission === "disclosureAdmin") {
    permissionDisabled = true;
  } else {
    permissionOptions = await permissionOptions.filter((option) => {
      return option.value !== "disclosureAdmin";
    });
  }

  return {
    titleConfig: {
      breadcrumbs: false,
      field: "{firstName} {lastName}",
      mainAction: {
        type: "text",
        text: "Delete This Responder",
        field: "delete",
        textColor: "warning.main",
        textStyles: ["underline"],
        preIcon: <TrashCanIcon />,
        modalConfig: {
          delete: {
            title: "Delete Responder",
            type: "text",
            message:
              "Are you sure you want to delete {data.firstName} {data.lastName} Responder?",
            submitLabel: "Delete",
            cancelLabel: "Cancel",
            submitColor: "red",
            cancelColor: "blue",
            submitType: "delete",
          },
        },
      },
    },
    title: "responders",
    type: "users",
    cards: [
      {
        column: 1,
        title: "Personal Information",
        bodyType: "form",
        fields: [
          {
            field: "firstName",
            headerName: "First Name",
            cellType: "text",
            required: true,
            type: "text",
            dataType: "string",
          },
          {
            field: "lastName",
            headerName: "Last Name",
            cellType: "text",
            required: true,
            type: "text",
            dataType: "string",
          },
          {
            field: "phone",
            headerName: "Phone",
            cellType: "text",
            required: true,
            type: "text",
            dataType: "phone",
          },
        ],
      },
      {
        column: 2,
        title: "Account",
        bodyType: "form",
        fields: [
          {
            field: "email",
            headerName: "Email",
            cellType: "text",
            disabled: true,
            required: true,
            type: "text",
            dataType: "email",
          },
          {
            field: "permission",
            headerName: "Role",
            disabled: permissionDisabled,
            required: true,
            type: "select",
            dataType: "string",
            options: permissionOptions,
          },
          {
            field: "district",
            labelField: "districtName",
            headerName: "District",
            cellType: "text",
            disabled: true,
            required: true,
            type: "select",
            options: [{label: data.districtName, value: data.district}],
            dataType: "string",
            requires: {
              permission: {
                requiresValues: ["districtAdmin", "schoolAdmin", "responder"],
              },
            },
          },
          {
            field: "school",
            labelField: "schoolName",
            showPermissions: ["disclosureAdmin", "districtAdmin"],
            headerName: "School",
            cellType: "text",
            required: true,
            type: "apiSelect",
            dataType: "string",
            apiConfig: {
              apiBody: {filters: [{ key: "district", value: data.district, expression: "=" }]},
              dataType: "schools",
              label: "",
            },
            requires: {
              permission: {
                requiresValues: ["schoolAdmin", "responder"],
              }
            },
          },
          {
            field: "school",
            labelField: "SchoolName",
            showPermissions: ["schoolAdmin", "responder"],
            headerName: "School",
            cellType: "text",
            disabled: true,
            required: true,
            type: "select",
            options: [{label: data.schoolName, value: data.school}],
            dataType: "string",
            requires: {
              permission: {
                requiresValues: ["schoolAdmin", "responder"],
              },
            },
          }
        ],
      },
      {
        column: 1,
        title: "Personal Time Off",
        bodyType: "table",
        apiType: "paidTimeOffs",
        apiBody: {
          userId: data.id,
        },
        columns: [
          {
            name: "Start Date",
          },
          {
            name: "End Date",
          },
          {
            name: "Count",
          },
        ],
      },
    ],
  };
};
