import React from "react";
import { useEffect, useState } from "react";
import DataTableMD from "../core/dataTableMD";
import {
  getStudentsColumns,
  getStudentFilters,
} from "./configs/studentsDataTableConfig";
/*
 * This generates the Students Page for the application
 * @param {*} props These are the properties required for the Students page (user, etc)
 * @returns This returns a generated Students page for the user
 */
const StudentsPage = (props) => {
  const [filters, setFilters] = useState([]);
  const [columns, setColumns] = useState([]);
  useEffect(() => {
    const getFilters = async () => {
      const filters = await getStudentFilters();
      const columns = await getStudentsColumns();
      setFilters(filters);
      setColumns(columns);
    };
    getFilters();
  }, []);
  const addSuccessMessage =
    "The new student has been successfully added to the system. The student information will stay anonymous unless determined users need to de-anonymize the information temporally.";
  return (
    <DataTableMD
      title="Students"
      dbTable={"students"}
      filters={filters}
      columns={columns}
      addButton={true}
      addSuccessMessage={addSuccessMessage}
      sort={{ field: "number", order: "desc" }}
    />
  );
};

export default StudentsPage;
