import { 
    appContent,
    contentContainer 
} from '../styles/componentContentStyle';
import { Outlet } from 'react-router-dom'

// Material UI Imports
import { Container } from '@mui/material';
import { TopBarComponent } from './componentTopbar';


/***
 * This component is for rendering the content on the right side of the application.
 * This includes the views, topbar, etc.
 */

const ContentComponent = () => {
    return (
        <Container sx={contentContainer}>
            <TopBarComponent/>
            <Container sx={appContent} className='content'>
                <Outlet />
            </Container>
        </Container>
    );
};

export default ContentComponent
