import React, { useEffect, useState, useContext } from "react";
import {
  Container,
  Typography,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { api } from "../../../../api";
import {
  CloseIconComments,
  MaxamizeIcon,
  MinimizeIcon,
  ResponderCommentIcon,
  StudentCommentIcon,
  SendIcon,
} from "../../../core/customIcons";

import { styles } from "../../styles/detailsView/detailViewDrawer";
import { useTheme } from "@mui/material/styles";
import { LoadingIcon } from "../../loadingIcon.js";
import { WebSocketContext } from "../../../context/websocketsContext.js";

const DetailViewDrawer = ({ data, open, handleOpen }) => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [subscribe, unsubscribe] = useContext(WebSocketContext);

  const theme = useTheme();
  const style = styles(theme);

  const fetchComments = async () => {
    const response = await api("get", { incidentId: data.id }, "comments");
    if (response.status === 200) {
      const tempComments = response.data;
      tempComments.sort(
        (a, b) => new Date(a.dateCreated) - new Date(b.dateCreated)
      );
      setComments(tempComments);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (comments.length > 0) {
      const element = document.getElementById("commentDiv");
      if (element) {
        element.scrollTop = element.scrollHeight;
      }
    }
    // eslint-disable-next-line
  }, [comments]);

  useEffect(() => {
    subscribe("comments", (messageData) => {
      const tempComments = [...comments];
      if (messageData.command === "newCommentStudent") {
        const commentData = messageData.comment;
        const incidentId = messageData.incidentId;
        if (incidentId === data.id) {
          tempComments.push(commentData);
        }
      }
      setComments([...tempComments]);
    });
    return () => {
      unsubscribe("comments");
    };
    // eslint-disable-next-line
  }, [comments, subscribe, unsubscribe]);

  useEffect(() => {
    if (open === "max") {
      fetchComments();
    }
    // eslint-disable-next-line
  }, [open]);

  const handleComment = async () => {
    setSubmitting(true);
    const commentData = {
      comment: newComment,
      incident: data.id,
      type: "General",
      portalOnly: "False",
    };
    const createComment = await api("post", commentData, "comments");
    if (createComment.status === 200) {
      setComments([...comments, createComment.data]);
      setNewComment("");
    }
    setSubmitting(false);
  };

  return (
    <Container
      sx={[
        style.detailViewDrawerContainer,
        open === "min" || open === "max"
          ? style.detailViewDrawerContainerOpen
          : style.detailViewDrawerContainerClose,
        open === "min" && style.detailViewDrawerContainerMin,
        open === "max" && style.detailViewDrawerContainerMax,
      ]}
    >
      <Container sx={style.detailViewDrawerContainerHeader}>
        <Typography
          variant="h5"
          sx={style.detailViewDrawerContainerCommentsTitle}
        >
          Chat with Student
        </Typography>
        <Container sx={style.detailViewDrawerMinMaxContainer}>
          <MinimizeIcon
            onClick={() => handleOpen("min")}
            sx={[
              style.detailViewDrawerContainerHeaderIcon,
              open === "min" && style.hide,
            ]}
          />
          <MaxamizeIcon
            onClick={() => handleOpen("max")}
            sx={[
              style.detailViewDrawerContainerHeaderIcon,
              open === "max" && style.hide,
            ]}
          />
          <CloseIconComments
            onClick={() => handleOpen("closed")}
            sx={style.detailViewDrawerContainerHeaderIcon}
          />
        </Container>
      </Container>

      {loading ? (
        <Container sx={style.detailViewDrawerContainerComments}>
          <LoadingIcon page="comments" />
        </Container>
      ) : (
        <Container
          id="commentDiv"
          sx={
            open === "min"
              ? style.hide
              : style.detailViewDrawerContainerComments
          }
        >
          {comments.map((comment, index) => (
            <Container key={index}>
              {comment.role === "user" && (
                <Container sx={style.detailViewDrawerCommentContainer}>
                  <Container
                    sx={[
                      style.detailViewDrawerContainerComment,
                      style.commentResponder,
                    ]}
                  >
                    <Typography sx={style.detailViewDrawerContainerCommentText}>
                      {comment.comment}
                    </Typography>
                    <Typography
                      sx={[
                        style.detailViewDrawerContainerCommentDate,
                        style.responderDate,
                      ]}
                    >
                      {new Date(comment.dateCreated + "+0000").toLocaleString({
                        year: "numeric",
                        month: "short",
                        day: "2-digit",
                        hour: "2-digit",
                        hour12: false,
                        minute: "2-digit",
                      })}
                    </Typography>
                  </Container>
                  <ResponderCommentIcon sx={style.responderIcon} />
                </Container>
              )}
              {comment.role === "student" && (
                <Container sx={style.detailViewDrawerCommentContainer}>
                  <StudentCommentIcon sx={style.studentIcon} />
                  <Container
                    sx={[
                      style.detailViewDrawerContainerComment,
                      style.commentStudent,
                    ]}
                  >
                    <Typography sx={style.detailViewDrawerContainerCommentText}>
                      {comment.comment}
                    </Typography>
                    <Typography
                      sx={[
                        style.detailViewDrawerContainerCommentDate,
                        style.studentDate,
                      ]}
                    >
                      {new Date(comment.dateCreated + "+0000").toLocaleString({
                        year: "numeric",
                        month: "short",
                        day: "2-digit",
                        hour: "2-digit",
                        hour12: false,
                        minute: "2-digit",
                      })}
                    </Typography>
                  </Container>
                </Container>
              )}
            </Container>
          ))}
        </Container>
      )}
      <Container
        sx={open === "min" ? style.hide : style.detailViewDrawerContainerFooter}
      >
        <TextField
          multiline
          placeholder="Type something here..."
          sx={style.detailViewDrawerContainerFooterTextField}
          onChange={(e) => setNewComment(e.target.value)}
          value={newComment}
          disabled={submitting}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {submitting ? (
                  <CircularProgress />
                ) : (
                  <SendIcon onClick={() => handleComment()}>Send</SendIcon>
                )}
              </InputAdornment>
            ),
          }}
        />
      </Container>
    </Container>
  );
};

export default DetailViewDrawer;
